<template>
    <div
        data-iframe-height
        class="wrapper thank-you-wrapper"
        :class="{ embedded: isEmbedded, 'new-embedded': isNewEmbedded }"
        data-test-id="survey-filler-thankyou"
    >
        <v-style>
            .thank-you-wrapper a { color: {{ primary_color }}; }
            .thank-you-wrapper a:hover { text-decoration: underline; }
        </v-style>
        <div
            v-if="!isFinished && status === 2"
            class="text-center"
        >
            <h1
                :style="titleStyle"
                :class="titleClass"
                v-html="closedSurveyTitle"
            />
            <section
                :style="descriptionStyle"
                :class="descriptionClass"
                v-html="closedSurveyDescription"
            />
        </div>
        <div
            v-else
            class="thank-you-page flex-grow max-w-128 text-center"
        >
            <div class="thank-you">
                <responsive-image
                    v-if="thank_you_logo_url && !isEmbedded"
                    :src="thank_you_logo_url"
                    alt="Logo"
                    class="mx-auto"
                />
                <h1
                    :style="titleStyle"
                    :class="titleClass"
                    v-html="computedTitle"
                />
                <img
                    v-if="thank_you_image_url"
                    crossorigin="anonymous"
                    :src="thank_you_image_url"
                    class="finish-picture mx-auto block"
                >
                <section
                    :style="descriptionStyle"
                    :class="descriptionClass"
                    v-html="computedDescription"
                />
            </div>
            <!--  Tmp switch off footer    <footer-wrapper />-->
        </div>
    </div>
</template>

<script>
import { onMounted, onUnmounted } from 'vue';
import { useState } from 'vuex-composition-helpers';
import striptags from 'striptags';
import { mapState, mapGetters } from 'vuex';
import VStyle from '@/components/ui/Style.vue';
import ResponsiveImage from './ui/ResponsiveImage.vue';
import { useResponsiveFontSizeClass } from './typeSpecificElements/hooks/useStyle';

import useRedirect from '@/utils/redirect';

export default {
    components: {
        VStyle,
        ResponsiveImage,
    },
    setup() {
        const { redirect } = useRedirect();
        const state = useState(['thankYouRedirect']);

        let timer;

        onMounted(() => {
            if (!state.thankYouRedirect.value) return;

            timer = redirect(state.thankYouRedirect.value);
        });

        onUnmounted(() => clearTimeout(timer));
    },
    computed: {
        ...mapState([
            'id',
            'isEmbedded',
            'isNewEmbedded',
            'thank_you_logo_url',
            'title',
            'description',
            'thank_you_title',
            'thank_you_description',
            'thank_you_image_url',
            'title_font_name',
            'title_font_weight',
            'answer_font_name',
            'answer_font_weight',
            'primary_color',
            'text_color',
            'isFinished',
            'isMultipleFillingOn',
            'status',
        ]),
        ...mapGetters(['getTranslations']),
        thankYouTitle() {
            return this.getTranslations?.thank_you_title || this.thank_you_title;
        },
        thankYouDescription() {
            return this.getTranslations?.thank_you_description || this.thank_you_description;
        },
        titleStyle() {
            return {
                'color': this.primary_color || undefined,
                'font-family': this.title_font_name || undefined,
                'font-weight': this.title_font_weight || undefined,
            };
        },
        titleClass() {
            const { titleFontSizeClass } = useResponsiveFontSizeClass();
            return titleFontSizeClass.value;
        },
        descriptionStyle() {
            return {
                'color': this.text_color || undefined,
                'font-family': this.answer_font_name || undefined,
                'font-weight': this.answer_font_weight || undefined,
            };
        },
        descriptionClass() {
            const { answerFontSizeClass } = useResponsiveFontSizeClass();
            return answerFontSizeClass.value;
        },
        computedTitle() {
            if (striptags(this.thankYouTitle)) {
                return this.thankYouTitle;
            }
            return this.$t('THANK_YOU_PAGE.TITLE');
        },
        computedDescription() {
            if (striptags(this.thankYouTitle) || striptags(this.thankYouDescription)) {
                return this.thankYouDescription;
            }
            return this.$t('THANK_YOU_PAGE.DESCRIPTION');
        },
        closedSurveyTitle() {
            return this.$t('THANK_YOU_PAGE.CLOSED_TITLE');
        },
        closedSurveyDescription() {
            return this.$t('THANK_YOU_PAGE.CLOSED_DESCRIPTION');
        }
    },
};
</script>

<style scoped lang="less">
.mobile {
    .thank-you-wrapper {
        width: 100% !important;

        .pager {
            width: 100% !important;
        }
    }
}

div.thank-you-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    &.embedded {
        margin-top: 50px;
        min-height: unset;

        &.new-embedded {
            margin-top: 0;
            padding: 1em;
        }
    }
}
div.thank-you-page {
    height: 100%;
}
div.thank-you {
    img.finish-picture {
        display: inline;
        max-width: 400px;
    }
}
h1 {
    font-size: 32px;
}
section {
    max-width: 500px;
    margin: 0 auto;
}
img.finish-picture {
    max-height: none;
}
@media (max-width: 450px) {
    div.thank-you {
        img.finish-picture {
            max-width: 80vw
        }
    }
}

/deep/ ul,
/deep/ ol {
    padding-left: 1em;
    text-align: left;
}

/deep/ ul {
    list-style-type: disc;
}

/deep/ ol {
    list-style-type: decimal;
}

/deep/ p:empty,
/deep/ div:empty {
    height: 1em;
}
</style>
