<template>
    <svg
        id="Layer_1"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 2578.4 744.7"
        style="enable-background:new 0 0 2578.4 744.7;"
        xml:space="preserve"
    >
        <g id="Layer_2_1_">
            <g id="Layer_1-2">
                <g class="st0">
                    <path d="M957.5,467.7l150-206.4h-150V216h216.9v41.8l-150,206.4h153.1v45.3h-220V467.7z" />
                    <path
                        d="M1343.6,481.4c-15.4,17.2-41.8,33.4-75.2,33.4c-45.8,0-68.2-23.8-68.2-67.3V297h46.2v133.8c0,33,16.7,43.1,42.7,43.1
				c23.3,0,43.6-13.2,54.6-27.7V297h46.2v212.5h-46.2V481.4z"
                    />
                    <path
                        d="M1429.5,297h46.2v31.2c15.8-20.2,41.4-36.1,69.1-36.1v45.8c-4-0.9-8.8-1.3-14.5-1.3c-19.4,0-45.3,13.2-54.6,28.2v144.8
				h-46.2V297z"
                    />
                    <path d="M1547.8,297h49.3l62,159.3l62-159.3h49.7l-86.7,212.5h-49.7L1547.8,297z" />
                    <path
                        d="M1869.3,291.7c63.8,0,104.7,48.8,104.7,115.7v11h-164.1c3.5,32.1,27.7,58.5,67.8,58.5c20.7,0,45.3-8.4,60.3-23.3 l21.1,30.4c-21.1,20.2-52.4,30.8-86.2,30.8c-63.8,0-111.3-44.4-111.3-111.8C1761.5,341.4,1806.4,291.7,1869.3,291.7z M1809.5,385.9h119.7c-0.9-25.1-18-56.3-59.8-56.3C1829.7,329.6,1811.7,359.9,1809.5,385.9z"
                    />
                    <path
                        d="M2010.6,554.8c14.5,0,24.2-4.4,30.4-18.5l10.6-24.2L1964.4,297h49.3l62,159.3l62-159.3h49.7l-102.5,250.8
				c-14.5,36.1-40,47.5-73,48c-6.6,0-19.4-1.3-26-3.1l7-41.4C1997.8,553.5,2005.3,554.8,2010.6,554.8z"
                    />
                    <path
                        d="M2198.7,452.8c16.7,0,30.8,14.1,30.8,30.8c0,16.7-14.1,30.8-30.8,30.8c-16.7,0-30.8-14.1-30.8-30.8
				C2167.9,466.8,2182,452.8,2198.7,452.8z"
                    />
                    <path d="M2263,297h46.2v212.5H2263V297z" />
                    <path
                        d="M2337.3,403c0-60.7,41.8-111.3,109.1-111.3c68.2,0,109.6,50.6,109.6,111.3c0,61.2-41.4,111.8-109.6,111.8
				C2379.1,514.8,2337.3,464.2,2337.3,403z M2508.1,403c0-37.4-22-70.4-61.6-70.4c-39.2,0-61.2,33-61.2,70.4
				c0,37.8,22,70.8,61.2,70.8C2486.1,473.9,2508.1,440.9,2508.1,403z"
                    />
                </g>
                <g>
                    <path
                        class="st1"
                        d="M0,372.4v279.3c0,51.4,41.7,93.1,93.1,93.1h558.6c51.4,0,93.1-41.7,93.1-93.1V372.4H0z"
                    />
                    <path
                        class="st2"
                        d="M93.1,744.7C41.7,744.7,0,703,0,651.6V93.1C0,41.7,41.7,0,93.1,0c0,0,0,0,0,0h558.5
				c51.4,0,93.1,41.7,93.1,93.1c0,0,0,0,0,0c0,51.4-93.1,144.5-300.1,351.5S144.5,744.7,93.1,744.7z"
                    />

                    <linearGradient
                        id="SVGID_1_"
                        gradientUnits="userSpaceOnUse"
                        x1="0"
                        y1="185.4613"
                        x2="744.73"
                        y2="185.4613"
                        gradientTransform="matrix(1 0 0 -1 0 744.0012)"
                    >
                        <stop
                            offset="0.16"
                            style="stop-color:#4EDCD4"
                        />
                        <stop
                            offset="1"
                            style="stop-color:#4EDCD4;stop-opacity:0"
                        />
                    </linearGradient>
                    <path
                        class="st3"
                        d="M0,651.6c0,51.4,41.7,93.1,93.1,93.1h558.6c51.4,0,93.1-41.7,93.1-93.1V372.4H0V651.6z"
                    />
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'Logo',
};
</script>

<style scoped>
.st0{enable-background:new    ;}
.st1{fill:#4EDCD4;}
.st2{fill:#1CC6BA;}
.st3{fill:url(#SVGID_1_);}
</style>
