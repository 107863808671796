<template>
    <div>
        <intro-media
            v-if="showLogo && logoSettings.position === 'top-center-above'"
            class="mt-4"
            :title="title"
            :url="logoUrl"
            :size="logoSettings.size"
        />
        <div
            v-if="isFirstPage"
            :class="{
                'mt-4': showLogo && logoSettings.position === 'top-center-above'
            }"
        >
            <h1
                :style="titleStyle"
                :class="titleClass"
                class="text-center"
                v-html="title"
            />
            <section
                :style="descriptionStyle"
                :class="descriptionClass"
                class="text-center"
                v-html="description"
            />
        </div>
        <intro-media
            v-if="showLogo && logoSettings.position === 'top-center-below'"
            class="mt-4"
            :title="title"
            :url="logoUrl"
            :size="logoSettings.size"
        />
        <section
            v-if="isFirstPage && isFillingTimeVisible"
            class="estimated-time flex justify-center items-center space-x-1 mt-4"
        >
            <font-awesome-icon
                class="fa-icon text-xl"
                :style="{ color: textColor }"
                :icon="['fal', 'clock']"
            />
            <div
                class="estimation-text"
                :style="descriptionStyle"
                :class="descriptionClass"
            >
                {{ estimatedText }}
            </div>
        </section>
    </div>
</template>

<script>
import elementTypeEnum from '@/enums/elementTypeEnum';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { useResponsiveFontSizeClass } from './typeSpecificElements/hooks/useStyle';

import IntroMedia from '@/components/IntroMedia.vue';

export default {
    components: {
        FontAwesomeIcon,
        IntroMedia
    },
    props: {
        title: { type: String, default: 'Title'},
        description: { type: String, default: null },
        primaryColor: { type: String, default: null },
        textColor: { type: String, default: null },
        titleFontName: { type: String, default: null },
        titleFontWeight: { type: String, default: null },
        answerFontName: { type: String, default: null },
        answerFontWeight: { type: String, default: null },
        elements: { type: Array, default: null },
        isFillingTimeVisible: { type: Boolean, default: null },
        fillingTime: { type: Number||String, default: null },
        logoUrl: { type: String, default: null },
        logoSettings: { type: Object, default: () => ({ hasLogo: true, position: 'top-center-above', size: 'small', onlyOnFirstPage: false })},
        isFirstPage: { type: Boolean, default: null },
    },
    computed: {
        titleStyle() {
            return {
                'color': this.primaryColor || undefined,
                'font-family': this.titleFontName || undefined,
                'font-weight': this.titleFontWeight || undefined,
            };
        },
        titleClass() {
            const { titleFontSizeClass } = useResponsiveFontSizeClass();
            return titleFontSizeClass.value;
        },
        descriptionStyle() {
            return {
                'color': this.textColor || undefined,
                'font-family': this.answerFontName || undefined,
                'font-weight': this.answerFontWeight || undefined,
            };
        },
        descriptionClass() {
            const { answerFontSizeClass } = useResponsiveFontSizeClass();
            return answerFontSizeClass.value;
        },
        estimation() {
            let time = 0;
            this.elements.forEach((element) => {
                if (element.type === elementTypeEnum.EXPLANATION) {
                    return;
                }
                if (element.type === elementTypeEnum.TEXT) {
                    time += 30;
                } else if (element.type === elementTypeEnum.MATRIX) {
                    time += 15 * element.options.length;
                } else {
                    time += 15;
                }
            });
            return Math.max(Math.floor(time / 60), 1);
        },
        estimatedText() {
            return this.$t('INTRO.ESTIMATED_TIME', {
                n: this.fillingTime || this.estimation,
                defaultValue: '{{n}} mins estimated',
            });
        },
        showLogo() {
            return this.logoSettings.hasLogo && this.logoSettings.onlyOnFirstPage && this.isFirstPage || this.logoSettings.hasLogo && !this.logoSettings.onlyOnFirstPage;
        },
    },
};
</script>

<style lang="less" scoped>
/deep/ ul,
/deep/ ol {
    padding-left: 1em;
    text-align: left;
}

/deep/ ul {
    list-style-type: disc;
}

/deep/ ol {
    list-style-type: decimal;
}

/deep/ p:empty,
/deep/ div:empty {
    height: 1em;
}
</style>
