<template>
    <div>
        <z-input
            ref="textarea"
            v-model="textValue"
            type="text"
            :error="Boolean(element.validation_error_message)"
            :text-color="text_color"
            :primary-color="primary_color"
            background-color="transparent"
            @blur="value = textValue"
        />
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue';
import ZInput from '@/components/ui/Input.vue';
import { useActions, useState } from 'vuex-composition-helpers';

export default defineComponent({
    components: {
        ZInput,
    },
    props: {
        element: { type: Object, default: () => ({})},
        isFirstUnansweredElement: { type: Boolean, default: false},
    },
    setup(props) {
        const textarea = ref<HTMLInputElement>();
        const textValue = ref(null);
        const hover = ref(false);
        const active = ref(false);

        const { primary_color, text_color, background_color, answers } = useState(['primary_color', 'text_color', 'background_color', 'answers']);
        const { setAnswer } = useActions(['setAnswer']);

        const value = computed({
            get: () => answers.value[props.element.guid] || '',
            set: (value) => setAnswer({ guid: props.element.guid, answer: value })
        });

        onMounted(() => {
            if (props.isFirstUnansweredElement) {
                textarea.value?.focus?.();
            }
            textValue.value = value.value;
        });

        return {
            textarea, textValue, hover, active, // refs
            primary_color, text_color, background_color, // store state
            value, // computed
        };
    },
});
</script>
