import { render, staticRenderFns } from "./Date.vue?vue&type=template&id=82ef00b2&scoped=true"
import script from "./Date.vue?vue&type=script&lang=ts"
export * from "./Date.vue?vue&type=script&lang=ts"
import style0 from "./Date.vue?vue&type=style&index=0&id=82ef00b2&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "82ef00b2",
  null
  
)

export default component.exports