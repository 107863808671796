import { computed } from 'vue';
import { useState } from 'vuex-composition-helpers';

export default function useParentQuestion(element) {
    const { answers, elements } = useState(['answers', 'elements']);

    const parentAnswer = computed(() => {
        if (!element.options_parent_question_guid || !answers.value[element.options_parent_question_guid]) {
            return { value: [] };
        }

        return answers.value[element.options_parent_question_guid];
    });

    const parentQuestionHasOtherOption = computed(() => {
        if (element.options_parent_question_guid) {
            const parentQuestion = elements.value.find(el => el.guid === element.options_parent_question_guid);

            if (parentQuestion) {
                return parentQuestion.has_other_option;
            }
        }

        return false;
    });

    return { parentAnswer, parentQuestionHasOtherOption };
}
