import api from '@/api';

import { autoPageActions } from './autopage';
import globals from '@/utils/globals';
import { getIdFromNickname } from '@/utils/languages';
import { produce } from 'immer';

export default {
    async setAnswer({ state, commit, dispatch }, {guid, answer}) {
        Sentry.addBreadcrumb({message: `Answer set ${guid}: ${answer}`, level: 'info'});
        commit('setAnswer', { guid, answer });
        if (!state.isTest && state.isAutosaveOn && !state.isNewEmbeddedPreview) {
            await dispatch('saveAnswers');
        }
    },
    setSelectedAnswer({ commit }, {guid, index}) {
        commit('setSelectedAnswer', {guid, index});
    },
    async saveAnswers({ state, getters }) {
        if (!state.isFinished && !state.isTest && Object.keys(state.answers).length > 0) {
            await api.saveAnswers(state.urlCode, state.answers, getters.urlParameters, state.startedAt, state.userId, state.recordId, state.surveyLanguage, state.lastVisitedPage);
        }
    },
    initPreviewMode({ commit }) {
        commit('setIsEmbedded', false);
        commit('setIsPreviewMode', true);
        window.addEventListener('message', (e) => {
            if (e.origin.indexOf(globals.getFrontendBaseUrl()) === -1 || !e.data || !/^\[zurvey]/.test(e.data)) {
                return;
            }

            const messageData = JSON.parse(e.data.replace(/^\[zurvey]/, ''));
            if (messageData.message === 'setElements') {
                commit('setElements', messageData.payload);
            } else if (messageData.message === 'startOver') {
                commit('setFinished', false);
                commit('setQuery', {});
                commit('reset');
            } else if (messageData.message === 'goBack') {
                commit('setFinished', false);
            }
        }, false);
    },
    ...autoPageActions,
    async getSurveyQuestionOptions({ state, commit }, dimensionId) {
        const options = await api.getSurveyQuestionOptions({ datasetId: state.datasetId, dimensionId, type: 'original' });

        if (!Array.isArray(options)) {
            throw new Error('options is not an array');
        }

        const elements = produce(state.elements, (draft) => {
            const element = draft.find(el => el.guid === dimensionId);

            if (element) {
                element.options = options;
            }
        });

        commit('setElements', elements);
    },
    async getSurveyQuestionOptionsTranslations({ state, commit }, dimensionId) {
        const optionsType = state.previewLang ? 'draft_translation' : 'live_translation';
        const translationType = state.previewLang ? 'draftTranslations' : 'liveTranslations';

        const options = await api.getSurveyQuestionOptions({ datasetId: state.datasetId, dimensionId, type: optionsType, langId: getIdFromNickname(state.surveyLanguage) });

        if (!Array.isArray(options)) {
            throw new Error('option translations is not an array');
        }

        const translations = produce(state.translations, draft => {
            const question = draft[translationType][state.surveyLanguage].questions.find(q => q.guid === dimensionId);

            if (question) {
                question.options = options;
            }
        });

        commit('setTranslations', translations);
    }
};
