<template>
    <div class="password-protection flex flex-col">
        <div class="sticky-header-password bg-bg">
            <div class="logo-part-wrapper">
                <logo
                    class="logo-wrapper"
                    style="width: 200px"
                />
            </div>
        </div>
        <div class="password-protection-part flex-grow">
            <form
                class="password-protection-form"
                @submit.prevent="checkPassword"
            >
                <div class="pass-message">
                    <b>{{ $t('PASSWORD.NEEDED') }}</b>
                </div>
                <z-input
                    v-model="password"
                    type="password"
                    class="mb-6"
                    autocomplete="off"
                />
                <z-button
                    class="block submit-pass"
                >
                    {{ $t('BUTTONS.SEND') }}
                </z-button>
            </form>
        </div>
    </div>
</template>
<script>
import api from '@/api';
import ZurveyLogo from '@/components/ui/Logo.vue';
import ZButton from '@/components/ui/Button.vue';
import ZInput from '@/components/ui/Input.vue';

import { toaster } from '@/utils/toaster';

export default {
    components: {
        ZButton,
        'logo': ZurveyLogo,
        ZInput,
    },
    props: {
        surveyId: { type: Number, default: null },
    },
    setup() {
        return { toaster };
    },
    data(){
        return {
            password: '',
            state: '',
        };
    },
    methods:{
        async checkPassword(){
            try {
                const response = await api.checkPassword({ id: this.surveyId, password: this.password });
                if (response === 'success') {
                    this.$emit('passwordIsOK');
                } else {
                    this.toaster.error(this.$t('PASSWORD.ERROR'));
                }
            } catch (e) {
                Sentry.captureException(e);
                this.toaster.error(this.$t('PASSWORD.ERROR'));
            }
        }
    }
};
</script>
<style scoped lang="less">
.password-protection{
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}
.password-protection-part{
    text-align: center;
    display: flex;
}
.password-protection-form{
    margin: auto;
}
.sticky-header-password {
    position: sticky;
    top: 0;
    padding: 10px;
    z-index: 100000;

    /deep/ .logo-part-wrapper {
        text-align: center !important;
        display: block !important;
        justify-content: unset !important;

        .logo-wrapper {
            width: 250px !important;
            margin: 0 auto !important;
            display: block !important;
        }
    }
}

.pass-message {
    font-size: 20px;
    margin: 20px 0;
}

.submit-pass{
    width: 20%;
    float: right;
    text-align: center;
    padding: 4px 10px !important;
}

</style>
