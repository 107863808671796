<template>
    <input
        :value="value"
        class="block flex-grow bg-transparent ml-1 px-1 -my-px py-0 border rounded focus:border-primary-700 hover:border-neutral-800 border-neutral-600"
        :style="{
            color: textColor,
            fontSize: inputFontSize,
            marginRight: '0.25em'
        }"
        type="text"
        @input="$emit('input', $event.target.value)"
    >
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useState } from 'vuex-composition-helpers';

import { useSpacingClass } from '../typeSpecificElements/hooks/useStyle';
import { LayoutSpacing } from '@/types/SettingEnums';

export default defineComponent({
    props: {
        value: { type: String, default: '' },
        textColor: { type: String, required: true },
        primaryColor: { type: String, required: true },
    },
    setup() {
        const { layout_spacing } = useState(['layout_spacing']);

        const { paddingSize } = useSpacingClass();

        const inputFontSize = computed(() => {
            switch (layout_spacing.value) {
            case LayoutSpacing.COZY:
                return '0.8em';
            case LayoutSpacing.COMPACT:
                return '0.9em';
            default:
                return '1em';
            }
        });

        return {paddingSize, inputFontSize};
    },
});
</script>
