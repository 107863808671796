import i18next from 'i18next';

export const actionEnum = {
    HIDE: 'hide',
    SHOW: 'show',
    CHANGE: 'change',
    JUMP: 'jump',
};

export const actionTextMap = {
    [actionEnum.HIDE]: () => i18next.t('PRINT.CONDITION_GROUP_ACTION_HIDE', 'Hide this question'),
    [actionEnum.SHOW]: () => i18next.t('PRINT.CONDITION_GROUP_ACTION_SHOW'),
    [actionEnum.CHANGE]: () => i18next.t('PRINT.CONDITION_GROUP_ACTION_CHANGE'),
    [actionEnum.JUMP]: () => i18next.t('PRINT.CONDITION_GROUP_ACTION_JUMP'),
};

export const operationEnum = {
    EMPTY: 'empty',
    NOT_EMPTY: 'not_empty',
    CONTAINS: 'contains',
    EQ: 'eq',
    NOT_EQ: 'neq',
    GT: 'gt',
    GTE: 'gte',
    LT: 'lt',
    LTE: 'lte',
    EXACTLY: 'exactly',
    ONE_OF: 'one_of',
    ALL_OF: 'all_of',
    NONE_OF: 'none_of',
};

export const operationTextMap = {
    [operationEnum.EMPTY]: () => i18next.t('PRINT.CONDITION_OPERATOR_IS_EMPTY'),
    [operationEnum.NOT_EMPTY]: () => i18next.t('PRINT.CONDITION_OPERATOR_IS_NOT_EMPTY'),
    [operationEnum.CONTAINS]: () => i18next.t('PRINT.CONDITION_OPERATOR_CONTAINS'),
    [operationEnum.EQ]: () => i18next.t('PRINT.CONDITION_OPERATOR_EQ'),
    [operationEnum.NOT_EQ]: () => i18next.t('PRINT.CONDITION_OPERATOR_NEQ'),
    [operationEnum.GT]: () => i18next.t('PRINT.CONDITION_OPERATOR_GT'),
    [operationEnum.GTE]: () => i18next.t('PRINT.CONDITION_OPERATOR_GTE'),
    [operationEnum.LT]: () => i18next.t('PRINT.CONDITION_OPERATOR_LT'),
    [operationEnum.LTE]: () => i18next.t('PRINT.CONDITION_OPERATOR_LTE'),
    [operationEnum.EXACTLY]: () => i18next.t('PRINT.CONDITION_OPERATOR_EXACTLY'),
    [operationEnum.ONE_OF]: () => i18next.t('PRINT.CONDITION_OPERATOR_ONE_OF'),
    [operationEnum.ALL_OF]: () => i18next.t('PRINT.CONDITION_OPERATOR_ALL_OF'),
    [operationEnum.NONE_OF]: () => i18next.t('PRINT.CONDITION_OPERATOR_NONE_OF'),
};

export const connectiveEnum = {
    AND: 'and',
    OR: 'or',
};

export const connectiveTextMap = {
    [connectiveEnum.AND]: () => i18next.t('PRINT.CONDITION_OPERATOR_CONNECTIVE_AND'),
    [connectiveEnum.OR]: () => i18next.t('PRINT.CONDITION_OPERATOR_CONNECTIVE_OR'),
};
