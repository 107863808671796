import 'iframe-resizer/js/iframeResizer.contentWindow.min';
import '@/utils/icons';
import Vue from 'vue';
import i18next from 'i18next';
import VueI18Next from '@panter/vue-i18next';
import SurveyFiller from './SurveyFiller.vue';
import store from './store/index';
import router from './router';
import LoadingScreen from './plugins/LoadingScreen';
import './utils/sentry';
import './assets/index.css';
import 'simplebar/dist/simplebar.min.css';

//@ts-ignore
window.globalThis = require('globalthis/polyfill')();

Vue.config.productionTip = false;

Vue.use(VueI18Next);
Vue.use(LoadingScreen);

// @ts-ignore
if (!Array.prototype.with) {
    // @ts-ignore
    Array.prototype.with = function(index, newElement) {
        return this.map((el, i) => {
            if (i !== index) return el;

            return newElement;
        });
    };
}

new Vue({
    el: '#app',
    router,
    store,
    i18n: new VueI18Next(i18next),
    render: h => h(SurveyFiller),
});

