import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import api from '@/api';
import store from '@/store/index';
import translation from '@/utils/translation.js';
import storage from '@/utils/storage';
import elementTypeEnum from '@/enums/elementTypeEnum';
import multipleRestrictionEnum, {getRestrictionMark, isTimeBasedRestriction} from '@/enums/multipleRestrictionEnum';
import PrintFiller from '@/pages/PrintFiller.vue';
import TestFiller from '@/pages/TestFiller.vue';
import SurveyFiller from '@/pages/SurveyFiller.vue';
import ErrorPage from '@/pages/ErrorPage.vue';
import dayjs from 'dayjs';
import uuidv4 from '@/utils/uuid';
import setColors from './utils/theme';
import i18next from 'i18next';
import striptags from 'striptags';
import delay from './utils/delay';
import hashedKeys from './utils/hashedKeys';
import { EmbeddedTypeEnum } from './enums/embeddedTypeEnum';

Vue.use(VueRouter);

async function initSurvey(store, shortCode)
{
    let surveyData = await api.getSurveyDetails(shortCode);

    let tries = 0;
    while ((!surveyData.survey || !surveyData.survey.id) && tries < 3) {
        tries++;
        // @ts-ignore
        await delay(1000);
        surveyData = await api.getSurveyDetails(shortCode, true);
    }

    if(surveyData.survey && surveyData.survey.is_browser_tab_title_enabled) {
        document.title = striptags(surveyData.survey.title);
    }
    if(surveyData.survey && surveyData.survey.favicon) {
        const isValid = await checkIfImageExists(surveyData.survey.favicon_url);
        const favicon = document.querySelector('link[rel="shortcut icon"]');
        if(favicon && isValid) {
            favicon.setAttribute('href', surveyData.survey.favicon_url);
        }
    }
    await translation(surveyData.survey?.language?.nickname);
    store.commit('setUrlCode', shortCode);
    store.commit('setId', surveyData.survey.id);
    store.commit('setIsFreeGroup', surveyData.isFreeGroup);
    if (surveyData.questions) {
        store.commit('setElements', surveyData.questions);
    }

    const allParametersFromUrl = new URLSearchParams(new URL(window.location.href).search.substring(1)); // We need to remove the ? from the beginning
    const allUrlParameters = [] as {name: string, value: string}[];

    if (allParametersFromUrl.has('guid')) {
        const data = await api.findSurveyParametersByGuid(allParametersFromUrl.get('guid')!);

        for (const key in data) {
            const value = data[key];

            if (key === 'previewLang' && value) {
                i18next.changeLanguage(value);
                store.commit('setPreviewLang');
                store.commit('setSurveyLanguage', value);
            }
            else if (key === 'lang' && surveyData.survey.is_multilang == true && (surveyData.translations.activeLanguages || []).includes(value.toLowerCase())) {
                i18next.changeLanguage(value.toLowerCase());
                store.commit('setSurveyLanguage', value.toLowerCase());
            }
            else {
                allUrlParameters.push({ name: key, value });
            }
        }
    }
    else {
        allParametersFromUrl.forEach(function(value, key) {
            if (key === 'previewLang' && value) {
                i18next.changeLanguage(value);
                store.commit('setPreviewLang');
                store.commit('setSurveyLanguage', value);
            }
            else if (key === 'lang' && surveyData.survey.is_multilang == true && (surveyData.translations.activeLanguages || []).includes(value.toLowerCase())) {
                i18next.changeLanguage(value.toLowerCase());
                store.commit('setSurveyLanguage', value.toLowerCase());
            }
            else {
                allUrlParameters.push({name: key, value: value});
            }
        });
    }


    if (store.state.isEmbedded) {
        const hasFullPagePopupParameter = allUrlParameters.findIndex(p => p.name === 'fullpage') >= 0;
        store.commit('setIsEmbedded', !hasFullPagePopupParameter);
    }

    if (surveyData.urlParameters && surveyData.urlParameters.length > 0) {
        store.commit('updateParameters', surveyData.urlParameters);
        const usedUrlParameters = [] as {name: string, value: string, guid: string, type: string}[];
        surveyData.urlParameters.forEach(function (surveyParam) {
            const usedUrlParameter = allUrlParameters.find(p => p.name === surveyParam.name);
            if(usedUrlParameter) {
                // Type is needed to differentiate user-defined url params from campaign-related ones
                usedUrlParameters.push({name: surveyParam.name, value: usedUrlParameter.value, guid: surveyParam.guid, type: surveyParam.type});
            }
        });
        store.commit('setUsedUrlParams', usedUrlParameters);
    }
    store.commit('setDisqualificationPages', surveyData.survey.disqualification_pages);
    store.commit('setMetaData', surveyData.survey);
    setColors(surveyData.survey.primary_color);

    store.commit('setBenchmarkLanguage', surveyData.survey.language.nickname);
    store.commit('setIsMultilang', Boolean(surveyData.survey.is_multilang));
    store.commit('setTranslations', surveyData.translations);

    if (typeof window !== 'undefined' && (window as any).FEATURES) {
        store.commit('setAccessedFeatures', (window as any).FEATURES);
    } else {
        const features = await api.getFeatures();
        const accessedFeatures = features.filter(f => parseInt(f.stage) >= surveyData.accessLevel).map(f => f.name);
        store.commit('setAccessedFeatures', accessedFeatures);
    }

    store.commit('setHasNewDictionary', surveyData.hasNewDictionary);

    if (store.state.resumableSurveyFillingGuid && store.getters['resumableSurveyFillingParameterValue']) {
        try {
            const campaignParams = allUrlParameters.reduce((fullText, param) => fullText + (param.name === 'campaign_id' || param.name === 'wave_id' ? ('&' + param.name + '=' +  param.value) : ''), '');
            await store.dispatch('resumeSurvey', campaignParams);
        }
        catch(e: any) {
            if (e.response.status !== 404) {
                Sentry.captureException(e);
            }
        }
    }
}

function checkIfImageExists(src) {
    return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => resolve(true);
        img.onerror = () => resolve(false);
        img.src = src;
    });
}

const routes: Array<RouteConfig> = [
    {
        path: '/print/:shortCode',
        component: PrintFiller,
        beforeEnter: async (to, from, next) => {
            Sentry.addBreadcrumb({
                message: 'Print survey short code',
                data: to.params.shortCode
            });
            try {
                store.commit('setIsTest', true);
                await initSurvey(store, to.params.shortCode);
                next();
            } catch (e) {
                Sentry.captureException(e);
                next({ name: 'notFound' });
            }
        }
    },
    {
        path: '/test/:shortCode',
        component: TestFiller,
        beforeEnter: async (to, from, next) => {
            Sentry.addBreadcrumb({
                message: 'Test filler survey short code',
                data: to.params.shortCode
            });
            try {
                let queryParams = to.query;

                if ('guid' in queryParams) {
                    queryParams = await api.findSurveyParametersByGuid(queryParams.guid as string);
                }

                const urlCode = to.params.shortCode;
                const isFillable = await api.checkIfFillable(urlCode, queryParams);

                if (!isFillable && isFillable !== 0) {
                    Sentry.addBreadcrumb({ message: 'It\'s not fillable' });
                    return next({ name: 'notFound' });
                }

                store.commit('setIsTest', true);
                if (queryParams.preview) {
                    store.dispatch('initPreviewMode');
                }
                await initSurvey(store, urlCode);
                next();
            } catch (e) {
                Sentry.captureException(e);
                next({ name: 'notFound' });
            }
        }
    },
    {
        path: '/cache-warmup',
        component: SurveyFiller,
        beforeEnter: (_, _2, next) => {
            Sentry.addBreadcrumb({
                message: 'Cache warmup',
                level: 'info'
            });
            next();
        }
    },
    {
        path: '/:shortCode',
        component: SurveyFiller,
        beforeEnter: async (to, _, next) => {
            Sentry.addBreadcrumb({
                message: 'Survey filler survey short code',
                data: to.params.shortCode
            });
            try {
                let queryParams = to.query;

                if ('guid' in queryParams) {
                    const campaignParams = await api.findSurveyParametersByGuid(queryParams.guid as string);
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const { guid, ...queryParamsWithoutGuid } = queryParams;
                    queryParams = {...queryParamsWithoutGuid, ...campaignParams};
                }

                if ('embedType' in queryParams) {
                    const {embedType, ...rest} = queryParams;

                    store.commit('setEmbeddedType', embedType);

                    queryParams = rest;
                }

                if ('embedType' in queryParams) {
                    const {embedType, ...rest} = queryParams;

                    store.commit('setEmbeddedType', embedType);

                    queryParams = rest;
                }

                const urlCode = to.params.shortCode;

                let isFillable;

                try {
                    isFillable = await api.checkIfFillable(urlCode, queryParams);
                }
                catch (e: any) {
                    if (e.response.status === 410) {
                        isFillable = false;
                    }
                    else {
                        return next({ name: 'notFound', params: { '0': to.path } });
                    }
                }

                await initSurvey(store, urlCode);
                (window as any).RENDER_FROM_FILLER = true;

                // If checkIfFillable endpoint gives back 0 in response, it means the user use multifilling restriction for query param and the user have already filled out the survey.
                if (!isFillable && isFillable !== 0) {
                    if (store.state.overallResponseLimitTarget) {
                        store.commit('setShowPage', store.state.overallResponseLimitTarget);
                    }
                    else {
                        Sentry.addBreadcrumb({ message: 'It\'s not fillable' });
                        return next({ name: 'notFound', params: { '0': to.path } });
                    }
                }

                if (!store.getters['editableElements'] || !store.getters['editableElements'].length) {
                    throw new Error('Survey shown with empty questions, redirect to not found page.');
                }

                let finishedAt: null|string;
                if(
                    store.state.multiFillRestriction === multipleRestrictionEnum.ONCE_IN_CAMPAIGN && queryParams['campaign_id'] ||
                    store.state.multiFillRestriction === multipleRestrictionEnum.ONCE_IN_WAVE && queryParams['campaign_id'] && queryParams['wave_id']
                ) {
                    const idBasedTimeFrame = multipleRestrictionEnum.ONCE_IN_CAMPAIGN ? '-' +  queryParams['campaign_id'] : '-' +  queryParams['campaign_id'] + '-' + queryParams['wave_id'];
                    finishedAt = storage.getItem('finished-' + urlCode + idBasedTimeFrame);
                } else {
                    finishedAt = storage.getItem('finished-' + urlCode);
                }

                const hasAlreadyFilledOutInThisTimeFrame =
                    isTimeBasedRestriction(store.state.multiFillRestriction) ?
                        dayjs(finishedAt).isAfter(getRestrictionMark(store.state.multiFillRestriction)) :
                        !!finishedAt;

                if (!store.state.isMultipleFillingOn && (store.state.multiFillRestrictionType === 'cookie' ? hasAlreadyFilledOutInThisTimeFrame : isFillable === 0)) {
                    if (!store.state.embeddedType || [EmbeddedTypeEnum.IN_PAGE, EmbeddedTypeEnum.FULL_PAGE].includes(store.state.embeddedType)) {
                        store.commit('setShowPage', store.state.singleResponseLimitTarget ?? 'thank-you');
                        Sentry.addBreadcrumb({ message: 'Has already been filled out' });
                        store.commit('setHasBeenFilled');
                    }
                }

                Object.entries(queryParams)
                    .filter(([key]) => store.state.elements.some(e => e.guid === key))
                    .forEach(([key, value]) => {
                        if (typeof value !== 'string') return;

                        const element = store.state.elements.find(e => e.guid === key);

                        if (!element) return;

                        const type = element.type;
                        const answer = Number.isNaN(+value) ? value : parseInt(value);

                        let answerToSet;

                        if (type === elementTypeEnum.RADIO) {
                            if (answer === 999 || answer === hashedKeys.DKNA) {
                                answerToSet = {
                                    value: null,
                                    other: null,
                                    dkna: true,
                                    inherited_other: null,
                                };
                            } else if (answer === 1000 || answer === hashedKeys.OTHER) {
                                answerToSet = {
                                    value: null,
                                    other: '',
                                    dkna: false,
                                    inherited_other: null,
                                };
                            } else {
                                answerToSet = {
                                    value: answer,
                                    other: null,
                                    dkna: false,
                                    inherited_other: null,
                                };
                            }
                        }
                        else if (typeof answer === 'number' && type === elementTypeEnum.SCALE && element.display_type === 'value') {
                            store.commit('setSelectedAnswer', {guid: key, index: answer});
                            answerToSet = element.ranges.at(answer)!.weight;
                        }
                        else {
                            answerToSet = answer;
                        }
                        store.commit('setAnswer', {guid: key, answer: answerToSet});
                    });

                const userId =  storage.getItem('_zid') || uuidv4();
                storage.setItem('_zid', userId);
                store.commit('setUserId', userId);
                Sentry.addBreadcrumb({ message: 'userId: ' + userId });

                store.commit('setStartedAt', new Date().toISOString());

                if (!store.state.recordId && !store.state.isAutosaveOn) {
                    const recordId = await api.initSurveyFilling(
                        to.params.shortCode,
                        store.state.answers,
                        store.getters['urlParameters'],
                        store.state.userId,
                        store.state.startedAt
                    );
                    store.commit('setRecordId', recordId);
                }

                Sentry.addBreadcrumb({ message: 'recordId: ' + store.state.recordId });

                next();
            } catch (e) {
                Sentry.captureException(e);
                return next({ name: 'notFound', params: { '0': to.path } });
            }
        },
    },
    {
        path: '*',
        name: 'notFound',
        component: ErrorPage
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router;
