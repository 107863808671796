import i18next from 'i18next';
import elementTypes from '@/enums/elementTypeEnum';
import hashedKeys from '@/utils/hashedKeys';

export default (state, element) => {
    let errorMessage : string|undefined = undefined;
    const answer = getAnswerForValidation(state, element);
    if (element.is_required && state.answers[element.guid] !== undefined && isAnswerEmpty(answer)) {
        errorMessage = i18next.t('QUESTION.VALIDATOR_ALERT_1');
    } else if (element.is_email && validateEmail(answer) === false) {
        errorMessage = i18next.t('QUESTION.VALIDATOR_ALERT_2');
    } else if (element.is_phone_number && validatePhoneNumber(answer) === false) {
        errorMessage = i18next.t('QUESTION.VALIDATOR_ALERT_3');
    } else if (element.is_matrix_vertically_restricted && validateMatrixVerticalRestriction(answer) === false) {
        errorMessage = i18next.t('QUESTION.VALIDATOR_ALERT_4');
    } else if (element.type === 9 && validateNumberLimit(answer, element) === false) {
        errorMessage = i18next.t('QUESTION.VALIDATOR_ALERT_NUMBER', {
            range1: element.ranges[0].weight,
            range2: element.ranges[1].weight,
            defaultValue: 'The answer must be between {{ range1 }} and {{ range2 }}'
        });
    } else if (element.type === elementTypes.TEXTAREA && !!element.max_length && validateMaxLength(answer, element) === false) {
        errorMessage = i18next.t('QUESTION.VALIDATOR_ALERT_TEXT_AREA_MAX_LENGTH', {
            limit: element.max_length,
            defaultValue: 'The answer length must be less than {{limit}}.'
        });
    }
    return errorMessage;
};

export function getAnswerForValidation(state, element) {
    if (state.answers[element.guid] === undefined || state.answers[element.guid] === null){
        return state.answers[element.guid];
    }
    const parentAnswer = state.answers[element.options_parent_question_guid];
    if (!parentAnswer || element.type !== elementTypes.MATRIX) {
        return state.answers[element.guid];
    }
    const answer = JSON.parse(JSON.stringify(state.answers[element.guid])) as any[];
    const newAnswer = [] as any[];
    for (const [key, value] of Object.entries(parentAnswer.value)) {
        if (element.use_selected_answers ? value === 1 : value === 0) {
            newAnswer.push(answer[key]);
        }
    }
    if (parentAnswer.other && parentAnswer.other !== '' && element.use_selected_answers){
        if (state.hasNewDictionary) {
            newAnswer.push(answer[hashedKeys.INHERITED_OTHER]);
        } else {
            newAnswer.push(answer[answer.length - 1]);
        }
    }
    return newAnswer;
}

function isAnswerEmpty(answer) {
    if (answer === undefined || answer === null) {
        return true;
    } else if (typeof answer === 'string') {
        return answer.length === 0;
    } else if (Array.isArray(answer)) {
        return answer.reduce((isEmpty, answerRow) => isEmpty || isAnswerEmpty(answerRow), false);
    } else if (answer instanceof Object && Array.isArray(answer.value)) { // then it's a checkbox, multiple values;
        return (answer.other === null || answer.other === undefined) &&
            (!answer.dkna) &&
            answer.value.every(value => value === 0);
    } else if (answer instanceof Object && Object.values(answer).every(row => row instanceof Object)) { // new dictionary matrix or order
        // @ts-ignore
        return Object.values(answer).some(row => row.other === null && row.dkna === false && row.value === null);
    } else if (answer instanceof Object && !Array.isArray(answer.value)) {
        return answer.other === null && answer.dkna === false && answer.value === null;
    } else if (isNaN(answer)) {
        return true;
    }
    return false;
}

function validateEmail(answer)
{
    if (!answer) {
        return true;
    }
    return /\S+@\S+\.\S+/.test(answer);
}

function validatePhoneNumber(answer)
{
    if (answer && answer.replace) {
        const phoneRe = /^\+?\(?(\d{1,20}\)?-?\/?\.?:?\(?){0,10}$/;
        const trimmed = answer.replace(/\s/g, '');
        return phoneRe.test(trimmed) && answer.length <= 22 && answer.length > 5;
    }
}

function validateMatrixVerticalRestriction(answer: { value: string }[] | { [key: string]: { value: { [key: string]: 0|1 } | string } })
{
    if (!answer) {
        return true;
    }
    // old dictionary
    if (Array.isArray(answer)) {
        const values = [] as string[];
        let hasMultipleInColumn = false;
        answer.forEach((answerRow) => {
            if (answerRow.value !== null && values.includes(answerRow.value)) {
                hasMultipleInColumn = true;
            }
            if (answerRow.value !== null) {
                values.push(answerRow.value);
            }
        });
        return !hasMultipleInColumn;
    }
    // new dictionary
    else {
        const values: string[] = [];

        for (const row in answer) {
            const value = answer[row].value;
            // single choice
            if (typeof value === 'string') {
                if (values.includes(value)) {
                    return false;
                }
                else {
                    values.push(value);
                }
            }
            // multi choice
            else {
                for (const guid in value) {
                    if (value[guid]) {
                        if (values.includes(guid)) {
                            return false;
                        }
                        else {
                            values.push(guid);
                        }
                    }
                }
            }
        }

        return true;
    }
}

function validateNumberLimit(answer, element) {
    if (!answer) {
        return true;
    }
    if (!element.ranges || element.ranges.length !== 2 || isNaN(parseFloat(element.ranges[0].weight)) || isNaN(parseFloat(element.ranges[1].weight))) {
        return true;
    }
    return (element.ranges[0].weight <= answer && element.ranges[1].weight >= answer);
}

function validateMaxLength(answer, element) {
    return (answer?.length || 0) <= element.max_length;
}
