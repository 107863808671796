import { computed } from 'vue';
import { useState } from 'vuex-composition-helpers';
import { LayoutSpacing } from '@/types/SettingEnums';

export default function useStyle() {
    const { text_color, answer_font_name, answer_font_weight } = useState(['text_color', 'answer_font_name', 'answer_font_weight', 'answer_font_size']);

    const labelStyle = computed(() => ({
        'color': text_color.value,
        'font-family': answer_font_name.value,
        'font-weight': answer_font_weight.value
    }));

    const labelStyleWithoutFontWeight = computed(() => ({
        'color': text_color.value,
        'font-family': answer_font_name.value
    }));

    return { labelStyle, labelStyleWithoutFontWeight };
}

export function useResponsiveFontSizeClass() {
    const { title_font_size, question_font_size, answer_font_size } = useState(['title_font_size', 'question_font_size', 'answer_font_size']);

    const getMobilSize = (size) => {
        switch (size) {
        case 'text-4xl':
            return 'mobile:text-2xl';
        case 'text-3xl':
            return 'mobile:text-xl';
        case 'text-2xl':
            return 'mobile:text-lg';
        case 'text-xl':
            return 'mobile:text-base';
        case 'text-lg':
            return 'mobile:text-sm';
        default:
            return 'mobile:text-xs';
        }
    };

    // E.g. survey title class "text-xl mobile:text-base"
    const titleFontSizeClass = computed(() => title_font_size.value + ' ' + getMobilSize(title_font_size.value));
    const questionFontSizeClass = computed(() => question_font_size.value + ' ' + getMobilSize(question_font_size.value));
    const answerFontSizeClass = computed(() => answer_font_size.value + ' ' + getMobilSize(answer_font_size.value));

    return { titleFontSizeClass, questionFontSizeClass, answerFontSizeClass };
}

export function useSpacingClass() {
    const { layout_spacing } = useState(['layout_spacing']);

    const paddingSize = computed(() => {
        switch (layout_spacing.value) {
        case LayoutSpacing.COZY:
            return 'p-1';
        case LayoutSpacing.COMPACT:
            return 'p-2';
        default:
            return 'p-3';
        }
    });

    const optionsSpace = computed(() => {
        switch (layout_spacing.value) {
        case LayoutSpacing.COZY:
            return 'space-y-1';
        case LayoutSpacing.COMPACT:
            return 'space-y-2';
        default:
            return 'space-y-3';
        }
    });

    return { paddingSize, optionsSpace };
}
