<template>
    <div
        v-if="showWithoutParent === true && element.options_parent_question_guid !== null"
        class="text-neutral-800 italic"
    >
        {{ $t('PRINT.PREVIOUS_OPTIONS') }}
    </div>
    <div
        v-else
        class="inline-block w-auto mobile:block leading-none"
        :class="[answerFontSizeClass, optionsSpace]"
        :style="{ minWidth: '280px' }"
    >
        <answer-box
            v-for="(option, index) in visibleOptions"
            :key="index"
            :selected="value === option.value"
        >
            <div
                class="flex items-center"
            >
                <z-radio
                    :id="element.guid + option.index"
                    v-model="value"
                    :value="option.value"
                    :primary-color="primary_color"
                    class="w-full"
                    :class="[paddingSize]"
                >
                    <span
                        :style="labelStyle"
                        v-html="option.optionText"
                    />
                </z-radio>
            </div>
        </answer-box>
        <answer-box
            v-if="element.has_other_option"
            :selected="value ==='other'"
            data-test-id="radio-other"
            class="other"
        >
            <div
                class="flex items-center"
            >
                <z-radio
                    v-model="value"
                    value="other"
                    :primary-color="primary_color"
                    :class="[paddingSize, {'w-full': value !== 'other'}]"
                >
                    <span :style="labelStyle">
                        {{ otherOptionLabel }}
                    </span>
                </z-radio>
                <answer-box-input
                    v-if="value === 'other'"
                    v-model="otherText"
                    :text-color="text_color"
                    :primary-color="primary_color"
                    @input="updateAnswer()"
                />
            </div>
        </answer-box>
        <answer-box
            v-if="element.has_dkna_option"
            :selected="value === 'dkna'"
            class="dkna"
        >
            <div
                class="flex items-center"
            >
                <z-radio
                    v-model="value"
                    value="dkna"
                    :primary-color="primary_color"
                    class="w-full"
                    :class="[paddingSize]"
                >
                    <span
                        :style="labelStyle"
                    >
                        {{ dknaOptionLabel }}
                    </span>
                </z-radio>
            </div>
        </answer-box>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, onBeforeMount, computed } from 'vue';
import { useActions, useGetters, useState } from 'vuex-composition-helpers';
import ZRadio from '@/components/ui/VModelRadio.vue';
import useOptions from './hooks/useOptions';
import useStyle, { useResponsiveFontSizeClass, useSpacingClass } from './hooks/useStyle';
import AnswerBox from '@/components/ui/AnswerBox.vue';
import AnswerBoxInput from '../ui/AnswerBoxInput.vue';
import hashedKeys from '@/utils/hashedKeys';

export default defineComponent({
    components: {
        ZRadio,
        AnswerBox,
        AnswerBoxInput,
    },
    props: {
        element: { type: Object, default: () => ({})},
        mobileView: Boolean,
        showWithoutParent: { type: Boolean, default: false },
    },
    setup(props) {
        const value = ref<string>();
        const otherText = ref('');

        const { primary_color, text_color, background_color, answers } = useState(['primary_color', 'text_color', 'background_color', 'answers']);
        const { setAnswer } = useActions(['setAnswer']);

        const visibleOptions = useOptions(props.element);

        const { labelStyle } = useStyle();
        const { answerFontSizeClass } = useResponsiveFontSizeClass();

        watch(value, (val) => {
            if (val !== 'other') {
                otherText.value = '';
            }
            updateAnswer();
        });

        onBeforeMount(() => {
            const answer = answers.value[props.element.guid];
            if (!answer || !(answer instanceof Object)) {
                return;
            }
            if (answer.other !== null) {
                value.value = 'other';
                otherText.value = answer.other || '';
            } else if (answer.dkna) {
                value.value = 'dkna';
            } else {
                value.value = answer.value;
            }
        });

        function updateAnswer() {
            setAnswer({
                guid: props.element.guid,
                answer: {
                    value: value.value === 'other' || value.value === 'dkna' ? null : value.value,
                    other: value.value === 'other' ? otherText.value : null,
                    dkna: value.value === 'dkna',
                }});
        }

        const { getTranslations } = useGetters(['getTranslations']);

        const otherOptionLabel = computed(() => {
            return getTranslations.value?.questions?.find(q => q.guid === props.element.guid)?.other_option_label || props.element.other_option_label;
        });

        const dknaOptionLabel = computed(() => {
            return getTranslations.value?.questions?.find(q => q.guid === props.element.guid)?.dkna_option_label || props.element.dkna_option_label;
        });

        const { paddingSize, optionsSpace } = useSpacingClass();

        return {
            value, otherText, // refs
            primary_color, text_color, background_color, // store state
            visibleOptions, labelStyle, answerFontSizeClass, // computed
            otherOptionLabel, dknaOptionLabel, // translations
            optionsSpace, paddingSize,
            updateAnswer,
            hashedKeys,
        };
    }
});
</script>
