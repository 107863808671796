<template>
    <div
        class="element-help"
        :class="helpClass"
        :style="helpStyle"
        v-html="help"
    />
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { useState } from 'vuex-composition-helpers';

import { useResponsiveFontSizeClass } from '../typeSpecificElements/hooks/useStyle';

export default defineComponent({
    props: {
        help: { type: String, default: '' },
    },
    setup() {
        const state = useState(['text_color', 'answer_font_name', 'answer_font_weight']);

        const helpStyle = computed(() => {
            return {
                color: state.text_color,
                'font-family': state.answer_font_name,
                'font-weight': state.answer_font_weight
            };
        });

        const helpClass = computed(() => {
            const { answerFontSizeClass } = useResponsiveFontSizeClass();
            return answerFontSizeClass.value;
        });
        return { helpStyle, helpClass };
    },
});
</script>
