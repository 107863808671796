<template>
    <div>
        <input
            v-model="date"
            type="date"
            :min="min"
            :max="max"
            :class="[element.validation_error_message ? 'border-red-500' : '', answerFontSizeClass]"
            class="mobile:w-full w-56 border p-2 rounded"
            :style="active ? activeBoxStyle : (hover ? hoverBoxStyle : baseBoxStyle)"
            @mouseover="hover = true"
            @mouseleave="hover = false"
            @focus="active = true"
            @focusout="active = false"
        >
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue';
import { useState, useGetters, useActions } from 'vuex-composition-helpers';
import dayjs from 'dayjs';
import { useResponsiveFontSizeClass } from './hooks/useStyle';

export default defineComponent({
    props: {
        element: { type: Object, required: true },
    },
    setup(props) {
        const hover = ref(false);
        const active = ref(false);

        const { answers } = useState(['answers']);
        const { activeBoxStyle, hoverBoxStyle, baseBoxStyle } = useGetters(['activeBoxStyle', 'hoverBoxStyle', 'baseBoxStyle']);
        const { setAnswer } = useActions(['setAnswer']);

        const min = computed(() => {
            if(!props.element.range_start) {
                return null;
            }
            return dayjs(props.element.range_start).format('YYYY-MM-DD');
        });

        const max = computed(() => {
            if(!props.element.range_end) {
                return null;
            }
            return dayjs(props.element.range_end).format('YYYY-MM-DD');
        });

        const date = computed({
            get() {
                return answers.value[props.element.guid] ||  null;
            },
            set(value: string|null) {
                const newDate = value || dayjs(value).format('YYYY-MM-DD');
                setAnswer({ guid: props.element.guid, answer: newDate });
            },
        });

        const { answerFontSizeClass } = useResponsiveFontSizeClass();

        return {
            hover, active, // refs
            activeBoxStyle, hoverBoxStyle, baseBoxStyle, // store getters
            min, max, date, // computed
            answerFontSizeClass
        };

    },
});
</script>

<style scoped lang="less">
input {
    border-radius: 0;
    border-right: 1px solid rgba(0,0,0,0);
    border-top: 1px solid rgba(0,0,0,0);
    border-left: 1px solid rgba(0,0,0,0);
    border-bottom: 1px solid #222;
}
</style>
