const elementTypeEnum = {
    EXPLANATION: 1,
    TEXT: 2,
    TEXTAREA: 3,
    CHECKBOX: 4,
    DATE: 5,
    DROPDOWN: 6,
    RADIO: 7,
    SCALE: 8,
    NUMBER: 9,
    META: 10,
    RATING: 11,
    MATRIX: 12,
    FILE: 13,
    PICTURE: 14,
    NPS: 15,
    CSAT: 16,
    ORDER: 17,
    CES_5_SCALE: 19,
    CES_7_SCALE: 20,
};

export default elementTypeEnum;
