<template>
    <iframe
        credentialless
        class="w-full"
        style="aspect-ratio: 16/9;"
        :src="url"
        frameborder="0"
        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
    />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        url: { type: String, required: true },
    }
});
</script>
