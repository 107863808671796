<template>
    <img
        :src="src"
        :alt="alt"
        crossorigin="anonymous"
    >
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        src: { type: String, required: true },
        alt: { type: String, default: null },
    },
});
</script>

<style scoped>
img {
    max-width: 400px;
    max-height: 200px;
    margin-bottom: 10px;
}

@media (max-width: 600px) {
    img {
        max-height: 15vh;
    }
}
</style>
