var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.closeMenu),expression:"closeMenu"}],ref:"dropdown",staticClass:"relative rounded",class:_vm.fontSizeClass,attrs:{"data-scope-id":_vm.manualScope,"data-test-id":"dropdown"}},[_c('v-style',[_vm._v(" [data-scope-id=\""+_vm._s(_vm.manualScope)+"\"] .dropdown-item:hover, .dropdown-item:hover div { background-color: "+_vm._s(_vm.primaryColor)+"; color: "+_vm._s(_vm.buttonTextColor)+" !important; } ")]),_c('div',{staticClass:"flex justify-between items-center w-full p-2 transform-none overflow-hidden shadow-none cursor-pointer rounded border",class:_vm.isError ? 'border-red-500' : '',style:(_vm.showMenu ? _vm.activeBoxStyle : (_vm.hover ? _vm.hoverBoxStyle : _vm.baseBoxStyle)),attrs:{"data-test-id":"dropdown-toggle"},on:{"mouseover":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false},"click":function($event){return _vm.toggleMenu()}}},[(_vm.showSearch)?_c('input',{ref:"input",staticClass:"w-full bg-transparent",style:({
                backgroundColor: _vm.backgroundColor,
                color: _vm.textColor,
            }),attrs:{"placeholder":_vm.placeholderText},domProps:{"value":_vm.searchText},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.stopPropagation();return _vm.addSearchTextAsOther.apply(null, arguments)},"input":function($event){_vm.searchText = $event.target.value}}}):_c('span',{staticClass:"truncate -mr-2",domProps:{"innerHTML":_vm._s(_vm.selectedOption && _vm.selectedOption.name !== undefined ? _vm.selectedOption.name : _vm.placeholderText)}}),_c('font-awesome-icon',{staticClass:"ml-2",style:({ color: _vm.showMenu ? _vm.primaryColor : _vm.textColor}),attrs:{"icon":['fal', _vm.showMenu ? 'chevron-up' : 'chevron-down']}})],1),(_vm.showMenu && _vm.hasOptions)?_c('simplebar',{staticClass:"condition-dropdown-menu absolute w-full left-0 list-none rounded-b bg-clip-padding border",staticStyle:{"top":"100%","margin-top":"-4px","z-index":"1000"},style:({
            borderColor: _vm.primaryColor,
            backgroundColor: _vm.backgroundColor,
            color: _vm.textColor,
            maxHeight: Math.min(_vm.maxHeight, 310) + 'px'
        }),attrs:{"data-test-id":"dropdown-menu"},on:{"scroll":() => ({})}},[(_vm.showOtherOption)?_c('li',{staticClass:"overflow-hidden w-full",class:{'border-b border-neutral-500': _vm.dknaOption === null}},[_c('div',{staticClass:"dropdown-item p-2",class:{
                    'cursor-default': _vm.searchText === '',
                    'text-neutral-600': _vm.searchText === '',
                    'cursor-pointer': _vm.searchText !== '',
                },style:({borderColor: _vm.primaryColor}),on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.addSearchTextAsOther.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.searchText)+" "),_c('div',{staticClass:"text-yellow-800 text-sm"},[_vm._v(" "+_vm._s(_vm.otherOptionTooltip)+" ")])])]):_vm._e(),(_vm.showDknaOption)?_c('li',{staticClass:"overflow-hidden w-full border-b border-neutral-500"},[_c('div',{staticClass:"dropdown-item p-2",class:{
                    'cursor-default': _vm.selectedOption.value === 'dkna',
                    'text-neutral-600': _vm.selectedOption.value === 'dkna',
                    'cursor-pointer': _vm.selectedOption.value !== 'dkna',
                },style:({borderColor: _vm.primaryColor}),on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.addDknaOption.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.dknaOption)+" ")])]):_vm._e(),_vm._l((_vm.filteredOptions),function(option,index){return _c('li',{key:index,staticClass:"overflow-hidden w-full"},[_c('div',{staticClass:"dropdown-item p-2",class:{
                    'cursor-default': option.value === false,
                    'text-neutral-600': option.value === false,
                    'cursor-pointer': option.value !== false,
                },style:({borderColor: _vm.primaryColor}),domProps:{"innerHTML":_vm._s(option.name)},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();option.value !== false ? _vm.updateOption(option) : ''}}})])})],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }