declare global {
    interface Window {
        isFacebookPixelEnabled: boolean;
    }

    function fbq(command: string, eventName: string, eventParams?: any): void;
}
export default {
    events: {
        SURVEY_NEXT: 'ZurveySurveyNext',
        SURVEY_PREV: 'ZurveySurveyPrev',
        SURVEY_SUBMIT: 'ZurveySurveySubmit',
        SURVEY_DISQUALIFIED: 'ZurveySurveyDisqualified'
    },

    loadFbPixelScript(fbPixelId: string|null) {
        if (!fbPixelId) {
            window.isFacebookPixelEnabled = false;
            return;
        }

        window.isFacebookPixelEnabled = true;

        const scriptContent =
            `!function(f,b,e,v,n,t,s)
             {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
             n.callMethod.apply(n,arguments):n.queue.push(arguments)};
             if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
             n.queue=[];t=b.createElement(e);t.async=!0;
             t.src=v;s=b.getElementsByTagName(e)[0];
             s.parentNode.insertBefore(t,s)}(window, document,'script',
             'https://connect.facebook.net/en_US/fbevents.js');
             fbq('init', '${fbPixelId}');`;

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.innerHTML = scriptContent;
        document.head.appendChild(script);

        const noscript = document.createElement('noscript');
        noscript.innerHTML = `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${fbPixelId}&noscript=1"/>`;
        document.head.appendChild(noscript);
    },

    track(event, properties = {}) {
        if (!window.isFacebookPixelEnabled) {
            return;
        }

        try {
            fbq('trackCustom', event, properties);
        } catch (e: any) {
            console.warn('Facebook pixel error: ' + e.message);
        }
    },
};
