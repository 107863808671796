import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft }  from '@fortawesome/pro-light-svg-icons/faChevronLeft';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faChevronUp } from '@fortawesome/pro-light-svg-icons/faChevronUp';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons/faExclamationCircle';
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock';
import { faBars } from '@fortawesome/pro-light-svg-icons/faBars';
import { faEllipsisH } from '@fortawesome/pro-light-svg-icons/faEllipsisH';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons/faQuestionCircle';
import { faSmileBeam } from '@fortawesome/pro-solid-svg-icons/faSmileBeam';
import { faSmileBeam as faLightSmileBeam } from '@fortawesome/pro-light-svg-icons/faSmileBeam';
import { faFrown as faLightFrown} from '@fortawesome/pro-light-svg-icons/faFrown';
import { faMeh as faLightMeh } from '@fortawesome/pro-light-svg-icons/faMeh';
import { faHeart as faLightHeart } from '@fortawesome/pro-light-svg-icons/faHeart';
import { faHeartBroken as faLightHeartBroken } from '@fortawesome/pro-light-svg-icons/faHeartBroken';
import { faThumbsUp as faLightThumbsUp } from '@fortawesome/pro-light-svg-icons/faThumbsUp';
import { faThumbsDown as faLightThumbsDown } from '@fortawesome/pro-light-svg-icons/faThumbsDown';
import { faSmileBeam as faRegularSmileBeam } from '@fortawesome/pro-regular-svg-icons/faSmileBeam';
import { faFrown as faRegularFrown} from '@fortawesome/pro-regular-svg-icons/faFrown';
import { faMeh as faRegularMeh } from '@fortawesome/pro-regular-svg-icons/faMeh';
import { faHeart as faRegularHeart } from '@fortawesome/pro-regular-svg-icons/faHeart';
import { faHeartBroken as faRegularHeartBroken } from '@fortawesome/pro-regular-svg-icons/faHeartBroken';
import { faThumbsUp as faRegularThumbsUp } from '@fortawesome/pro-regular-svg-icons/faThumbsUp';
import { faThumbsDown as faRegularThumbsDown } from '@fortawesome/pro-regular-svg-icons/faThumbsDown';
import { faSmile as faRegularSmile } from '@fortawesome/pro-regular-svg-icons/faSmile';
import { faFrown } from '@fortawesome/pro-solid-svg-icons/faFrown';
import { faMeh } from '@fortawesome/pro-solid-svg-icons/faMeh';
import { faSmile } from '@fortawesome/free-solid-svg-icons/faSmile';
import { faSmile as lightSmile } from '@fortawesome/pro-light-svg-icons/faSmile';
import { faHeart } from '@fortawesome/free-solid-svg-icons/faHeart';
import { faHeartBroken } from '@fortawesome/free-solid-svg-icons/faHeartBroken';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons/faThumbsUp';
import { faThumbsDown } from '@fortawesome/free-solid-svg-icons/faThumbsDown';

library.add(
    faChevronLeft,
    faTimes,
    faChevronDown,
    faChevronUp,
    faExclamationCircle,
    faClock,
    faBars,
    faEllipsisH,
    faQuestionCircle,
    faSmileBeam,
    faLightSmileBeam,
    faLightFrown,
    faLightMeh,
    faLightHeart,
    faLightHeartBroken,
    faLightThumbsUp,
    faLightThumbsDown,
    faRegularSmileBeam,
    faRegularFrown,
    faRegularMeh,
    faRegularHeart,
    faRegularHeartBroken,
    faRegularThumbsUp,
    faRegularThumbsDown,
    faRegularSmile,
    faFrown,
    faMeh,
    faSmile,
    lightSmile,
    faHeart,
    faHeartBroken,
    faThumbsUp,
    faThumbsDown
);
