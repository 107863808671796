<template>
    <div class="max-w-128 mx-auto">
        <rating-component
            :type="element.display_type.split('_')[0]"
            :icon-style="element.display_type.split('_')[1]"
            :value="value"
            :active-color="element.colors && element.colors[0]"
            :base-color="element.colors && element.colors[1]"
            @clicked="updateAnswer($event)"
        />
        <div
            v-if="element.rating_labels && element.rating_labels.length > 0"
            class="flex justify-around"
        >
            <div
                v-for="index in 5"
                :key="index"
                class="w-1/5 text-center text-small break-words p-1"
                :style="{ color: text_color }"
            >
                {{ ratingLabels[index-1] }}
            </div>
        </div>
        <div>
            <answer-box
                v-if="has_dkna_option"
                class="mt-2 inline-block mobile:block"
                :selected="value === dkna_option_value"
            >
                <vm-radio
                    :checked="value"
                    :value="dkna_option_value"
                    :primary-color="primary_color"
                    class="w-full p-1.5"
                    @change="updateAnswer"
                >
                    {{ dknaOptionLabel }}
                </vm-radio>
            </answer-box>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { useActions, useGetters, useState } from 'vuex-composition-helpers';
import RatingComponent from './RatingComponent.vue';
import VmRadio from '@/components/ui/VModelRadio.vue';
import AnswerBox from '../ui/AnswerBox.vue';

export default defineComponent({
    components: {
        RatingComponent, VmRadio, AnswerBox
    },
    props: {
        element: { type: Object, default: () => ({})},
    },
    setup(props) {
        const { primary_color, answers, text_color } = useState(['primary_color', 'answers', 'text_color']);
        const { setAnswer } = useActions(['setAnswer']);

        const value = computed(() => answers.value[props.element.guid] || 0);

        function updateAnswer(e) {
            setAnswer({ guid: props.element.guid, answer: e });
        }

        const { has_dkna_option, dkna_option_value } = props.element;

        const { getTranslations } = useGetters(['getTranslations']);

        const ratingLabels = computed(() => getTranslations.value?.questions?.find(q => q.guid === props.element.guid)?.rating_labels || props.element.rating_labels);

        const dknaOptionLabel = computed(() => {
            return getTranslations.value?.questions?.find(q => q.guid === props.element.guid)?.dkna_option_label || props.element.dkna_option_label;
        });

        return {
            primary_color, value, updateAnswer, has_dkna_option, dkna_option_value, ratingLabels, dknaOptionLabel, text_color
        };
    },
});
</script>
