import axios from 'axios';
import globals from '@/utils/globals';

export const api = axios.create({ baseURL: globals.getApiBaseUrl() });

export const java = axios.create({ baseURL: globals.getIngressBaseUrl() });

const guidParamCache = new Map<string, Record<string, string>>();

export default {
    async getFeatures() {
        if (typeof window !== 'undefined' && (window as any).FEATURES) {
            return (window as any).FEATURES;
        }
        const { data } = await api.get('/getFeatures');
        return data;
    },
    async checkPassword({ id, password }) {
        const { data } = await api.post('/filler/password/checksurveypassword', { id, password });
        return data;
    },
    async upload(file) {
        const formData = new FormData();
        formData.append('file', file);
        const data = await api.post('/filler/upload', formData, {
            headers: {'Content-Type': 'multipart/form-data'}
        });
        return data.data;
    },
    async checkIfFillable(shortCode, queryParams) {
        if (typeof window !== 'undefined' && (window as any).IS_FILLABLE !== undefined) {
            return (window as any).IS_FILLABLE;
        }
        const { data } = await api.post(`/filler/checkIfFillable/${shortCode}`, { queryParams, withCredentials: true });
        return data;
    },
    async reportSurvey(id) {
        const { data } = await api.post(`/filler/reportSurvey/${id}`, { withCredentials: true });
        return data;
    },
    async getSurveyDetails(shortCode, force?) {
        if (typeof window !== 'undefined' && (window as any).SURVEY_DATA && !force) {
            return (window as any).SURVEY_DATA;
        }
        const { data } = await api.get(`/filler/getSurveyDetails/${shortCode}`, { withCredentials: true });
        return data;
    },
    async initSurveyFilling(shortCode, answers, parameters, userId, startedAt) {
        const { data } = await java({
            method: 'post',
            url: `/survey/init/${shortCode}`,
            data: {
                answers,
                parameters,
                startedAt,
                submittedAt: new Date().toISOString(),
                userId,
                pageId: 0,
            },
            headers: {
                'Content-Type': 'text/plain',
                'ntcl_survey_user_guid': userId,
                'ntcl_survey_page_id': 0
            }
        });
        return data.id;
    },
    async saveAnswers(shortCode, answers, parameters, startedAt, userId, recordId, language, currentPage) {
        await java({
            method: 'post',
            url: `/survey/add/${shortCode}`,
            data: {
                answers,
                parameters,
                startedAt,
                submittedAt: new Date().toISOString(),
                userId,
                recordId,
                language,
                pageId: currentPage,
                completed: false
            },
            headers: {
                'Content-Type': 'text/plain',
                'ntcl_survey_user_guid': userId,
                'ntcl_survey_page_id': currentPage
            }
        });
    },
    async submit({ shortCode, answers, parameters, startedAt, userId, recordId, language, currentPage, closingPageType }) {
        await java({
            method: 'post',
            url: `/survey/add/${shortCode}`,
            data: {
                answers,
                parameters,
                startedAt,
                submittedAt: new Date().toISOString(),
                userId,
                recordId,
                language,
                pageId: currentPage,
                closingPageType,
                completed: true
            },
            headers: {
                'Content-Type': 'text/plain',
                'ntcl_survey_user_guid': userId,
                'ntcl_survey_page_id': currentPage
            }
        });
    },

    async findSurveyParametersByGuid (guid: string): Promise<Record<string, string>> {
        // NOTE(adam): We cache the parameters by guid, because:
        // 1. at least 2 code paths call this function externally
        // 2. I guess that both code paths would call this function with the same guid
        // 3. we don't want to make 2 requests for the same guid
        let params = guidParamCache.get(guid);

        if (!params) {
            const { data } = await api.get(`/filler/findQueryParams/${ guid }`, { withCredentials: true }) as { data: Record<string, string> };

            guidParamCache.set(guid, params = data);
        }

        return params;
    },
    async loadAnswers(datasetId, value, campaignParams: string): Promise<any> {
        const { data } = await api.get(`/filler/getAnswer/${datasetId}?identifier=${ value }${campaignParams}`);

        return data;
    },
    async getSurveyQuestionOptions({ datasetId, dimensionId, type, langId = undefined }) {
        const { data } = await api.get(`/filler/${datasetId}/${dimensionId}/${type}/options${ langId ? '/' + langId : ''}`);
        return data;
    },
};
