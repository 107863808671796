import { postMessageToParent } from '@/utils/iframe';
import { useState } from 'vuex-composition-helpers';

function getRedirectUrl(url, urlParams, inherit) {
    let redirectUrl = url;

    if (inherit) {
        // Filter out campaign-related url params
        const userDefinedUrlParams = urlParams.filter(p => ['unique', 'collector'].includes(p.type));
        if (userDefinedUrlParams.length > 0) {
            redirectUrl += userDefinedUrlParams.reduce((string, urlParam, i) => {
                const concatChar = i === 0 && !redirectUrl.includes('?') ? '?' : '&';
                const urlParamValue = urlParam.name + '=' + urlParam.value;
                return string + concatChar + urlParamValue;
            }, '');
        }
    }

    return redirectUrl;
}

function redirect({ url, delay, openInNewTab, inheritUrlParams = false }) {
    const { isNewEmbedded, usedUrlParams, isPreviewMode } = useState(['isNewEmbedded', 'usedUrlParams', 'isPreviewMode']);

    return setTimeout(() => {
        const redirectUrl = getRedirectUrl(url, usedUrlParams.value, inheritUrlParams);

        if (!isNewEmbedded.value && !isPreviewMode.value) {
            if (openInNewTab) {
                window.open(redirectUrl, '_blank');
            }
            else {
                window.location.replace(redirectUrl);
            }
        }
        else {
            postMessageToParent({ url: redirectUrl });
        }

    }, (delay || 0.1) * 1000);
}

export default function useRedirect() {
    return {
        redirect
    };
}
