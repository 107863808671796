<template>
    <div>
        <router-view />
        <toaster-container />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ToasterContainer from '@/components/ui/ToasterContainer.vue';

export default defineComponent({
    name: 'SurveyFiller',
    components: {
        ToasterContainer
    },
    created() {
        //@ts-ignore
        this.$load.start();
    }
});
</script>
