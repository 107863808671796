import store from '@/store';
import { hex2rgba } from './color';

export const getPanelStyle = (width) => {
    const { bgImage, background_color } = store.state as any;

    if (bgImage && bgImage.panelSettings && width >= 1024) {
        const blur = bgImage.panelSettings?.blur || 0;
        const opacity = bgImage.panelSettings?.opacity ? (bgImage.panelSettings.opacity / 100) : 1.0;
        const bgColor = bgImage.panelSettings?.bgColor || '#ffffff';
        return {
            'background-color': hex2rgba(bgColor, opacity),
            'backdrop-filter': 'blur(' + blur + 'px)',
        };
    }
    else if (bgImage?.panelSettings) {
        return {
            'background-color': bgImage?.panelSettings?.bgColor || background_color
        };
    }
    return {
        'background-color': background_color
    };
};

export const getSurveyBgStyle = (width) => {
    const { bgImage, background_color, shouldShowBgImageOnEmbed } = store.state as any;

    if(bgImage && width >= 1024 && shouldShowBgImageOnEmbed) {
        const blur = bgImage?.blur || 0;
        const opacity = bgImage?.opacity ? (bgImage?.opacity / 100) : 1.0;
        const contrast = bgImage?.contrast || 100;
        return {
            'background-image': 'url(' + bgImage?.url + ')',
            'background-repeat': 'no-repeat',
            'background-size': 'cover',
            'background-position': 'center center',
            'background-color': background_color,
            'opacity': opacity,
            'filter': 'blur(' + blur + 'px) contrast(' + contrast + '%)',
        };
    }
    else if (bgImage?.panelSettings) {
        return {
            'background-color': bgImage.panelSettings?.bgColor || background_color
        };
    }
    return {
        'background-color': background_color
    };
};
