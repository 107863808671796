declare global {
    interface Window {
        dataLayer: any[];
        isGTMEnabled: boolean;
    }
}
export default {
    events: {
        SURVEY_NEXT: 'ZurveySurveyNext',
        SURVEY_PREV: 'ZurveySurveyPrev',
        SURVEY_SUBMIT: 'ZurveySurveySubmit',
        SURVEY_DISQUALIFIED: 'ZurveySurveyDisqualified'
    },
    loadGTMScript(GTMId) {
        if (!GTMId) {
            window.isGTMEnabled = false;
            return;
        }

        window.isGTMEnabled = true;
        const scriptContent =
            `(function(w,d,s,l,i){
              w[l]=w[l]||[];
              w[l].push({'gtm.start': new Date().getTime(), event:'gtm.js'});
              var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),
                  dl=l!='dataLayer'?'&l='+l:'';
              j.async=true;
              j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
              f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${GTMId}');`;

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.innerHTML = scriptContent;
        document.head.appendChild(script);

        const noscript = document.createElement('noscript');
        noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${GTMId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
        document.body.appendChild(noscript);

    },
    track(event, properties = {}, isEmbedded = false) {
        if (!window.isGTMEnabled) {
            return;
        }
        window.dataLayer = window.dataLayer || [];
        try {
            window.dataLayer.push({
                event: event,
                ...properties,
            });

            if (isEmbedded) {
                window.parent.postMessage({
                    event: event,
                    ...properties
                }, '*');
            }
        } catch (e: any) {
            console.warn('GTM error: ' + e.message);
        }
    },
};
