import { render, staticRenderFns } from "./Image.vue?vue&type=template&id=74071be1&scoped=true"
import script from "./Image.vue?vue&type=script&lang=ts"
export * from "./Image.vue?vue&type=script&lang=ts"
import style0 from "./Image.vue?vue&type=style&index=0&id=74071be1&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74071be1",
  null
  
)

export default component.exports