<template>
    <img
        class="element-image w-full"
        :src="url"
        :alt="alt"
        crossorigin="anonymous"
    >
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        url: { type: String, required: true },
        alt: { type: String, default: '' },
    },
});
</script>

<style scoped>
img.element-image {
    max-height: 100% !important;
    margin: 0 !important;
}
</style>
