<template>
    <div
        class="flex items-center text-red-500 mt-2 p-1.5 space-x-1"
        data-test-id="validation-error"
        :class="[questionFontSizeClass]"
    >
        <icon
            :icon="['fal', 'exclamation-circle']"
            class="fa-icons"
        />
        <span>
            {{ error }}
        </span>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { useResponsiveFontSizeClass } from '../typeSpecificElements/hooks/useStyle';

export default defineComponent({
    components: {
        'icon': FontAwesomeIcon,
    },
    props: {
        error: { type: String, default: '' },
    },
    setup() {
        const { questionFontSizeClass } = useResponsiveFontSizeClass();

        return {questionFontSizeClass};
    },
});
</script>
