<template>
    <div>
        <div
            v-if="showWithoutParent === true && element.options_parent_question_guid !== null"
            class="text-neutral-800 italic"
        >
            {{ $t('PRINT.PREVIOUS_OPTIONS') }}
        </div>
        <drop-down
            v-else
            :style="{'font-family': answer_font_name, 'font-weight': answer_font_weight }"
            class="name"
            :is-filterable="true"
            :options="options"
            :selected="selected"
            :text-color="text_color"
            :background-color="bgImage && bgImage.panelSettings ? bgImage.panelSettings.bgColor : background_color"
            :primary-color="primary_color"
            :is-error="!!element.validation_error_message"
            :search-only="element.search_only_options"
            :has-other-option="element.has_other_option"
            :dkna-option="dknaOption"
            @update-option="updateAnswer"
        />
    </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, watch } from 'vue';
import { useState, useActions, useGetters, useMutations } from 'vuex-composition-helpers';
import DropDown from '@/components/ui/DropDown.vue';
import useOptions from './hooks/useOptions';

export default defineComponent({
    components: {
        DropDown,
    },
    props: {
        element: { type: Object, required: true},
        showWithoutParent: { type: Boolean, default: false },
    },
    setup(props) {
        const { answer_font_name, answer_font_weight, answer_font_size, text_color, primary_color, background_color, answers, bgImage, benchmarkLanguage, surveyLanguage, dropdownWithOptionsFromFileAnswers } = useState([
            'answer_font_name',
            'answer_font_weight',
            'answer_font_size',
            'text_color',
            'primary_color',
            'background_color',
            'answers',
            'bgImage',
            'benchmarkLanguage',
            'surveyLanguage',
            'dropdownWithOptionsFromFileAnswers',
        ]);
        const mutations = useMutations(['updateDropdownWithOptionsFromFileAnswer']);
        const actions = useActions(['setAnswer', 'getSurveyQuestionOptions', 'getSurveyQuestionOptionsTranslations']);

        const { getTranslations } = useGetters(['getTranslations']);

        const visibleOptions = computed(() => useOptions(props.element).value);

        const options = computed(() => {
            return visibleOptions.value.map((option, index) => ({
                name: option.optionText,
                value: option.value,
                index,
            }));
        });

        const dknaOption = computed(() => {
            return props.element.has_dkna_option ?
                getTranslations.value?.questions?.find(q => q.guid === props.element.guid)?.dkna_option_label || props.element.dkna_option_label :
                null;
        });

        const selected = computed(() => {
            const answer = answers.value[props.element.guid];
            if (!answer) {
                return undefined;
            }
            if (answer.dkna) {
                return { name: dknaOption.value, value: 'dkna' };
            }
            if (answer.other) {
                return { name: answer.other, value: 'other' };
            }

            if (props.element.options_from_file && typeof dropdownWithOptionsFromFileAnswers.value[props.element.guid] === 'number') {
                return options.value.at(dropdownWithOptionsFromFileAnswers.value[props.element.guid]);
            }

            // First check options.value as it handles translation, original options should be the fallback

            return options.value.find(o => o.value === answer.value);
        });

        function updateAnswer(option) {
            actions.setAnswer({ guid: props.element.guid, answer: {
                value: option.value === 'dkna' || option.value === 'other' ? null : option.value,
                other: option.value === 'other' ? option.name : null,
                dkna: option.value === 'dkna',
            }});

            if (props.element.options_from_file) {
                mutations.updateDropdownWithOptionsFromFileAnswer({ guid: props.element.guid, index: option.index });
            }
        }

        async function getSurveyQuestionOptions() {
            try {
                if (
                    props.element.options_id != undefined
                    && visibleOptions.value.length === 0
                ) {
                    await actions.getSurveyQuestionOptions(props.element.guid);
                }
            }
            catch (e) {
                Sentry.addBreadcrumb({
                    message: 'Couldn\'t load survey question options',
                    data: {
                        guid: props.element.guid,
                        optionsId: props.element.options_id,
                    }
                });
                Sentry.captureException(e);
            }
        }

        async function getSurveyQuestionOptionsTranslations() {
            try {
                if (
                    props.element.options_id != undefined
                    && surveyLanguage.value
                    && surveyLanguage.value !== benchmarkLanguage.value
                ) {
                    await actions.getSurveyQuestionOptionsTranslations(props.element.guid);
                }
            }
            catch (e: any) {
                Sentry.addBreadcrumb({
                    message: 'Couldn\t load survey question option translations',
                    data: {
                        guid: props.element.guid,
                        optionsId: props.element.options_id,
                        language: surveyLanguage.value,
                    }
                });
                Sentry.captureException(e);
            }
        }

        onMounted(() => {
            getSurveyQuestionOptions();
            getSurveyQuestionOptionsTranslations();
        });

        watch(surveyLanguage, () => {
            getSurveyQuestionOptionsTranslations();
        });

        return {
            answer_font_name, answer_font_weight, answer_font_size,
            text_color, primary_color, background_color, bgImage, // store state
            options, dknaOption, selected, // computed
            updateAnswer, // methods
            visibleOptions,
        };
    },
});
</script>
