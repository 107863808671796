<template>
    <div
        class="footer"
        :class="{ 'bg-neutral-200 text-black': !hasBackground }"
        :style="hasBackground ? {...panelStyle, 'color': text_color} : null"
    >
        <div v-html="createdWith" /><br>
        <div>
            {{ $t('PRIVACY.DATA_WITH_SECURITY') }}
        </div>
        <div>
            {{ $t('PRIVACY.READ_MORE') }}
            <a
                href="https://zurvey.io/zurvey/en/privacy-policy"
                class="pp-link"
                target="_blank"
            >
                {{ $t('PRIVACY.OUR_PP') }}
            </a>.
        </div>
        <div
            v-if="hasOwnPrivacyPolicy && privacyPolicyUrl"
            v-html="ppLine"
        />
        <div v-if="isFreeGroup">
            {{ $t('PHISHING.MISLEADING_SURVEY', 'If you find this survey in any way misleading or abusive') }},
            <span
                data-test-id="report-survey"
                class="underline cursor-pointer"
                @click="$emit('reportSurvey')"
            >
                {{ $t('PHISHING.REPORT_SURVEY', 'report this survey') }}
            </span>
        </div>
    </div>
</template>

<script>
import { computed, onMounted, onUnmounted, ref } from 'vue';
import {postMessageToParent} from '@/utils/iframe';
import { getPanelStyle } from '@/utils/getSurveyStyle';
import { mapState } from 'vuex';

export default {
    props:{
        hasOwnPrivacyPolicy: { type: Boolean, default: null },
        privacyPolicyCompany: { type: String, default: null },
        privacyPolicyUrl: { type: String, default: null },
        isFreeGroup: { type: Boolean, default: false }
    },
    setup() {
        const width = ref(window.innerWidth);

        const handleResize = (event) => width.value = event.target.innerWidth;
        onMounted(() => window.addEventListener('resize', handleResize));
        onUnmounted(() => window.removeEventListener('resize', handleResize));

        const panelStyle = computed(() => getPanelStyle(width.value));

        const hasBackground = computed(() => panelStyle.value['backdrop-filter']);

        return { panelStyle, hasBackground };
    },
    computed: {
        ...mapState(['text_color']),
        ppLine() {
            return this.$t('PRIVACY.CLIENT_PP_LINE', {
                replace: { privacy_policy_link: this.ppLink, company: this.privacyPolicyTitle },
                interpolation: { escapeValue: false },
                defaultValue: 'You can also learn more about the {{- privacy_policy_link}} of {{company}}'
            });
        },
        ppLink() {
            return `<a href="${this.privacyPolicyUrl}" class="pp-link underline" target="_blank" rel="noopener">${this.$t('PRIVACY.PRIVACY_POLICY', 'privacy policy')}</a>`;
        },
        privacyPolicyTitle() {
            return this.$store.state.privacyPolicyTitle || 'survey creator';
        },
        iframePp() {
            if (this.hasOwnPrivacyPolicy && this.privacyPolicyUrl) {
                const creatorLink = `<a href="${this.privacyPolicyUrl}" target="_blank" rel="noopener" style="color: var(--color-primary-700); text-decoration: underline;">${this.privacyPolicyTitle}</a>`;
                const zurveyLink = '<a href="https://zurvey.io/zurvey/en/privacy-policy" target="_blank" rel="noopener" style="color: var(--color-primary-700); text-decoration: underline;">zurvey.io</a>';
                return this.$t('PRIVACY.POPUP_FOOTER', {
                    replace: { survey_creator_link: creatorLink, zurvey_link: zurveyLink },
                    interpolation: { escapeValue: false },
                    defaultValue: 'Learn more about {{- survey_creator_link}}\'s and {{- zurvey_link}}\'s privacy policy.'
                });
            }

            return `<a href="https://zurvey.io/zurvey/en/privacy-policy" target="_blank" style="color:var(--color-primary-700); text-decoration: underline;">
                    ${this.$t('PRIVACY.IFRAME_PP', 'Privacy policy')}
                    </a>`;
        },
        createdWith() {
            return this.$t('PRIVACY.CREATED_WITH_ZURVEY_IO', {
                zurvey: '<a href="https://www.zurvey.io" target="_blank" rel="noopener" style="color:inherit">Zurvey.io</a>',
                interpolation: { escapeValue: false },
                defaultValue: 'Powered by {{zurvey}}'
            });
        }
    },
    mounted() {
        postMessageToParent({
            createdWith: this.createdWith,
            ourPp: this.iframePp,
            ppLine: this.ppLine,
            care: this.$t('PRIVACY.DATA_WITH_SECURITY'),
        });
    }
};
</script>

<style scoped>
.footer {
    width: 100%;
    line-height: 15px;
    font-size: 12px;
    text-align: center;
    display: block;
    padding: 15px;
}
.pp-link{
    text-decoration: underline;
}
</style>
