<template>
    <div
        data-iframe-height
        class="wrapper thank-you-wrapper"
        :class="{ embedded: isEmbedded, 'new-embedded': isNewEmbedded }"
    >
        <v-style>
            .thank-you-wrapper a { color: {{ primary_color }}; }
            .thank-you-wrapper a:hover { text-decoration: underline; }
        </v-style>
        <div
            v-if="!isFinished"
            class="thank-you"
        >
            <h1
                :style="titleStyle"
                :class="titleClass"
                v-html="title"
            />
            <img
                v-if="element.image"
                crossorigin="anonymous"
                class="finish-picture"
                :src="element.image"
            >
            <section
                :style="descriptionStyle"
                :class="descriptionClass"
                v-html="description"
            />
        </div>
        <div
            v-else
            class="thank-you-page"
        >
            <div class="thank-you">
                <responsive-image
                    v-if="element.logo_url && !isEmbedded"
                    :src="element.logo_url"
                    alt="Logo"
                    class="mx-auto"
                />
                <h1
                    :style="titleStyle"
                    :class="titleClass"
                    v-html="title"
                />
                <img
                    v-if="element.image"
                    crossorigin="anonymous"
                    class="finish-picture"
                    :src="element.image"
                >
                <section
                    :style="descriptionStyle"
                    :class="descriptionClass"
                    v-html="description"
                />
            </div>
            <!--  Tmp switch off footer    <footer-wrapper />-->
        </div>
    </div>
</template>

<script>
import {computed, onMounted, onUnmounted} from 'vue';
import { mapState } from 'vuex';

import VStyle from '@/components/ui/Style.vue';
import ResponsiveImage from '@/components/ui/ResponsiveImage.vue';
import { useResponsiveFontSizeClass } from './typeSpecificElements/hooks/useStyle';

import useRedirect from '@/utils/redirect';
import gtm from '@/utils/gtm';
import {useGetters, useState} from 'vuex-composition-helpers';
import striptags from 'striptags';
import googleAnalytics from '@/utils/googleAnalytics';
import facebookPixel from '@/utils/facebookPixel';

export default {
    components: {
        VStyle,
        ResponsiveImage,
    },
    props: {
        element: { type: Object, default: () => ({
            order: null,
            title: null,
            description: null,
            image: null,
            redirect: null,
        })},
        urlParamsForTracking: { type: Object, default: () => ({}) }
    },
    setup(props) {
        const { redirect } = useRedirect();
        const { urlCode, recordId, isEmbedded, isNewEmbedded } = useState(['urlCode', 'recordId', 'isEmbedded', 'isNewEmbedded']);
        const { getTranslations } = useGetters(['getTranslations']);
        let timer;

        const title = computed(() =>  getTranslations?.disqualification_pages?.[props.element.order -1]?.title || props.element.title);

        onMounted(() => {
            addTracking();

            if (!props.element.redirect) return;

            timer = redirect(props.element.redirect);
        });

        onUnmounted(() => clearTimeout(timer));

        function addTracking() {
            let properties = {
                recordId: recordId.value,
                surveyUniqueName: urlCode.value,
                disqualificationPageTitle: striptags(title.value),
                ...props.urlParamsForTracking
            };

            gtm.track(
                gtm.events.SURVEY_DISQUALIFIED,
                properties,
                isNewEmbedded.value || isEmbedded.value
            );

            googleAnalytics.track(
                googleAnalytics.events.SURVEY_DISQUALIFIED,
                properties,
                isNewEmbedded.value || isEmbedded.value
            );

            facebookPixel.track(
                facebookPixel.events.SURVEY_DISQUALIFIED,
                properties
            );
        }

        return {
            getTranslations,
            title,
            isEmbedded,
            isNewEmbedded
        };
    },
    computed: {
        ...mapState([
            'title_font_name',
            'title_font_weight',
            'answer_font_name',
            'answer_font_weight',
            'primary_color',
            'text_color',
            'isFinished'
        ]),
        titleStyle() {
            return {
                'color': this.primary_color || undefined,
                'font-family': this.title_font_name || undefined,
                'font-weight': this.title_font_weight || undefined
            };
        },
        titleClass() {
            const { titleFontSizeClass } = useResponsiveFontSizeClass();
            return titleFontSizeClass.value;
        },
        descriptionStyle() {
            return {
                'color': this.text_color || undefined,
                'font-family': this.answer_font_name || undefined,
                'font-weight': this.answer_font_weight || undefined
            };
        },
        descriptionClass() {
            const { answerFontSizeClass } = useResponsiveFontSizeClass();
            return answerFontSizeClass.value;
        },
        description() {
            return this.getTranslations?.disqualification_pages?.[this.element.order -1]?.description || this.element.description;
        }
    },
};
</script>

<style scoped lang="less">
.mobile {
    .thank-you-wrapper {
        width: 100% !important;

        .sticky-header{
            position: sticky;
            top: 0;
            padding: 10px;
            z-index: 100000;

            /deep/ .logo-part-wrapper {
                text-align: center !important;
                display: block !important;
                justify-content: unset !important;

                .logo-wrapper {
                    width: 250px !important;
                    margin: 0 auto !important;
                    display: block !important;
                }
            }

            /deep/ .info {
                width: 90% !important;
            }
        }

        .pager {
            width: 100% !important;
        }
    }
}
div.thank-you-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    &.embedded {
        margin-top: 50px;
        min-height: unset;

        &.new-embedded {
            margin-top: 0;
            padding: 1em;
        }
    }
}
div.thank-you {
    text-align: center;

    img.finish-picture {
        display: inline;
        max-width: 400px;
    }
}
h1 {
    text-align: center;
    font-size: 32px;
}
section {
    text-align: center;
    max-width: 500px;
    margin: 0 auto;
}
img.finish-picture {
    max-height: none;
}
.thank-you {
    text-align: center;
}
@media (min-width: 450px) {
    div.thank-you {
        img.finish-picture {
            max-width: 80vw
        }
    }
}
</style>
