<template>
    <div
        v-if="languageUpdated"
        ref="wrapper"
        class="filler-wrapper mx-auto mobile:min-w-80 py-8 mobile:py-4"
        :class="{
            'embedded': isEmbedded,
            'new-embedded': isNewEmbedded,
            'mobile:mx-12': !isEmbedded,
            'mobile-xs:mx-10': is_question_ordinal_number_visible,
            'mobile-xs:mx-6': !is_question_ordinal_number_visible,
        }"
    >
        <v-style>
            .filler-wrapper a { color: {{ primary_color }}; }
            .filler-wrapper a:hover { text-decoration: underline; }
        </v-style>
        <language-selector
            v-if="showLanguageSelector"
        />
        <intro
            v-if="!isEmbedded"
            ref="intro"
            class="intro-preview"
            :class="{ [progressBarMargin.bottom]: !showProgressBarOnTop }"
            :title="surveyTitle"
            :description="surveyDescription"
            :title-font-name="title_font_name"
            :title-font-weight="title_font_weight"
            :answer-font-name="answer_font_name"
            :answer-font-weight="answer_font_weight"
            :primary-color="primary_color"
            :text-color="text_color"
            :is-filling-time-visible="is_filling_time_visible"
            :filling-time="filling_time"
            :elements="fillerElements"
            :logo-url="logo_file_url"
            :logo-settings="logo_settings"
            :is-first-page="currentPage === 1"
        />
        <progress-bar
            v-if="showProgressBarOnTop"
            :class="[progressBarMargin.top, progressBarMargin.bottom]"
            :primary-color="primary_color"
            :percentage="percentage"
        />
        <main
            :class="elementSpace"
            class="mb-4"
        >
            <div
                v-for="element in currentPageElements"
                :key="element.guid"
                class="space-y-4"
            >
                <survey-element
                    :ref="element.guid"
                    :element="element"
                    :visible-order="isEmbedded ? null : getVisibleOrder(element)"
                    :class="[isEmbedded ? 'embedded' : '']"
                    :mobile-view="mobileView"
                    :is-first-unanswered-element="firstVisibleNotAnsweredElementOfPage && firstVisibleNotAnsweredElementOfPage.guid === element.guid"
                />
                <div
                    v-if="isPasswordWarningVisible(element)"
                    :data-test-id="element.guid + '-warning'"
                    class="bg-red-100 rounded w-2/3 mx-auto"
                    :class="{ 'text-xs w-full': isEmbedded }"
                >
                    <div class="text-center p-2">
                        <span>
                            {{ $t('PHISHING.PASSWORD_CHECK_WARNING', 'Please note that Zurvey.io would never ask for your login information.') }}
                        </span>
                        <span>
                            {{ $t('PHISHING.LEARN_MORE', 'Learn more about online safety on this link') }}:
                            <a
                                href="https://edu.gcfglobal.org/en/internetsafety"
                                target="_blank"
                                class="text-black underline"
                            >
                                https://edu.gcfglobal.org/en/internetsafety/
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        </main>
        <progress-bar
            v-if="showProgressBarOnBottom"
            :class="[progressBarMargin.top, progressBarMargin.bottom]"
            :primary-color="primary_color"
            :percentage="percentage"
        />
        <div
            ref="pager"
            class="pager flex justify-center"
            data-iframe-height
            :class="[
                isEmbedded ? 'embedded' : '',
                iframePp ? 'iframe-pp' : '',
                { [pagerMargin]: !showProgressBarOnBottom }
            ]"
        >
            <z-button
                v-if="visitedPages.length > 0"
                type="secondary"
                :style="prevButtonStyle"
                data-test-id="prev"
                size="large"
                class="whitespace-no-wrap"
                @click="back"
            >
                {{ $t('BUTTONS.PREVIOUS_PAGE') }}
            </z-button>
            <z-button
                v-if="hasNextPage"
                :style="buttonStyle"
                data-test-id="next"
                size="large"
                class="whitespace-no-wrap"
                @click="forward"
            >
                {{ $t('BUTTONS.NEXT_PAGE') }}
            </z-button>
            <z-button
                v-else
                :style="buttonStyle"
                data-test-id="finish"
                size="large"
                class="whitespace-no-wrap"
                @click="finish"
            >
                {{ $t('BUTTONS.FINISH') }}
            </z-button>
        </div>
    </div>
</template>

<script>
import {computed, nextTick, onMounted, onUnmounted, ref} from 'vue';
import { useState } from 'vuex-composition-helpers';
import { mapGetters, mapState, mapMutations } from 'vuex';
import Intro from './Intro.vue';
import Element from '@/components/Element/Element.vue';
import typeEnum from '@/enums/elementTypeEnum';
import ZButton from '@/components/ui/Button.vue';
import VStyle from '@/components/ui/Style.vue';
import {isDark, hex2rgb} from '@/utils/color';
import { postMessageToParent } from '@/utils/iframe';
import { FillingProgressPosition } from '@/types/SettingEnums';
import ProgressBar from './ProgressBar.vue';
import LanguageSelector from './LanguageSelector.vue';

import { LayoutSpacing } from '@/types/SettingEnums';
import i18next from 'i18next';
import gtm from '@/utils/gtm';
import googleAnalytics from '@/utils/googleAnalytics';
import facebookPixel from '@/utils/facebookPixel';

export default {
    name: 'Filler',
    components: {
        ZButton,
        'intro': Intro,
        'survey-element': Element,
        VStyle,
        ProgressBar,
        LanguageSelector,
    },
    props: {
        urlParamsForTracking: { type: Object, default: () => ({}) }
    },
    setup() {
        const { layout_spacing } = useState(['layout_spacing']);

        const elementSpace = computed(() => {
            switch (layout_spacing.value) {
            case LayoutSpacing.COZY:
                return 'space-y-4 mobile:space-y-2';
            case LayoutSpacing.COMPACT:
                return 'space-y-8 mobile:space-y-4';
            default:
                return 'space-y-16 mobile:space-y-8';
            }
        });

        const progressBarMargin = computed(() => {
            switch (layout_spacing.value) {
            case LayoutSpacing.COZY:
                return { top: 'mt-2 mobile:mt-1', bottom: 'mb-2 mobile:mb-1' };
            case LayoutSpacing.COMPACT:
                return { top: 'mt-4 mobile:mt-2', bottom: 'mb-4 mobile:mb-2' };
            default:
                return { top: 'mt-8 mobile:mt-4', bottom: 'mb-8 mobile:mb-4' };
            }
        });

        const languageUpdated = ref(true);

        // This is needed for recompute computed properties in filler which contain translation
        const updateLanguage = async () => {
            languageUpdated.value = false;
            await nextTick();
            languageUpdated.value = true;
        };

        onMounted(() => {
            i18next.on('languageChanged', updateLanguage);
        });

        onUnmounted(() => {
            i18next.off('languageChanged', updateLanguage);
        });

        const pagerMargin = computed(() => {
            switch (layout_spacing.value) {
            case LayoutSpacing.COZY:
                return 'mt-4 mobile:mt-2';
            case LayoutSpacing.COMPACT:
                return 'mt-8 mobile:mt-4';
            default:
                return 'mt-16 mobile:mt-8';
            }
        });

        return { elementSpace, progressBarMargin, pagerMargin, languageUpdated };
    },
    data() {
        return {
            firstElementToShowOnPage: { order: 999 },
            visitedPages: [],
            visitedPagesShownQuestions: [],
            mobileView: false,
            timerId: null,
        };
    },
    computed: {
        ...mapState({
            currentPageMinOrder: state => state.query.o ? parseInt(state.query.o) : 0,
        }),
        ...mapState([
            'id',
            'isEmbedded',
            'isNewEmbedded',
            'iframePp',
            'logo_file_url',
            'logo_settings',
            'title',
            'description',
            'title_font_name',
            'title_font_weight',
            'answer_font_name',
            'answer_font_weight',
            'primary_color',
            'text_color',
            'background_color',
            'is_filling_time_visible',
            'answers',
            'filling_time',
            'bgImage',
            'is_filling_progress_visible',
            'filling_progress_position',
            'is_question_ordinal_number_visible',
            'query',
            'urlCode',
            'recordId',
            'usedUrlParams'
        ]),
        ...mapGetters([
            'editableElements',
            'fillerElements',
            'nextPageFirstVisibleElement',
            'hasNextPage',
            'shouldAutoForward',
            'currentPage',
            'getTranslations',
            'showLanguageSelector',
            'firstUnansweredQuestion',
            'resumableSurveyFillingParameterValue',
        ]),
        currentPageElements() {
            return this.fillerElements
                .map((element, index) => ({ ...element, questionNumber: index }))
                .filter(element => element.page === this.currentPage)
                .filter(element => element.order >= this.currentPageMinOrder);
        },
        buttonStyle() {
            let textColor = this.background_color;
            if (textColor === 'transparent') {
                textColor = isDark(hex2rgb(this.primary_color)) ? '#fff' : '#000';
            }
            return {
                'background-color': this.primary_color + ' !important',
                'border-color': this.primary_color + ' !important',
                'color': textColor + ' !important',
            };
        },
        prevButtonStyle() {
            return {
                'background-color': 'transparent' + ' !important',
                'color': this.primary_color + ' !important',
                'border-color': this.primary_color + ' !important',
            };
        },
        shownQuestions() {
            return this.visitedPagesShownQuestions
                .reduce((allShownQuestions, pageShownQuestions) => pageShownQuestions + allShownQuestions, 0);
        },
        percentage() {
            if (this.currentPageMinOrder === 0){
                return 0;
            } else {
                const totalElements = this.editableElements.length;
                const completedElements = this.editableElements.filter(e => e.order < this.currentPageMinOrder).length;
                return Math.round(completedElements * 100 / totalElements);
            }
        },
        firstVisibleNotAnsweredElementOfPage() {
            return this.currentPageElements.find(e =>
                (e.type !== typeEnum.NUMBER || e.type !== typeEnum.TEXT || e.type !== typeEnum.TEXTAREA) && !this.$store.state.answers[e.guid]);
        },
        surveyTitle() {
            return this.getTranslations?.title || this.title;
        },
        surveyDescription() {
            return this.getTranslations?.description || this.description;
        },
        showProgressBarOnTop() {
            return !this.isEmbedded && this.currentPage > 1 && this.is_filling_progress_visible && [FillingProgressPosition.TOP, FillingProgressPosition.BOTH].includes(this.filling_progress_position);
        },
        showProgressBarOnBottom() {
            return !this.isEmbedded && this.currentPage > 1 && this.is_filling_progress_visible && [FillingProgressPosition.BOTTOM, FillingProgressPosition.BOTH].includes(this.filling_progress_position);
        },

    },
    watch: {
        answers() {
            this.$store.dispatch('validateCurrentPage', this.currentPageElements);
        },
        async shouldAutoForward(val) {
            if (val && this.nextPageFirstVisibleElement !== 'thank-you') {
                Sentry.addBreadcrumb({ message: 'Autoforwarding' });
                this.timerId = setTimeout(() => {
                    this.forward();
                }, 300);
            }
        },
    },
    async created() {
        if (this.$store.state.isPreviewMode) {
            return; // In preview mode there's no no need to set default page on created, it breaks 'Step Back' command
        }
        const firstVisibleElement = this.fillerElements.sort((e1, e2) => e1.order - e2.order)[0];
        this.setQuery({
            p: firstVisibleElement ? firstVisibleElement.page : 1,
            o: 0
        });
        Sentry.addBreadcrumb({
            message: `Survey filling started with ${firstVisibleElement ? firstVisibleElement.guid : 'no-element'}`,
            level: 'info'
        });

        if (this.fillerElements.length > 0 && this.resumableSurveyFillingParameterValue) {
            const lastPage = Math.max(...this.fillerElements.map(el => el.page));

            while (this.query.p < (this.firstUnansweredQuestion?.page || lastPage) ) {
                this.forward();
            }
        }
    },
    mounted() {
        const mql = window.matchMedia('(max-width: 480px)');
        this.mobileView = mql.matches;
        if (mql && mql.addEventListener) {
            mql.addEventListener('change', (event) => {
                this.mobileView = event.matches;
            });
        } else {
            Sentry.captureException('No window matchMedia');
        }
    },
    destroyed() {
        this.setIsCurrentPageValidForAutoPagination(false);
        this.setWentBack(false);
    },
    methods: {
        ...mapMutations(['setQuery', 'setIsCurrentPageValidForAutoPagination', 'setWentBack', 'setLastVisitedPage']),
        postPageChange() {
            this.$emit('page-change');
            postMessageToParent('pageChanged');
        },
        forward() {
            if (this.timerId) {
                clearTimeout(this.timerId);
            }
            Sentry.addBreadcrumb({message: 'Forward button clicked', level: 'info'});
            if (this.getValidationErrors()) {
                return;
            }
            this.$store.commit('setIsCurrentPageValidForAutoPagination', false);
            this.$store.commit('setWentBack', false);
            if (this.nextPageFirstVisibleElement === 'thank-you') {
                Sentry.addBreadcrumb({message: 'Submit after next button clicked to thank-you', level: 'info'});
                this.$emit('finished');
                return;
            }
            if (this.nextPageFirstVisibleElement.includes && this.nextPageFirstVisibleElement.includes('disq-page-')) {
                Sentry.addBreadcrumb({message: 'Submit after next button clicked to disq page', level: 'info'});
                this.$emit('finished');
                return;
            }
            this.visitedPagesShownQuestions.push(
                this.currentPageElements.filter(element => element.type !== typeEnum.EXPLANATION).length
            );

            this.visitedPages.push({
                page: this.currentPage,
                order: this.currentPageMinOrder,
            });

            const prevPageNumber = this.currentPage;
            // SetQuery will update currentPage, so this should be stored before to send correct number to service
            this.setLastVisitedPage(this.currentPage);

            this.setQuery({
                p: this.nextPageFirstVisibleElement.page,
                o: this.nextPageFirstVisibleElement.order
            });
            Sentry.addBreadcrumb({
                message: 'next page',
                level: 'info',
                data: {
                    page: this.nextPageFirstVisibleElement.page,
                    order: this.nextPageFirstVisibleElement.order
                }
            });
            if (this.$refs.intro) {
                this.$refs.intro.$el.scrollIntoView(true);
            }
            this.postPageChange();
            try {
                this.$store.dispatch('saveAnswers');
            } catch (error) {
                Sentry.captureException(error);
            }

            this.addNextTracking(prevPageNumber);
        },
        async back() {
            if (this.timerId) {
                clearTimeout(this.timerId);
            }
            Sentry.addBreadcrumb({message: 'Back button clicked', level: 'info'});

            const prevPageNumber = this.currentPage;
            // SetQuery will update currentPage, so this should be stored before to send correct number to service
            this.setLastVisitedPage(this.currentPage);

            await this.$store.commit('setWentBack', true);
            const prevPage = this.visitedPages.pop();
            this.visitedPagesShownQuestions.pop();
            this.setQuery({
                p: prevPage.page,
                o: prevPage.order
            });
            Sentry.addBreadcrumb({
                message: 'previous page',
                level: 'info',
                data: {
                    page: prevPage.page,
                    order: prevPage.order
                }
            });
            this.postPageChange();
            this.$store.dispatch('saveAnswers');

            this.addPrevTracking(prevPageNumber);

            await nextTick();
            this.$refs.pager.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
        },
        finish() {
            if (!this.getValidationErrors()) {
                Sentry.addBreadcrumb({message: 'Submit after submit button clicked', level: 'info'});
                this.$emit('finished');
            }
        },
        getValidationErrors() {
            this.setAnswerForAllElements();
            this.setEmptyOthersToNull();
            this.$store.commit('validate');
            const firstValidationError = this.currentPageElements.find(element => element.validation_error_message);
            if (firstValidationError) {
                this.$refs[firstValidationError.guid]?.[0].$el.scrollIntoView(true);
            }
            return !!firstValidationError;
        },
        setAnswerForAllElements() {
            this.currentPageElements
                .filter(element => element.type !== typeEnum.EXPLANATION )
                .forEach(element => {
                    const currentAnswer = this.$store.state.answers[element.guid];
                    if (currentAnswer === undefined) {
                        this.$store.commit('setAnswer', { guid: element.guid, answer: null });
                    }
                });
        },
        setEmptyOthersToNull() {
            this.currentPageElements
                .filter(element =>
                    this.$store.state.answers[element.guid] !== undefined &&
                    typeof(this.$store.state.answers[element.guid] ? this.$store.state.answers[element.guid].other : null) === typeof('string') &&
                    this.$store.state.answers[element.guid].other.trim() === ''
                ).forEach(element => {
                    const currentAnswer = this.$store.state.answers[element.guid];
                    this.$store.commit('setAnswer', { guid: element.guid, answer: { ...currentAnswer, other: null }});
                });
        },
        getVisibleOrder(element) {
            if (element.type === typeEnum.EXPLANATION) {
                return null;
            }
            const numberOfExplanationElementsOnThisPageBefore = this.currentPageElements
                .filter(e => e.type === typeEnum.EXPLANATION)
                .filter(e => e.order < element.order)
                .length;
            const elementPageOrder = this.currentPageElements.filter(e => e.order < element.order).length;
            return this.shownQuestions + elementPageOrder - numberOfExplanationElementsOnThisPageBefore + 1;
        },
        isPasswordWarningVisible(element) {
            const passwordText = this.$t('PHISHING.PASSWORD', 'Password');
            return (
                element.title?.toLowerCase().includes(passwordText.toLowerCase()) ||
                element.title?.toLowerCase().includes(passwordText.substring(0, 5).toLowerCase()) ||
                element.help?.toLowerCase().includes(passwordText.toLowerCase()) ||
                element.help?.toLowerCase().includes(passwordText.substring(0, 5).toLowerCase())) &&
                (element.type === typeEnum.TEXT || element.type === typeEnum.TEXTAREA);
        },

        addPrevTracking(prevPageNumber) {
            const properties = {
                recordId: this.recordId,
                surveyUniqueName: this.urlCode,
                fromPage: prevPageNumber,
                toPage: this.currentPage,
                ...this.urlParamsForTracking
            };

            gtm.track(
                gtm.events.SURVEY_PREV,
                properties,
                this.isNewEmbedded || this.isEmbedded
            );

            googleAnalytics.track(
                googleAnalytics.events.SURVEY_PREV,
                properties,
                this.isNewEmbedded || this.isEmbedded
            );

            facebookPixel.track(
                facebookPixel.events.SURVEY_PREV,
                properties
            );
        },

        addNextTracking(prevPageNumber) {
            const properties = {
                recordId: this.recordId,
                surveyUniqueName: this.urlCode,
                fromPage: prevPageNumber,
                toPage: this.currentPage,
                ...this.urlParamsForTracking
            };

            gtm.track(
                gtm.events.SURVEY_NEXT,
                properties,
                this.isNewEmbedded || this.isEmbedded
            );

            googleAnalytics.track(
                googleAnalytics.events.SURVEY_NEXT,
                properties,
                this.isNewEmbedded || this.isEmbedded
            );

            facebookPixel.track(
                facebookPixel.events.SURVEY_NEXT,
                properties
            );
        }
    },

};
</script>

<style scoped lang="less">
.filler-wrapper {
    max-width: 960px;

    &.embedded {
        padding: 0;
        margin-top: 50px;
        min-height: unset;

        &.new-embedded {
            margin-top: 0;
            padding: 1em;
        }
    }

    .pager {
        span {
            margin: 0 10px;
        }
    }
}

@media screen and (max-width: 1055px) {
    .filler-wrapper {
        /deep/ .matrix-table {
            display: none !important;
        }

        /deep/ .matrix-mobile {
            display: block !important;
        }

        .pager {
            width: 100% !important;
        }
    }
}
</style>
