<template>
    <div
        class="mb-3 space-y-2 mobile:block"
        :class="{
            'inline-block': isVertical,
            'w-64': isVertical
        }"
    >
        <div
            class="flex mobile:space-y-4 mobile:space-x-0"
            :class="{
                'flex-col space-y-4 space-x-0': isVertical,
                'space-x-4': !isVertical,
                'mobile:flex-col': !element.is_reversed,
                'mobile:flex-col-reverse mobile:space-y-reverse': element.is_reversed,
                'flex-row-reverse space-x-reverse' : !isVertical && element.is_reversed,
                'flex-col-reverse space-y-reverse' : isVertical && element.is_reversed,
            }"
        >
            <answer-box
                v-for="(range, index) in ranges"
                :key="index"
                class="p-2 text-center cursor-pointer w-full flex justify-center items-center"
                :selected="index === selectedAnswer"
                @click="handleChange(range.weight, index)"
            >
                <span
                    :style="labelStyle"
                    :class="answerFontSizeClass"
                >
                    {{ range.label }}
                </span>
            </answer-box>
        </div>
        <div class="max-w-2xl">
            <answer-box
                v-if="has_dkna_option"
                :selected="selectedAnswer === 'dkna'"
                :class="{
                    'inline-block mobile:block': !isVertical
                }"
            >
                <vm-radio
                    :checked="selectedAnswer === 'dkna' && dkna_option_value"
                    :value="dkna_option_value"
                    :primary-color="primary_color"
                    class="w-full p-1.5"
                    @change="handleChange($event, 'dkna')"
                >
                    <span
                        :style="labelStyle"
                        :class="answerFontSizeClass"
                    >
                        {{ dknaOptionLabel }}
                    </span>
                </vm-radio>
            </answer-box>
        </div>
    </div>
</template>

<script lang="ts">
import {computed, defineComponent, ref} from 'vue';
import {useState, useActions} from 'vuex-composition-helpers/dist';
import VmRadio from '@/components/ui/VModelRadio.vue';
import AnswerBox from '../ui/AnswerBox.vue';
import useStyle, { useResponsiveFontSizeClass } from '@/components/typeSpecificElements/hooks/useStyle';
import { useGetters } from 'vuex-composition-helpers';

export default defineComponent({
    components: {
        VmRadio,
        AnswerBox,
    },
    props: {
        element: { type: Object, default: () => ({})},
    },
    setup(props) {
        const { primary_color, answers, selectedAnswers } = useState(['primary_color', 'answers', 'selectedAnswers']);
        const { setAnswer, setSelectedAnswer } = useActions(['setAnswer', 'setSelectedAnswer']);
        const { labelStyle } = useStyle();
        const { answerFontSizeClass } = useResponsiveFontSizeClass();

        const hoveredBox = ref(null);

        const isVertical = computed(() => props.element.display_direction === 'vertical');

        const value = computed({
            get() {
                if (answers.value[props.element.guid] !== undefined && answers.value[props.element.guid] !== null) {
                    return answers.value[props.element.guid];
                }
                return null;
            },
            set(newValue){
                setAnswer({ guid: props.element.guid, answer: Number(newValue) });
            }
        });

        const selectedAnswer = computed({
            get() {
                return selectedAnswers.value[props.element.guid];
            },
            set(newValue) {
                setSelectedAnswer({ guid: props.element.guid, index: newValue });
            }
        });

        function handleChange(answer, index) {
            value.value = answer;
            selectedAnswer.value = index;
        }

        const getDisplayedLabel = (index: number): string => {
            return getTranslations.value?.questions?.find(q => q.guid === props.element.guid)?.ranges?.[index].range;
        };

        const { getTranslations } = useGetters(['getTranslations']);

        const ranges = computed(() => {
            return props.element.ranges.map((range, index) => {
                const label = getDisplayedLabel(index) || range.range || range.weight;

                return {
                    label: props.element.display_type === 'number' ? range.weight : label,
                    weight: range.weight,
                };
            });
        });

        const dknaOptionLabel = computed(() => {
            return getTranslations.value?.questions?.find(q => q.guid === props.element.guid)?.dkna_option_label || props.element.dkna_option_label;
        });

        const { has_dkna_option, dkna_option_label, dkna_option_value } = props.element;

        return {
            value, selectedAnswer, handleChange,
            hoveredBox,
            isVertical,
            has_dkna_option,
            dkna_option_label,
            dkna_option_value,
            primary_color,
            labelStyle,
            ranges,
            dknaOptionLabel,
            answerFontSizeClass,
        };
    }
});
</script>
