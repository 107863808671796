export const multiLangState = {
    isMultilang: false,
    translations: [],
    benchmarkLanguage: null,
    surveyLanguage: null,
    previewLang: false,
};

export const multiLangGetters = {
    multilangTranslations(state) {
        return state.isMultilang ? state.translations : [];
    },
    showLanguageSelector(state) {
        return state.isMultilang && state.translations?.activeLanguages?.length > 0 && !state.isEmbedded;
    },
    getTranslations(state) {
        if (state.previewLang) {
            return state.translations?.draftTranslations?.[state.surveyLanguage] || {};
        }
        return state.translations?.liveTranslations?.[state.surveyLanguage] || {};
    },
    activeLanguages(state) {
        return state.translations?.activeLanguages || [];
    },
};

export const multiLangMutations = {
    setSurveyLanguage(state, language: string) {
        state.surveyLanguage = language;
    },
    setBenchmarkLanguage(state, language: string) {
        state.benchmarkLanguage = language;
    },
    setIsMultilang(state, isMultilang: boolean) {
        state.isMultilang = isMultilang;
    },
    setTranslations(state, translations: any[]) {
        state.translations = translations;
    },
    setPreviewLang(state) {
        state.previewLang = true;
    }
};
