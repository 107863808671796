<template>
    <div class="group relative inline-block">
        <slot />
        <div
            v-if="tooltip"
            class="z-tooltip absolute hidden group-hover:block w-max max-w-128 z-10 left-1/2 transform -translate-x-1/2 top-0"
        >
            <div
                class="inner bg-white shadow rounded p-2 text-default"
                v-html="tooltip"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        tooltip: { type: String, required: false, default: '' }
    },
});
</script>

<style scoped>

.z-tooltip {
    --arrow-size: 5px;
    --transform-translate-y: calc(-100% - 2 * var(--arrow-size)) !important;
}
.z-tooltip:after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border: solid var(--arrow-size) transparent;
    border-top-color: white;
}
</style>
