import api from '@/api';
import i18next from 'i18next';
import typeEnum from '@/enums/elementTypeEnum';
export default {
    getters: {
        resumableSurveyFillingParameterValue(_state, _getters, rootState) {
            const param = rootState.usedUrlParams.find(p => p.guid === rootState.resumableSurveyFillingGuid);

            if (!param) return null;

            return param.value;
        },
        firstUnansweredQuestion(_state, _getters, rootState, rootGetters) {
            return rootGetters.fillerElements.find(question => question.type !== typeEnum.EXPLANATION && !rootState.answers[question.guid]) || null;
        }
    },
    actions: {
        async resumeSurvey({ rootState, commit, getters }, campaignUrlParams) {
            Sentry.addBreadcrumb({
                message: 'data for loading answers for resumable survey',
                data: {
                    datasetId: rootState.datasetId,
                    identifier: getters.resumableSurveyFillingParameterValue,
                }
            });
            const response = await api.loadAnswers(rootState.datasetId, getters.resumableSurveyFillingParameterValue, campaignUrlParams);
            if (response.completed) {
                commit('setShowPage', rootState.singleResponseLimitTarget ?? 'thank-you');
                commit('setHasBeenFilled');
                return;
            }

            commit('setSurvey', response);

            if (response.language) {
                i18next.changeLanguage(response.language.toLowerCase());
            }
        },
    },
};
