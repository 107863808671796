export enum FillingProgressPosition {
    TOP = 'top',
    BOTTOM = 'bottom',
    BOTH = 'both'
}

export enum LayoutSpacing {
    SPACIOUS = 'spacious',
    COMPACT = 'compact',
    COZY = 'cozy'
}

export enum QuestionTagType {
    NONE = 'none',
    REQUIRED = 'required',
    OPTIONAL = 'optional'
}
