<template>
    <div class="flex items-center">
        <z-input
            ref="number"
            :value="answer"
            class="w-full"
            data-test-id="number-preview-input"
            :text-color="text_color"
            :primary-color="primary_color"
            background-color="transparent"
            @input="handleInput"
            @blur="onBlur"
        />
        <span
            v-if="suffix"
            data-test-id="number-preview-suffix"
            class="ml-2"
            :style="suffixStyle"
        >
            {{ suffix }}
        </span>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted } from 'vue';
import { useActions, useGetters, useState } from 'vuex-composition-helpers';
import ZInput from '@/components/ui/Input.vue';

export default defineComponent({
    components: {
        ZInput,
    },
    props: {
        element: { type: Object, default: () => ({})},
        isFirstUnansweredElement: { type: Boolean, default: false},
    },
    setup(props) {
        const number = ref<HTMLInputElement>();
        const answer = ref<string|undefined|null>(null);
        const { primary_color, background_color, text_color, answers } = useState(['primary_color', 'background_color', 'text_color', 'answers']);
        const { setAnswer } = useActions(['setAnswer']);

        function addSeparators(number: string): string {
            const numberArray = number.split('');
            for (let i = numberArray.length - 4 ; i >= 0; i -= 3) {
                numberArray[i] = numberArray[i] + ' ';
            }

            return numberArray.join('');
        }

        function handleInput(value: string) {
            answer.value = undefined;

            const cleanedInput = value.replace(/([^0-9,.-])/g, '').replaceAll(',', '.');

            if (!cleanedInput.includes('.')) {
                answer.value = addSeparators( cleanedInput );
                return;
            }

            const first = addSeparators( cleanedInput.split('.')[0] );
            const second = cleanedInput.split('.').slice(1).join('');

            answer.value = first + '.' + second;
        }

        const suffixStyle = computed(() => ({
            'color': text_color.value,
        }));

        onMounted(() => {
            if (props.isFirstUnansweredElement) {
                number.value?.focus?.();
            }
            answer.value = answers.value[props.element.guid] || '';
        });

        function onBlur() {
            if (!answer.value?.replace) return;

            const newValue = parseFloat(answer.value.replace(/\s/g, ''));
            setAnswer({ guid: props.element.guid, answer: newValue });
        }

        const { getTranslations } = useGetters(['getTranslations']);

        const suffix = computed(() => {
            return getTranslations.value?.questions?.find(q => q.guid === props.element.guid)?.suffix || props.element.suffix;
        });

        return { number, answer, handleInput, suffixStyle, onBlur, primary_color, background_color, text_color, suffix };
    },
});
</script>
