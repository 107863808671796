<template>
    <div
        ref="table"
        class="matrix-wrapper"
        style="display: block;"
    >
        <table
            class="matrix-table"
            :class="{ 'force-mobile-view': hasOverflow }"
            :data-test-id="'matrix-table-' + element.order"
        >
            <thead
                :class="{
                    'sticky top-0 z-50': germanMatrix
                }"
                :style="bgStyle"
            >
                <tr>
                    <th><!--the left top cell is empty--></th>
                    <th
                        v-for="(range, index) in ranges"
                        :key="index"
                        check-width
                        class="font-normal"
                    >
                        <label
                            :style="labelStyle"
                            :class="answerFontSizeClass"
                            v-html="range.label"
                        />
                    </th>
                    <th
                        v-if="element.has_dkna_option"
                        check-width
                        data-test="dkna-range"
                        class="font-normal"
                    >
                        <label
                            :style="labelStyle"
                            :class="answerFontSizeClass"
                        >
                            {{ dknaOptionLabel }}
                        </label>
                    </th>
                </tr>
            </thead>
            <tbody v-if="showWithoutParent === true && element.options_parent_question_guid !== null">
                <td check-width>
                    <label class="hyphenate-with-prev-answer text-neutral-800">
                        {{ $t('PRINT.PREVIOUS_OPTIONS') }}
                    </label>
                </td>
                <td
                    v-for="(range, subIndex) in ranges"
                    :key="subIndex"
                >
                    <z-checkbox
                        v-if="element.is_multiple_enabled"
                        :id="element.guid + subIndex"
                        :value="range"
                        :checked="false"
                        :primary-color="boxColor"
                    />
                    <z-radio
                        v-else
                        :id="element.guid + subIndex"
                        :value="subIndex"
                        :primary-color="boxColor"
                    />
                </td>
                <td v-if="element.has_dkna_option">
                    <z-checkbox
                        v-if="element.is_multiple_enabled"
                        :id="element.guid + 'dkna'"
                        :value="true"
                        :checked="false"
                        :primary-color="boxColor"
                    />
                    <z-radio
                        v-else
                        :id="element.guid + 'dkna'"
                        :value="true"
                        :primary-color="boxColor"
                    />
                </td>
            </tbody>
            <tbody v-else>
                <tr
                    v-for="option in visibleOptions"
                    :key="option.index"
                    :data-test-id="'non-random-index' + option.index"
                >
                    <td check-width>
                        <label
                            :style="labelStyle"
                            :class="answerFontSizeClass"
                            class="hyphenate"
                            v-html="option.optionText"
                        />
                    </td>
                    <td
                        v-for="(range, subIndex) in ranges"
                        :key="subIndex"
                        data-test="controller"
                    >
                        <z-checkbox
                            v-if="element.is_multiple_enabled"
                            :id="element.guid + option.index + subIndex"
                            :checked="values[option.value]"
                            :value="range.value"
                            :primary-color="boxColor"
                            @change="updateValues(option.value, $event)"
                        />
                        <z-radio
                            v-else
                            :id="element.guid + option.index + subIndex"
                            :checked="values[option.value]"
                            :value="range.value"
                            :primary-color="boxColor"
                            @change="updateValues(option.value, $event)"
                        />
                    </td>
                    <td v-if="element.has_dkna_option">
                        <z-checkbox
                            v-if="element.is_multiple_enabled"
                            :id="element.guid + option.index + 'dkna'"
                            :checked="dkna[option.value]"
                            :value="true"
                            :primary-color="boxColor"
                            @change="updateDkna(option.value, $event)"
                        />
                        <z-radio
                            v-else
                            :id="element.guid + option.index + 'dkna'"
                            :checked="dkna[option.value]"
                            :value="true"
                            :primary-color="boxColor"
                            @change="updateDkna(option.value, $event)"
                        />
                    </td>
                </tr>
            </tbody>
        </table>
        <div
            v-if="!showWithoutParent"
            class="matrix-mobile"
            :class="{ 'force-mobile-view': hasOverflow }"
        >
            <div
                v-for="option in visibleOptions"
                :key="option.value"
                class="mt-4 space-y-2"
            >
                <label
                    class="option"
                    :style="labelStyle"
                    :class="answerFontSizeClass"
                    v-html="option.optionText"
                />
                <div
                    class="options leading-none"
                    :class="[answerFontSizeClass, optionsSpace]"
                >
                    <answer-box
                        v-for="(range, subIndex) in ranges"
                        :key="subIndex"
                        class="range"
                    >
                        <z-checkbox
                            v-if="element.is_multiple_enabled"
                            :id="element.guid + option.index + subIndex"
                            :checked="values[option.value]"
                            :value="range.value"
                            :primary-color="boxColor"
                            class="w-full"
                            :class="[paddingSize]"
                            @change="updateValues(option.value, $event)"
                        >
                            <span
                                :style="labelStyle"
                                v-html="range.label"
                            />
                        </z-checkbox>
                        <z-radio
                            v-else
                            :id="element.guid + option.index + subIndex"
                            :checked="values[option.value]"
                            :value="range.value"
                            :primary-color="boxColor"
                            class="w-full"
                            :class="[paddingSize]"
                            @change="updateValues(option.value, $event)"
                        >
                            <span
                                :style="labelStyle"
                                v-html="range.label"
                            />
                        </z-radio>
                    </answer-box>
                    <answer-box
                        v-if="element.has_dkna_option"
                        class="range"
                    >
                        <z-checkbox
                            v-if="element.is_multiple_enabled"
                            :id="element.guid + 'dkna' + option.index"
                            :checked="dkna[option.value]"
                            :value="true"
                            :primary-color="boxColor"
                            class="w-full"
                            :class="[paddingSize]"
                            @change="updateDkna(option.value, $event)"
                        >
                            <span
                                :style="labelStyle"
                            >
                                {{ dknaOptionLabel }}
                            </span>
                        </z-checkbox>
                        <z-radio
                            v-else
                            :id="element.guid + 'dkna' + option.index"
                            :checked="dkna[option.value]"
                            :value="true"
                            :primary-color="boxColor"
                            class="w-full"
                            :class="[paddingSize]"
                            @change="updateDkna(option.value, $event)"
                        >
                            <span
                                :style="labelStyle"
                            >
                                {{ dknaOptionLabel }}
                            </span>
                        </z-radio>
                    </answer-box>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, onMounted, onUnmounted, nextTick } from 'vue';
import { useState, useActions, useGetters } from 'vuex-composition-helpers';
import ZRadio from '@/components/ui/VModelRadio.vue';
import ZCheckbox from '@/components/ui/VModelCheckbox.vue';

import useOptions from './hooks/useOptions';
import useStyle, { useResponsiveFontSizeClass, useSpacingClass } from './hooks/useStyle';
import AnswerBox from '../ui/AnswerBox.vue';

export default defineComponent({
    components: {
        ZRadio,
        ZCheckbox,
        AnswerBox,
    },
    props: {
        element: { type: Object, default: () => ({})},
        showWithoutParent: { type: Boolean, default: false },
    },
    setup(props) {
        const { primary_color, answers, background_color, hasNewDictionary } = useState(['primary_color', 'answers', 'background_color', 'hasNewDictionary']);
        const { setAnswer } = useActions(['setAnswer']);

        const { labelStyleWithoutFontWeight: labelStyle } = useStyle();
        const { answerFontSizeClass } = useResponsiveFontSizeClass();

        const boxColor = computed(() => primary_color.value);

        const visibleOptions = useOptions(props.element);

        const answer = computed(() => {
            if (answers.value[props.element.guid]) {
                return answers.value[props.element.guid];
            }

            if (hasNewDictionary.value) {
                return props.element.options.reduce((a, o) => {
                    const value = !props.element.is_multiple_enabled ? null : props.element.ranges.reduce((v, r) => ({ ...v, [r.id]: 0 }), {});

                    return {
                        ...a,
                        [o.id]: {
                            value,
                            other: null,
                            dkna: false,
                        }
                    };
                }, {});
            }

            return props.element.options.map(() => {
                const value = !props.element.is_multiple_enabled ? null : props.element.ranges.map(() => 0);

                return {
                    value,
                    other: null,
                    dkna: false,
                };
            });
        });

        const values = computed(() => {
            if (hasNewDictionary.value) {
                if (props.element.is_multiple_enabled) {
                    return Object.keys(answer.value).reduce((a, o) => {
                        return {
                            ...a,
                            [o]: Object.keys(answer.value[o].value).reduce((v, r) => {
                                if (parseInt(answer.value[o].value[r])) {
                                    v.push(r);
                                }
                                return v;
                            }, [] as string[])
                        };
                    }, {});
                }

                return Object.keys(answer.value).reduce((v, o) => {
                    return {
                        ...v,
                        [o]: answer.value[o].value
                    };
                }, {});
            }

            if (props.element.is_multiple_enabled) {
                return answer.value.map(o => o.value.reduce((a, r, i) => {
                    if (r) {
                        a.push(i);
                    }
                    return a;
                }, []));
            }

            return answer.value.map(o => o.value);
        });

        const updateValues = (key, val) => { // key is the index or id of the option
            if (hasNewDictionary.value) {
                if (props.element.is_multiple_enabled) {
                    const value = {
                        ...answer.value,
                        [key]: {
                            value: props.element.ranges.reduce((v, r) => {
                                return {
                                    ...v,
                                    [r.id]: val.includes(r.id) ? 1 : 0
                                };
                            }, {}),
                            other: null,
                            dkna: false
                        }
                    };
                    setAnswer({ guid: props.element.guid, answer: value });
                }
                else {
                    const value = {
                        ...answer.value,
                        [key]: {
                            value: val,
                            other: null,
                            dkna: false
                        }
                    };
                    setAnswer({ guid: props.element.guid, answer: value });
                }
            }
            else {
                if (props.element.is_multiple_enabled) {
                    const value = answer.value.with(key, {
                        value: props.element.ranges.map((_, i) => val.includes(i) ? 1 : 0),
                        other: null,
                        dkna: false,
                    });

                    setAnswer({ guid: props.element.guid, answer: value });
                }
                else {
                    const value = answer.value.with(key, {
                        value: val,
                        other: null,
                        dkna: false,
                    });

                    setAnswer({ guid: props.element.guid, answer: value });
                }
            }
        };

        const dkna = computed(() => {
            if (hasNewDictionary.value) {
                return Object.keys(answer.value).reduce((v, o) => {
                    return {
                        ...v,
                        [o]: answer.value[o].dkna
                    };
                }, {});
            }

            return answer.value.map(o => o.dkna);
        });

        const updateDkna = (key, val) => { // key is the index or id of the option
            if (hasNewDictionary.value) {
                const value = {
                    ...answer.value,
                    [key]: {
                        value: !props.element.is_multiple_enabled ? null : props.element.ranges.reduce((v, r) => ({ ...v, [r.id]: 0 }), {}),
                        other: null,
                        dkna: val
                    },
                };
                setAnswer({ guid: props.element.guid, answer: value });
            }
            else {
                const value = answer.value.with(key, {
                    value: !props.element.is_multiple_enabled ? null : props.element.ranges.map(() => 0),
                    other: null,
                    dkna: true
                });
                setAnswer({ guid: props.element.guid, answer: value });
            }
        };

        const table = ref<HTMLTableElement>();
        const germanMatrix = ref(false);

        function checkIfGermanMatrix() {
            const view = document.querySelector('.simplebar-content-wrapper') as HTMLDivElement;

            if (table.value && view) {
                germanMatrix.value = table.value.offsetHeight > view.offsetHeight;
            }
        }

        const hasOverflow = ref(false);

        async function checkOverflow() {
            hasOverflow.value = false;
            await nextTick();
            const elements: Array<HTMLTableCellElement> = Array.from(document.querySelectorAll(`[data-test-id=matrix-table-${props.element.order}] [check-width]`));

            for (const element of elements) {
                if (element.offsetWidth < element.querySelector('label')!.offsetWidth) {
                    hasOverflow.value = true;
                    return;
                }
            }
        }

        onMounted(() => {
            checkIfGermanMatrix();
            window.addEventListener('resize', checkIfGermanMatrix); // going from mobile view to desktop view we need to recheck

            checkOverflow();
            window.addEventListener('resize', checkOverflow); // going from mobile view to desktop view we need to recheck
        });

        onUnmounted(() => {
            window.removeEventListener('resize', checkIfGermanMatrix);
            window.removeEventListener('resize', checkOverflow);
        });

        const { getTranslations } = useGetters(['getTranslations']);

        const ranges = computed(() => {
            return props.element.ranges.map((range, index) => {
                const label = getTranslations.value?.questions?.find(q => q.guid === props.element.guid)?.ranges?.[index]?.range || range.range;

                return {
                    label,
                    value: hasNewDictionary.value ? range.id : index,
                };
            });
        });

        const dknaOptionLabel = computed(() => {
            return getTranslations.value?.questions?.find(q => q.guid === props.element.guid)?.dkna_option_label || props.element.dkna_option_label;
        });

        const bgStyle = computed(() => {
            return {
                'background-color': germanMatrix.value ? background_color.value : 'transparent'
            };
        });

        const { paddingSize, optionsSpace } = useSpacingClass();

        return {
            answer,
            values, dkna, updateValues, updateDkna,
            labelStyle, boxColor, visibleOptions, answerFontSizeClass,
            background_color,
            table, germanMatrix,
            ranges,
            dknaOptionLabel,
            bgStyle,
            hasOverflow,
            paddingSize, optionsSpace,
        };
    }
});
</script>

<style scoped lang="less">
    .matrix-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        label {
            margin: 0;
        }
    }
    .matrix-table{
        width: 100%;
        table-layout: fixed;

        td, th {
            text-align: center;
            padding: 10px 5px;
        }

        td:first-child {
            text-align: left;
        }
    }
    .matrix-mobile {
        display: none;

        .option {
            padding: 20px 0 10px 0;
        }

        .range {
            .checkbox_input {
                margin-right: 8px;
            }
        }
    }
    @media (min-width: 1056px) {
        .matrix-mobile.force-mobile-view {
            display: block !important;

            label {
                display: block;
            }
            .options {
                display: inline-block;
                min-width: 280px;
            }

        }
        .matrix-table.force-mobile-view {
            display: none !important;
        }
    }
    .hyphenate-with-prev-answer{
        font-style: italic;
    }

.checkbox_control {
    display: inline-block;
    width: 1em;
    height: 1em;

    svg path {
        opacity: 0;
        transition: transform 0.1s ease-in 25ms;
        transform: scale(0);
        transform-origin: center;
    }
}

.checkbox_input {
    grid-template-areas: "checkbox";
    margin-left: -16px;
    margin-right: 4px;

    > * {
        grid-area: checkbox;
    }

    input {
        opacity: 0;
        width: 1em;
        height: 1em;

        &:checked + .checkbox_control svg path {
            opacity: 1;
            transform: scale(1);
        }

        &:disabled + .checkbox_control {
            color: var(--disabled);
        }
    }
}
</style>
