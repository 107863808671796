<template>
    <div>
        <div
            v-if="isSlider"
            class="slider-wrapper pt-5"
        >
            <z-slider
                v-model="value"
                :min="ranges[0].weight"
                :max="ranges[1].weight"
                :primary-color="primary_color"
                :background-color="background_color"
                :text-color="text_color"
                :gradient-colors="element.colors"
                :is-reversed="element.is_reversed"
                :is-decimal="element.is_decimal_scale"
                :starting-point="element.start_point ? parseFloat(element.start_point) : undefined"
                :decimal-precision="element.decimal_precision"
                :slider-has-default-value="element.slider_has_default_value"
                :hide-value="element.hide_value"
                :is-nps="element.type === elementTypeEnum.NPS"
            />
        </div>
        <button-scale
            v-else
            :options="options"
            :selected="value"
            :primary-color="primary_color"
            :background-color="background_color"
            :text-color="text_color"
            :is-reversed="element.is_reversed"
            :colors="element.colors"
            @input="value = $event"
        />
        <div
            v-if="element.label_type !== 'none'"
            class="w-full flex mt-2 justify-between text-default"
            :class="element.is_reversed ? 'flex-row-reverse' : ''"
        >
            <div
                class="w-1/2"
                :style="labelStyle"
                :class="element.is_reversed ? 'text-right' : ''"
            >
                <icon
                    v-if="element.range_start_icon"
                    class="text-2xl mobile:text-xl"
                    :icon="[startIconStyle, startIcon]"
                />
                <div v-else>
                    {{ ranges[0].range }}
                </div>
            </div>
            <div
                class="w-1/2"
                :class="element.is_reversed ? 'text-left' : 'text-right'"
                :style="labelStyle"
            >
                <icon
                    v-if="element.range_end_icon"
                    class="text-2xl mobile:text-xl"
                    :icon="[endIconStyle, endIcon]"
                />
                <div v-else>
                    {{ ranges[1].range }}
                </div>
            </div>
        </div>
        <div>
            <answer-box
                v-if="has_dkna_option"
                :selected="value === dkna_option_value"
                class="mt-2 w-64 mobile:w-full block"
            >
                <vm-radio
                    v-model="value"
                    :value="dkna_option_value"
                    :primary-color="primary_color"
                    class="w-full p-1.5"
                >
                    <span :style="labelStyle">
                        {{ dknaOptionLabel }}
                    </span>
                </vm-radio>
            </answer-box>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import range from 'lodash/range';
import {computed, onMounted} from 'vue';
import {useActions, useState} from 'vuex-composition-helpers/dist';
import ButtonScale from './ButtonScale.vue';
import ZSlider from '@/components/ui/Slider.vue';
import useStyle from './hooks/useStyle';
import VmRadio from '@/components/ui/VModelRadio.vue';
import AnswerBox from '../ui/AnswerBox.vue';
import { useGetters } from 'vuex-composition-helpers';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import elementTypeEnum from '@/enums/elementTypeEnum';

export default defineComponent({
    components: {
        ButtonScale,
        ZSlider,
        VmRadio,
        AnswerBox,
        'icon': FontAwesomeIcon
    },
    props: {
        element: { type: Object, default: () => ({})},
    },
    setup(props) {
        const { primary_color, background_color, text_color, answers } = useState(['primary_color', 'background_color', 'text_color', 'answers']);
        const { setAnswer } = useActions(['setAnswer']);
        const { labelStyle } = useStyle();

        const endIcon = computed(() => props.element.range_end_icon ? props.element.range_end_icon.split('_')[0] : null);

        const startIcon = computed(() => props.element.range_start_icon ? props.element.range_start_icon.split('_')[0] : null);

        const startIconType = computed(() => props.element.range_start_icon ? props.element.range_start_icon.split('_')[1] : null);

        const endIconType = computed(() => props.element.range_end_icon ? props.element.range_end_icon.split('_')[1] : null);

        const startIconStyle = computed(() => startIconType.value === 'solid' ? 'fas' : (startIconType.value === 'light' ? 'fal' : 'far'));

        const endIconStyle = computed(() => endIconType.value === 'solid' ? 'fas' : (endIconType.value === 'light' ? 'fal' : 'far'));

        const isSlider = computed(() => ['slider', 'color-slider'].includes(props.element.display_type));

        const value = computed({
            get() {
                if (answers.value[props.element.guid] !== undefined && answers.value[props.element.guid] !== null) {
                    return answers.value[props.element.guid];
                }
                return isSlider.value && props.element.slider_has_default_value ? props.element.start_point : null;
            },
            set(newValue){
                setAnswer({ guid: props.element.guid, answer: newValue !== null ? Number(newValue) : newValue });
            }
        });
        const options = computed(() => range(parseInt(props.element.ranges[0].weight), parseInt(props.element.ranges[1].weight) + 1));

        onMounted(() => {
            if (isSlider.value && answers.value[props.element.guid] === undefined) {
                value.value = props.element.start_point && props.element.slider_has_default_value ? props.element.start_point : (props.element.slider_has_default_value ? props.element.ranges[0].weight : null);
            }
        });

        const { has_dkna_option, dkna_option_value } = props.element;

        const { getTranslations } = useGetters(['getTranslations']);

        const ranges = computed(() => {
            const translation = getTranslations.value?.questions?.find(q => q.guid === props.element.guid);
            if (translation?.ranges && translation?.ranges?.length > 0) {
                return translation.ranges;
            } else {
                return props.element.ranges;
            }
        });

        const dknaOptionLabel = computed(() => {
            return getTranslations.value?.questions?.find(q => q.guid === props.element.guid)?.dkna_option_label || props.element.dkna_option_label;
        });

        return {
            primary_color,
            background_color,
            text_color,
            value,
            options,
            labelStyle,
            has_dkna_option,
            dkna_option_value,
            dknaOptionLabel,
            ranges,
            endIcon,
            startIcon,
            startIconStyle,
            endIconStyle,
            isSlider,
            elementTypeEnum,
        };
    }
});
</script>
