<template>
    <div
        class="w-full flex"
        data-test-id="scale-radio-buttons"
        :class="[isReversed ? 'flex-row-reverse' : '', answerFontSizeClass]"
        @mouseleave="hovered = null"
    >
        <div
            v-for="(option, i) in options"
            :key="option"
            class="flex-grow text-center border cursor-pointer py-1"
            :class="{
                'rounded-l': i === (isReversed ? options.length - 1 : 0),
                'rounded-r': i+1 === (isReversed ? 1 : options.length),
                'border-r-0': (isReversed ? i >= 1 : i+1 !== options.length)
            }"
            :style="{
                ...boxStyle(option),
                borderColor
            }"
            @click="$emit('input', option)"
            @mouseenter="hovered = option"
        >
            {{ option }}
        </div>
    </div>
</template>

<script lang="ts">
import { chooseHigherContrast, hex2rgba } from '@/utils/color';
import { defineComponent, ref, computed } from 'vue';
import { useResponsiveFontSizeClass } from '@/components/typeSpecificElements/hooks/useStyle';
import { darkenHex } from '@/utils/color';

export default defineComponent({
    name: 'ButtonScale',
    props: {
        options: { type: Array as () => Array<number>, default: () => [] },
        selected: { type: Number, default: null },
        primaryColor: { type: String, default: '#1cc6ba' },
        backgroundColor: { type: String, default: '#ffffff' },
        textColor: { type: String, default: '#282828' },
        isReversed: { type: Boolean, default: false },
        colors: { type: Array as () => Array<string>, default: () => [] },
    },
    setup(props) {
        const hovered = ref<number|null>(null);
        const hoverColor = computed(() => hex2rgba(props.primaryColor, 0.4));
        const isColoredButton = computed(() => props.colors.length === 3);

        const boxStyle = computed(() => {
            return (option) => {
                const isSelected = props.selected !== null && props.selected >= option;
                const hoveredOrSelected = hovered.value !== null && hovered.value >= option;

                if (isColoredButton.value) {
                    let backgroundColor;

                    if (option < 7) {
                        backgroundColor = props.colors[0];
                    }
                    else if (option > 8) {
                        backgroundColor = props.colors[2];
                    }
                    else {
                        backgroundColor = props.colors[1];
                    }

                    if (isSelected) {
                        backgroundColor = darkenHex(backgroundColor, .4);
                    }
                    else if (hoveredOrSelected) {
                        backgroundColor = darkenHex(backgroundColor, .2);
                    }

                    return {
                        backgroundColor,
                        color: chooseHigherContrast(backgroundColor, props.textColor, (props.backgroundColor === 'transparent' ? '#FFFFFF' : props.backgroundColor))
                    };
                }

                let backgroundColor = 'transparent';
                if (isSelected) {
                    backgroundColor = props.primaryColor;
                }
                else if (hoveredOrSelected) {
                    backgroundColor = hoverColor.value;
                }

                let color = props.textColor;
                if (isSelected) {
                    color = chooseHigherContrast(backgroundColor, props.textColor, (props.backgroundColor === 'transparent' ? '#FFFFFF' : props.backgroundColor));
                }

                return {
                    backgroundColor,
                    color,
                };
            };
        });

        const borderColor = computed(() => {
            return hex2rgba(props.textColor, 0.3);
        });

        const { answerFontSizeClass } = useResponsiveFontSizeClass();

        return { hovered, hoverColor, boxStyle, borderColor, answerFontSizeClass };
    },
});
</script>
