// for checkbox, radio and matrix questions
import { computed } from 'vue';
import { useState, useGetters } from 'vuex-composition-helpers';
import useParentQuestion from './useParentQuestion';
import useShuffle from './useShuffle';
import hashedKeys from '@/utils/hashedKeys';
import striptags from 'striptags';

export const getOptionText = (option) => {
    return typeof option === 'string' ? option : Object(option).value;
};

export default function useOptions(element) {
    const { parentAnswer, parentQuestionHasOtherOption } = useParentQuestion(element);
    const { answers, hasNewDictionary, elements } = useState(['answers', 'hasNewDictionary', 'elements']);
    const { getTranslations } = useGetters(['getTranslations']);

    const visibleOptions =  computed<Array<any>>(() => {
        let options = (Array.isArray(element.options) ? element.options : []).map((option, index) => ({
            index,
            optionText: calculateRowLabel(index) || '',
            id: option?.id,
            value: hasNewDictionary.value ? (element.options_from_file ? option?.value : option?.id) : (element.options_from_file ? (typeof option === 'string' ? striptags(option) : option) : index)
        }));

        if (
            element.options_parent_question_guid
            && !element.use_selected_answers
            && parentQuestionHasOtherOption.value
        ) {
            // if parent question has other option, don't include it when inheriting not used answers
            options.pop();
        }

        if (element.has_random_rotation) {
            options = useShuffle(options);
        }

        return options.filter(option => isRowVisible(option.value));
    });

    // value is the option's index or id
    function isRowVisible(value: number|string): boolean {
        if (!element.options_parent_question_guid) {
            return true;
        }

        if (typeof value === 'number') {
            if (value < parentAnswer.value.value.length) {
                return element.use_selected_answers ? !!parentAnswer.value.value[value] : !parentAnswer.value.value[value];
            }
            else {
                return element.use_selected_answers ? !!parentAnswer.value.other : !parentAnswer.value.other;
            }
        }
        else {
            if (value === hashedKeys.INHERITED_OTHER) {
                return element.use_selected_answers ? !!parentAnswer.value.other : !parentAnswer.value.other;
            }

            return element.use_selected_answers ? !!parentAnswer.value.value[value] : !parentAnswer.value.value[value];
        }
    }

    function calculateRowLabel(index): string {
        let label;

        if (!element.options_parent_question_guid) {
            label = getTranslations.value?.questions?.find(q => q.guid === element.guid)?.options?.[index] || element.options[index];
        }

        else {
            const parentOptions = getTranslations.value?.questions?.find(q => q.guid === element.options_parent_question_guid)?.options || elements.value.find(e => e.guid === element.options_parent_question_guid).options;

            if (index < parentOptions.length) {
                label = parentOptions?.[index] || element.options[index];
            }
            else {
                label = answers.value[element.options_parent_question_guid]?.other;
            }
        }

        return getOptionText(label);
    }

    return visibleOptions;
}
