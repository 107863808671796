const languages = {
    HUNGARIAN: 1,
    ENGLISH: 2,
    BULGARIAN: 3,
    GERMAN: 4,
    ROMANIAN: 5,
    GEORGIAN: 6,
    RUSSIAN: 7,
    UKRAINIAN: 8,
    POLISH: 9,
    MIXED: 10,
    DUTCH: 11,
    CZECH: 12,
    SLOVAKIAN: 13,
    INDONESIAN: 14,
    ALBANIAN: 15,
    DANISH: 16,
    SWEDISH: 17,
    CROATIAN: 18,
    SERBIAN: 19,
    SLOVENIAN: 20,
    BOSNIAN: 21,
    GREEK: 22,
    NORWEGIAN: 23,
    SPANISH: 24,
    FRENCH: 25,
    ESTONIAN: 26,
    FINNISH: 27,
    ITALIAN: 28,
    LATVIAN: 29,
    PORTUGUESE: 30,
    TURKISH: 31,
    HINDI: 32,
    MALAY: 33,
};

/**
 * Nicknames are needed for language filter because Text Api returns this => Semantic Analysis language
 * */
export const nicknames = {
    HUNGARIAN: 'hu',
    ENGLISH: 'en',
    BULGARIAN: 'bg',
    GERMAN: 'de',
    ROMANIAN: 'ro',
    GEORGIAN: 'ge',
    RUSSIAN: 'ru',
    UKRAINIAN: 'ua',
    POLISH: 'pl',
    DUTCH: 'nl',
    CZECH: 'cz',
    SLOVAKIAN: 'sk',
    INDONESIAN: 'id',
    ALBANIAN: 'al',
    DANISH: 'dk',
    SWEDISH: 'se',
    CROATIAN: 'hr',
    SERBIAN: 'sr',
    SLOVENIAN: 'si',
    BOSNIAN: 'ba',
    GREEK: 'gr',
    NORWEGIAN: 'no',
    SPANISH: 'es',
    FRENCH: 'fr',
    ESTONIAN: 'et',
    FINNISH: 'fi',
    ITALIAN: 'it',
    LATVIAN: 'lv',
    PORTUGUESE: 'pt',
    TURKISH: 'tr',
    HINDI: 'hi',
    MALAY: 'my',
};

export const getOwnDisplayNameFromNickname = (nickname) => {
    switch (nickname) {
    case nicknames.BULGARIAN:
        return 'български';
    case nicknames.CZECH:
        return 'Český';
    case nicknames.DUTCH:
        return 'Nederlands';
    case nicknames.ENGLISH:
        return 'English';
    case nicknames.GEORGIAN:
        return 'ქართული';
    case nicknames.GERMAN:
        return 'Deutsch';
    case nicknames.HUNGARIAN:
        return 'Magyar';
    case nicknames.INDONESIAN:
        return 'ინდონეზიური';
    case nicknames.POLISH:
        return 'Polski';
    case nicknames.ROMANIAN:
        return 'Română';
    case nicknames.RUSSIAN:
        return 'Русский';
    case nicknames.SLOVAKIAN:
        return 'Slovenský';
    case nicknames.UKRAINIAN:
        return 'Українська';
    case nicknames.ALBANIAN:
        return 'Shqip';
    case nicknames.DANISH:
        return 'Dansk';
    case nicknames.SWEDISH:
        return 'Svenska';
    case nicknames.CROATIAN:
        return 'Hrvatski';
    case nicknames.SERBIAN:
        return 'Српски';
    case nicknames.SLOVENIAN:
        return 'Slovenski';
    case nicknames.BOSNIAN:
        return 'Bosanski / босански';
    case nicknames.GREEK:
        return 'Ελληνικά';
    case nicknames.NORWEGIAN:
        return 'Norsk';
    case nicknames.SPANISH:
        return 'Español';
    case nicknames.FRENCH:
        return 'Français';
    case nicknames.ESTONIAN:
        return 'Eesti';
    case nicknames.FINNISH:
        return 'Suomi';
    case nicknames.ITALIAN:
        return 'Italiano';
    case nicknames.LATVIAN:
        return 'Latviešu';
    case nicknames.PORTUGUESE:
        return 'Português';
    case nicknames.TURKISH:
        return 'Türkçe';
    case nicknames.HINDI:
        return 'हिंदी';
    case nicknames.MALAY:
        return 'Melayu';
    }
};

export const getIdFromNickname = (nickname) => languages[Object.keys(nicknames).find(key => nicknames[key] == nickname) || 'ENGLISH'];

export default languages;
