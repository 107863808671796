import { actionEnum, operationEnum, connectiveEnum } from '@/enums/conditionEnum';
import { multiLangGetters } from '@/store/multilang';

export const isVisibleByConditions = (state, element) => {
    if (!element.condition_groups || element.condition_groups.length === 0) {
        return true;
    } else {
        const isHidden = element.condition_groups
            .filter(conditionGroup => conditionGroup.action === actionEnum.HIDE)
            .find(conditionGroup => isConditionGroupFulfilled(conditionGroup, state, element.guid));
        const allShowOnly = element.condition_groups.filter(conditionGroup => conditionGroup.action === actionEnum.SHOW);
        const fulfilledShowOnly = allShowOnly.filter(conditionGroup => isConditionGroupFulfilled(conditionGroup, state, element.guid));
        // Visible if no condition group with 'hide' action is fulfilled and all show only conditions are fulfilled
        return isHidden === undefined && allShowOnly.length === fulfilledShowOnly.length;
    }
};

export const changeTitleByConditions = (state, element) => {
    if (!element.condition_groups || element.condition_groups.length === 0) {
        return element;
    } else {
        const title = element.condition_groups.reduce((title, conditionGroup) => {
            if (conditionGroup.action !== actionEnum.CHANGE) return title;
            const isFulfilled = isConditionGroupFulfilled(conditionGroup, state, element.guid);
            if (!isFulfilled) {
                return title;
            }
            if (state.isMultilang) {
                const translations = multiLangGetters.getTranslations(state);
                // @ts-ignore
                const translatedQuestion = translations?.questions?.find(q => q.guid === element.guid);
                if (translatedQuestion) {
                    const translatedCondition = translatedQuestion.conditions?.find(c => c.order === conditionGroup.order);
                    return translatedCondition ? translatedCondition.action_payload : translatedQuestion.title;
                }
            }
            return conditionGroup.action_payload;
        }, element.title);
        return { ...element, title };
    }
};

export const getNextPageElementByConditions = (state, element) => {
    if (!element.condition_groups || element.condition_groups.length === 0) {
        return null;
    } else {
        return element.condition_groups.reduce((targetElement, conditionGroup) => {
            if (conditionGroup.action !== actionEnum.JUMP) return targetElement;
            if (targetElement === 'thank-you') return targetElement;
            if (targetElement && typeof targetElement === 'string' && targetElement.includes('disq-page-')) return targetElement;
            const isFulfilled = isConditionGroupFulfilled(conditionGroup, state, element.guid);
            if (!isFulfilled) {
                return targetElement;
            }
            if (conditionGroup.action_payload === 'thank-you') {
                return 'thank-you';
            }
            if (conditionGroup.action_payload && conditionGroup.action_payload.includes('disq-page-')) {
                return conditionGroup.action_payload;
            }
            const groupTargetElement = state.elements.find(element => element.guid === conditionGroup.action_payload);
            if (!groupTargetElement) {
                return targetElement;
            }
            if (!targetElement) {
                return groupTargetElement;
            }
            return groupTargetElement.order > targetElement.order ? groupTargetElement : targetElement;
        }, null );
    }
};


/**
* Order is important
* true && false || true || false === (((true && false) || true) || false) === true
* */
function isConditionGroupFulfilled(conditionGroup, state, elementGuid) {
    return conditionGroup.conditions.reduce((fulfilledSoFar, condition) => {
        const fulfilledByCondition = isConditionFulfilled(condition, state, elementGuid, conditionGroup.action); // If the condition is not met, the element is visible
        return condition.connective === connectiveEnum.AND ?
            fulfilledSoFar && fulfilledByCondition :
            fulfilledSoFar || fulfilledByCondition;
    }, true);
}

function isConditionFulfilled(condition, state, elementGuid, conditionAction) {
    const conditionalQuestion = state.elements.find(e => e.guid === condition.conditional_question_guid);
    const conditionalParameter = state.usedUrlParams.find(parameter => parameter.guid === condition.conditional_question_guid);
    if (!conditionalQuestion && !conditionalParameter) {
        return false; // If the question and the url parameter does not exists, the condition is not fulfilled
    }
    if (conditionalQuestion && conditionAction !== 'jump' && elementGuid === condition.conditional_question_guid) {
        return false; // Only jump conditions can be dependent on the question itself that has the condition (Show X question if X's answer is 0 can cause infinite loop)
    }
    if (conditionalQuestion && !isVisibleByConditions(state, conditionalQuestion)) {
        return false; // If the question is not visible by conditions, the condition is not fulfilled
    }
    let answer = null;
    if(conditionalQuestion) {
        answer = state.answers[condition.conditional_question_guid];
    } else if(conditionalParameter) {
        answer = conditionalParameter.value;
    }
    // For matrix questions (where value.row is set and the answer is an array) a row acts like a separate question
    // Also conditions apply to these rows not the whole question
    if (condition.value && condition.value.row !== undefined && Array.isArray(answer)) {
        // Old dictionary
        // If the row is out of range, it means its 'any row'
        if (condition.value.row >= conditionalQuestion.options.length) {
            //@ts-ignore
            return answer.some(row => isAnswerMatchingCondition(condition, row));
        } else {
            // Else we use the row as an independent question
            return isAnswerMatchingCondition(condition, answer[condition.value.row]);
        }
    } else if (condition.value && condition.value.row !== undefined && answer !== null && typeof answer === 'object') {
        // New dictionary
        // In new dictionary row does not have a relation to the count of option indices
        if (answer[condition.value.row]) {
            return isAnswerMatchingCondition(condition, answer[condition.value.row]);
        } else {
            // If the row is out of range, it means its 'any row'
            return Object.values(answer).some(row => isAnswerMatchingCondition(condition, row));
        }
    } else {
        return isAnswerMatchingCondition(condition, answer);
    }
}

function isAnswerEmpty(answer) {
    if (answer == null) {
        return true;
    } else if (typeof answer === 'string') {
        return answer === '';
    } else if (Array.isArray(answer)) { // order question
        return false;
    } else if (answer instanceof Object && Array.isArray(answer.value)) { // then it's a checkbox, multiple values
        return !answer.other && !answer.dkna && answer.value.every(value => value === 0);
    } else if (answer instanceof Object && typeof answer.value === 'number') {
        return isNaN(answer.value);
    } else if (answer instanceof Object && !Array.isArray(answer.value)) {
        return !answer.other && !answer.dkna && (answer.value == null || Object.values(answer.value).every(option => option == 0));
    }
    return false;
}

function isAnswerMatchingCondition(condition, answer){
    if (condition.operation === operationEnum.EMPTY) {
        return isAnswerEmpty(answer);
    } else if (isAnswerEmpty(answer)) {
        return false; // No further operation can match when the answer is empty
    }
    switch (condition.operation) {
    case operationEnum.NOT_EMPTY:
        return !isAnswerEmpty(answer);
    case operationEnum.EQ:
        return condition.value !== null && answer == condition.value.value;
    case operationEnum.NOT_EQ:
        return condition.value !== null && answer != condition.value.value;
    case operationEnum.GT:
        return condition.value !== null && answer > condition.value.value;
    case operationEnum.GTE:
        return condition.value !== null && answer >= condition.value.value;
    case operationEnum.LT:
        return condition.value !== null && answer < condition.value.value;
    case operationEnum.LTE:
        return condition.value !== null && answer <= condition.value.value;
    case operationEnum.CONTAINS:
        return condition.value !== null && answer.includes(condition.value.value);
    case operationEnum.EXACTLY:
        return answerIsExactly(answer, condition.value);
    case operationEnum.ONE_OF:
        return answerIsOneOf(answer, condition.value);
    case operationEnum.ALL_OF:
        return answerIsAllOf(answer, condition.value);
    case operationEnum.NONE_OF:
        return answerIsNoneOf(answer, condition.value);
    default:
        return true;
    }
}
/**
 * @param {Object} answer
 * @param {string|undefined} answer.other
 * @param {boolean|undefined} answer.dkna
 * @param {Array} answer.value - [0,1,1,0,0] - 1 if option checked, 0 if not
 * @param {Object} expectedAnswer
 * @param {boolean} expectedAnswer.other
 * @param {boolean} expectedAnswer.dkna
 * @param {Array} expectedAnswer.value - [0,2,4], indexes of options
 * @returns {boolean}
 */
function answerIsExactly(answer, expectedAnswer) {
    // If other is not chosed in the expected answer, the real answer's other value must be empty
    const other = expectedAnswer.other ? answer.other && answer.other.length > 0 : !answer.other;
    // If dkna is not chosed in the expected answer, the real answer's dkna value must be empty
    const dkna = expectedAnswer.dkna ? answer.dkna : !answer.dkna;
    // Iterate over all options, check if only those are 1 where expectedAnswer is set
    let options;
    if (Array.isArray(answer.value)) {
        options = answer.value.reduce((isExactly, isOptionChecked, optionNumber) => {
            const shouldBeChecked = expectedAnswer.value.indexOf(optionNumber) > -1;
            if ((isOptionChecked && shouldBeChecked) || (!isOptionChecked && !shouldBeChecked)) {
                return isExactly;
            }
            return false;
        }, true);
    }
    else {
        options = Object.keys(answer.value).every(option => {
            if (answer.value[option] === 1) {
                return expectedAnswer.value.includes(option);
            }
            else {
                return !expectedAnswer.value.includes(option);
            }
        });
    }
    return options && other && dkna;
}

/**
 * Available for checkboxes, radios and dropdowns
 * @param {Object} answer
 * @param {string|undefined} answer.other
 * @param {boolean|undefined} answer.dkna
 * @param {Array|number} answer.value - For checkboxes: [0,1,1,0,0] - 1 if option checked, 0 if not. For dropdown and radio: 3 - the order of the option
 * @param {Object} expectedAnswer
 * @param {boolean} expectedAnswer.other
 * @param {boolean} expectedAnswer.dkna
 * @param {Array} expectedAnswer.value - [0,2,4], indexes of options
 * @returns {boolean}
 */
function answerIsOneOf(answer, expectedAnswer) {
    const other = expectedAnswer.other ? answer.other && answer.other.length > 0 : false;
    const dkna = expectedAnswer.dkna ? answer.dkna : false;
    let options = false;
    if (Array.isArray(answer.value)) { // checkbox
        // If we find an excepted option where the actual answer's option is checked, it's a match
        const found = expectedAnswer.value.find(expectedOption => answer.value[expectedOption] === 1);
        options = found !== undefined;
    }
    else if (typeof answer.value === 'object' && answer.value != null) {
        options = Object.keys(answer.value).some(option => {
            if (answer.value[option] === 1) {
                return expectedAnswer.value.includes(option);
            }
        });
    }
    else { // Radio or dropdown
        options = expectedAnswer.value.includes(answer.value);
    }
    return options || other || dkna;
}

/**
 * This one only available for checkboxes
 * @param {Object} answer
 * @param {string|undefined} answer.other
 * @param {boolean|undefined} answer.dkna
 * @param {Array|number} answer.value - For checkboxes: [0,1,1,0,0] - 1 if option checked, 0 if not.
 * @param {Object} expectedAnswer
 * @param {boolean} expectedAnswer.other
 * @param {boolean} expectedAnswer.dkna
 * @param {Array} expectedAnswer.value - [0,2,4], indexes of options
 * @returns {boolean}
 */
function answerIsAllOf(answer, expectedAnswer) {
    let options;
    if (Array.isArray(answer.value)) {
        options = expectedAnswer.value.reduce((isAllOf, expectedValue) => {
            return answer.value[expectedValue] === 1 && isAllOf;
        }, true);
    }
    else {
        if (Object.values(answer.value).every(o => o == 0)) {
            options = false;
        }
        else {
            options = Object.keys(answer.value).every(option => {
                if (answer.value[option] === 1) {
                    return expectedAnswer.value.includes(option);
                }
                return true;
            });
        }
    }
    const other = expectedAnswer.other ? answer.other && answer.other.length > 0 : true;
    const dkna = expectedAnswer.dkna ? answer.dkna : true;
    return options && other && dkna;
}

/**
 * Available for checkboxes, radios and dropdowns
 * @param {Object} answer
 * @param {string|undefined} answer.other
 * @param {boolean|undefined} answer.dkna
 * @param {Array|number} answer.value - For checkboxes: [0,1,1,0,0] - 1 if option checked, 0 if not. For dropdown and radio: 3 - the order of the option
 * @param {Object} expectedAnswer
 * @param {boolean} expectedAnswer.other - in this case it means, that the other options shouldn't be checked
 * @param {boolean} expectedAnswer.dkna - in this case it means, that the dkna options shouldn't be checked
 * @param {Array} expectedAnswer.value - [0,2,4], indexes of options
 * @returns {boolean}
 */
function answerIsNoneOf(answer, expectedAnswer) {
    let options = false;
    if (isAnswerEmpty(answer)) {
        return false;
    }
    if (Array.isArray(answer.value)) { // checkbox
        // All of the expected options should be 0 aka not checked.
        options = expectedAnswer.value.reduce((isNoneOf, expectedOptionOrder) => {
            return answer.value[expectedOptionOrder] == 0 && isNoneOf;
        }, true);
    }
    else if (typeof answer.value === 'object' && answer.value != null) {
        if (Object.values(answer.value).every(o => !o)) {
            options = false;
        }
        else {
            options = Object.keys(answer.value).every(option => {
                if (answer.value[option] === 1) {
                    return !expectedAnswer.value.includes(option);
                }

                return true;
            });
        }
    }
    else { // dropdown or radio
        // The answered option should be neither of the expected options
        options = expectedAnswer.value.reduce((isNoneOf, expectedOptionOrder) => {
            return answer.value !== expectedOptionOrder && isNoneOf;
        }, true);
    }
    const other = expectedAnswer.other ? !answer.other || answer.other.length === 0 : true;
    const dkna = expectedAnswer.dkna ? !answer.dkna : true;
    return options && other && dkna;
}
