<template>
    <div
        class="z-50 flex justify-end"
    >
        <drop-down
            class="w-32"
            :selected="selectedLanguage"
            :options="languages"
            :text-color="text_color"
            :background-color="bgImage && bgImage.panelSettings ? bgImage.panelSettings.bgColor : background_color"
            :is-filterable="false"
            @update-option="updateLanguage"
        />
    </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { useGetters, useMutations, useState } from 'vuex-composition-helpers';

import { getOwnDisplayNameFromNickname } from '@/utils/languages';

import DropDown from '@/components/ui/DropDown.vue';

import i18next from 'i18next';

export default defineComponent({
    components: {
        DropDown,
    },
    setup() {
        const { benchmarkLanguage, surveyLanguage, previewLang, translations, text_color, background_color, bgImage } = useState(['benchmarkLanguage', 'surveyLanguage', 'previewLang', 'translations', 'text_color', 'background_color', 'bgImage']);
        const { activeLanguages } = useGetters(['activeLanguages']);
        const { setSurveyLanguage } = useMutations(['setSurveyLanguage']);

        const updateLanguage = ({value}) => {
            setSurveyLanguage(value);
            i18next.changeLanguage(value);
        };

        const languages = computed(() => {
            if (previewLang.value) {
                return [
                    { value: benchmarkLanguage.value, name: getOwnDisplayNameFromNickname(benchmarkLanguage.value) },
                    ...Object.keys(translations.value.draftTranslations).map(lang => ({ value: lang, name: getOwnDisplayNameFromNickname(lang) })),
                ];
            }

            return [
                { value: benchmarkLanguage.value, name: getOwnDisplayNameFromNickname(benchmarkLanguage.value) },
                ...activeLanguages.value.map(lang => ({ value: lang, name: getOwnDisplayNameFromNickname(lang) })),
            ];
        });

        const selectedLanguage = computed(() => {
            return surveyLanguage.value
                ? languages.value.find(lang => lang.value === surveyLanguage.value)
                : languages.value.find(lang => lang.value === benchmarkLanguage.value);
        });

        return {
            benchmarkLanguage,
            selectedLanguage,
            updateLanguage,
            languages,
            text_color,
            background_color,
            bgImage
        };
    },
});
</script>

