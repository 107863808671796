var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"space-y-2"},[_c('div',{staticClass:"flex",class:{
            'flex-row-reverse': _vm.element.is_reversed
        }},_vm._l((_vm.element.ranges),function(range){return _c('div',{key:range.weight,staticClass:"w-full border border-r-0 flex justify-center p-1 cursor-pointer",class:{
                'last:border-r': !_vm.element.is_reversed, /* css is... */
                'first:border-r': _vm.element.is_reversed, /* ...weird */
            },style:({
                'border-color': _vm.borderColor
            }),on:{"mouseover":function($event){_vm.hoveredAnswer = range.weight},"mouseleave":function($event){_vm.hoveredAnswer = null},"click":function($event){return _vm.setAnswer({ guid: _vm.element.guid, answer: range.weight })}}},[_c('div',{staticClass:"rounded-full w-10 h-10 flex items-center justify-center",class:_vm.answerFontSizeClass,style:({
                    ..._vm.textStyle,
                    ...(range.weight === _vm.hoveredAnswer ? _vm.hoveredStyle : {}),
                    ...(range.weight === _vm.answer ? _vm.selectedStyle : {}),
                })},[_c('div',[_vm._v(" "+_vm._s(range.weight)+" ")])])])}),0),_c('div',{staticClass:"flex",class:{
            'flex-row-reverse': _vm.element.is_reversed
        }},_vm._l((_vm.element.ranges),function(range,index){return _c('div',{key:range.weight,staticClass:"w-full text-center text-xs",class:_vm.answerFontSizeClass,style:(_vm.textStyle)},[_vm._v(" "+_vm._s(_vm.getDisplayedLabel(index) || range.range)+" ")])}),0),(_vm.element.has_dkna_option)?_c('div',[_c('answer-box',{attrs:{"selected":_vm.answer === _vm.element.dkna_option_value}},[_c('vm-radio',{staticClass:"w-full p-1.5",attrs:{"value":_vm.element.dkna_option_value,"primary-color":_vm.primary_color},on:{"change":function($event){return _vm.setAnswer({ guid: _vm.element.guid, answer: _vm.element.dkna_option_value })}},model:{value:(_vm.answer),callback:function ($$v) {_vm.answer=$$v},expression:"answer"}},[_c('span',{class:_vm.answerFontSizeClass,style:(_vm.textStyle)},[_vm._v(" "+_vm._s(_vm.dknaLabel)+" ")])])],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }