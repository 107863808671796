<template>
    <div class="pictures-wrapper">
        <div class="choice-info text-neutral-800 text-default mb-4 -mt-2">
            {{ isMultipleAllowed ? $t('QUESTION.CHECKBOX') : $t('QUESTION.RADIOBUTTON') }}
        </div>
        <div class="pictures flex space-x-2 mobile:justify-center items-end">
            <label
                v-for="(picture, index) in visibleOptions"
                :key="index"
                :for="element.guid + picture.url + index"
            >
                <input
                    v-if="isMultipleAllowed"
                    :id="element.guid + picture.url + index"
                    v-model="values"
                    type="checkbox"
                    name="pictures"
                    class="hidden"
                    :value="picture.index"
                >
                <input
                    v-else
                    :id="element.guid + picture.url + index"
                    v-model="value"
                    type="radio"
                    name="pictures"
                    class="hidden"
                    :value="picture.index"
                >
                <z-tooltip
                    :tooltip="picture.option"
                    :style="{ transform: value === picture.index || values.includes(picture.index) ? 'translateY(-10px)' : 'translateY(0)' }"
                    class="transition-all duration-300"
                >
                    <img
                        v-if="picture.url"
                        crossorigin="anonymous"
                        :src="picture.url"
                        :alt="picture.option"
                        class="cursor-pointer rounded transition-all duration-300 w-full"
                        :class="{
                            'opacity-100 shadow': value === picture.index || values.includes(picture.index),
                            'opacity-50': value !== picture.index || values.includes(picture.index),
                        }"
                    >
                </z-tooltip>
            </label>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch, onBeforeMount } from 'vue';
import ZTooltip from '@/components/ui/Tooltip.vue';
import { useActions, useGetters, useState } from 'vuex-composition-helpers';
import useShuffle from './hooks/useShuffle';
import { getOptionText } from './hooks/useOptions';

export default defineComponent({
    components: {
        ZTooltip,
    },
    props: {
        element: { type: Object, default: () => ({})},
    },
    setup(props) {
        const loading = ref(false);
        const uploaded = ref(false);
        const value = ref(null);
        const values = ref<number[]|string[]>([]);

        const { answers, hasNewDictionary } = useState(['answers', 'hasNewDictionary']);
        const { setAnswer } = useActions(['setAnswer']);

        const { getTranslations } = useGetters(['getTranslations']);

        const visibleOptions = computed(() => {
            const options = props.element.options_with_urls.map((option, index) => ({
                index: hasNewDictionary.value ? option.option.id : option.index,
                option: getTranslations.value?.questions?.find(q => q.guid === props.element.guid)?.options?.[index] || getOptionText(option.option),
                url: option.url,
            }));

            if(props.element.has_random_rotation) {
                return useShuffle(options);
            } else {
                return options;
            }
        });

        const isMultipleAllowed = computed(() => {
            return props.element.is_multiple_enabled;
        });

        watch(value, updateAnswer);
        watch(values, updateAnswer);

        function updateAnswer() {
            if (isMultipleAllowed.value) {
                if (hasNewDictionary.value) {
                    const value: {[id:string]: number} = {};
                    for (let i = 0; i < props.element.options.length; i++) {
                        const id = props.element.options[i].id;
                        // @ts-ignore
                        value[id] = values.value.indexOf(id) > -1 ? 1 : 0;
                    }
                    setAnswer({ guid: props.element.guid, answer: { value }});
                } else {
                    const value: number[] = [];
                    for (let i = 0; i < props.element.options.length; i++) {
                        // @ts-ignore
                        value[i] = values.value.indexOf(i) > -1 ? 1 : 0;
                    }
                    setAnswer({ guid: props.element.guid, answer: { value }});
                }
            } else {
                setAnswer({ guid: props.element.guid, answer: { value: value.value }});
            }
        }

        onBeforeMount(() => {
            const answer = answers.value[props.element.guid];
            if (!answer || !(answer instanceof Object)) {
                return;
            }
            if (isMultipleAllowed.value) {
                values.value = answer.value;
            } else {
                value.value = answer.value;
            }
        });

        return {
            loading, uploaded, value, values, // refs
            visibleOptions, isMultipleAllowed, // computed
        };
    },
});
</script>
