export default {
    getSelfBaseUrl() {
        const baseUrl = process.env.BASE_URL || '';
        return baseUrl.replace(/,+$/, '');
    },
    getApiBaseUrl() {
        return process.env.VUE_APP_API_BASE_URL.replace(/,+$/, '');
    },
    getFrontendBaseUrl() {
        return process.env.VUE_APP_FRONTEND_BASE_URL.replace(/,+$/, '');
    },
    getIngressBaseUrl() {
        const baseUrl = process.env.VUE_APP_INGRESS_BASE_URL || process.env.INGRESS_BASE_URL;
        return baseUrl.replace(/,+$/, '');
    },
    getPort() {
        return process.env.PORT || 8080;
    },
    getEnvironment() {
        return process.env.VUE_APP_ENVIRONMENT || 'local';
    },
    getSentryRelease() {
        return process.env.VUE_APP_SENTRY_RELEASE || '1.0';
    },
    getSentryDNS() {
        // Do not send logs in local environment
        // https://github.com/getsentry/sentry-laravel/issues/204#issuecomment-468601494
        return this.getEnvironment() !== 'local' ?
            'https://d899b5e1acb24cebb67d93d40e00eab6@o515752.ingest.sentry.io/6001552' :
            null;
    },
};
