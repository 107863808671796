var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"w-full flex",class:[_vm.isReversed ? 'flex-row-reverse' : '', _vm.answerFontSizeClass],attrs:{"data-test-id":"scale-radio-buttons"},on:{"mouseleave":function($event){_vm.hovered = null}}},_vm._l((_vm.options),function(option,i){return _c('div',{key:option,staticClass:"flex-grow text-center border cursor-pointer py-1",class:{
            'rounded-l': i === (_vm.isReversed ? _vm.options.length - 1 : 0),
            'rounded-r': i+1 === (_vm.isReversed ? 1 : _vm.options.length),
            'border-r-0': (_vm.isReversed ? i >= 1 : i+1 !== _vm.options.length)
        },style:({
            ..._vm.boxStyle(option),
            borderColor: _vm.borderColor
        }),on:{"click":function($event){return _vm.$emit('input', option)},"mouseenter":function($event){_vm.hovered = option}}},[_vm._v(" "+_vm._s(option)+" ")])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }