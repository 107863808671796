<template>
    <div
        class="relative"
        :class="{
            [titleClass]: true,
        }"
    >
        <div
            v-if="visibleOrder !== null"
            class="absolute top-0 left-0 transform -translate-x-10 w-8 text-right"
            :style="questionNumberStyle"
        >
            <span v-if="isNumberVisible">
                {{ visibleOrder !== null ? `${visibleOrder}` : '' }}
            </span>
        </div>
        <div
            :style="titleStyle"
            class="element-title"
            v-html="elementTitle"
        />
        <z-tooltip
            v-if="help && helpAsTooltip && !hideTooltip"
            :id="id"
            ref="tooltipRef"
            :tooltip="help"
            class="ml-2 transition-all duration-300 translateY(-10px)"
        >
            <icon :icon="['fal', 'question-circle']" />
        </z-tooltip>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, nextTick, onMounted, onUnmounted, ref, watch } from 'vue';
import { useState } from 'vuex-composition-helpers';

import ZTooltip from '@/components/ui/Tooltip.vue';
import { useResponsiveFontSizeClass } from '../typeSpecificElements/hooks/useStyle';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import i18next from 'i18next';

import { QuestionTagType } from '@/types/SettingEnums';

export default defineComponent({
    components: {
        ZTooltip,
        'icon': FontAwesomeIcon,
    },
    props: {
        visibleOrder: { type: Number, default: null },
        title: { type: String, required: true },
        optional: { type: Boolean, default: false },
        required: { type: Boolean, default: false },
        help: { type: String, default: '' },
        helpAsTooltip: { type: Boolean, default: false },
    },
    setup(props) {
        const state = useState(['primary_color', 'text_color', 'question_font_name', 'question_font_weight', 'is_question_ordinal_number_visible', 'question_tag_type']);
        const hideTooltip = ref(false);
        const tooltipRef = ref();
        const elementTitle = ref('');

        const titleClass = computed(() => {
            const { questionFontSizeClass } = useResponsiveFontSizeClass();
            return questionFontSizeClass.value;
        });

        const questionNumberStyle = computed(() => {
            return {
                color: state.primary_color.value,
                'font-family': state.question_font_name.value,
                'font-weight': state.question_font_weight.value,
            };
        });

        const titleStyle = computed(() => {
            return {
                color: state.text_color.value,
                'font-family': state.question_font_name.value,
                'font-weight': state.question_font_weight.value,
            };
        });

        const isNumberVisible = computed(() => state.is_question_ordinal_number_visible.value);

        const questionTagText = computed(() => {
            let questionTagText = ''; // QuestionTagType.NONE case or when the tag type and the question's setting differ
            if (state.question_tag_type.value === QuestionTagType.REQUIRED && props.required) {
                questionTagText = ' <span class="text-neutral-600 font-normal">' + '*' + '</span>';
            }
            if (state.question_tag_type.value === QuestionTagType.OPTIONAL && props.optional) {
                questionTagText = ' <span class="text-neutral-600 font-normal">' + i18next.t('QUESTION.OPTIONAL') + '</span>';
            }
            return questionTagText;
        });

        const setTitle = async () => {
            hideTooltip.value = false;
            await nextTick();

            // i have no fucking idea why it doesn't work witk p tag
            const originalTitle = props.title.replaceAll('<p', '<div').replaceAll('</p>', '</div>');
            const tooltipHTML = tooltipRef.value?.$el.outerHTML || '';

            const lastIndex = originalTitle.lastIndexOf('</div>');
            if (lastIndex >= 0) {
                elementTitle.value = originalTitle.substring(0, lastIndex) + tooltipHTML + questionTagText.value + originalTitle.substring(lastIndex);
            }
            else {
                elementTitle.value = originalTitle + tooltipHTML + questionTagText.value;
            }
            hideTooltip.value = true;
        };

        const id = 'tooltip-' + Math.random().toString().replace('.', '');
        const setTooltipWidth = async () => {
            if (!props.helpAsTooltip) return;
            const tooltip = document.querySelector(`#${id} .z-tooltip`) as HTMLDivElement;
            if (!tooltip) return;
            const inner = tooltip.querySelector('.inner') as HTMLDivElement;
            if (!inner) return;

            inner.style.width = 'unset';
            inner.style.translate = 'unset';

            tooltip.classList.remove('hidden');
            const { x, width } = tooltip.getClientRects()[0];

            if (width > window.innerWidth - 40) {
                inner.style.width = window.innerWidth - 40 + 'px';
                const { x: newX } = tooltip.getClientRects()[0];
                inner.style.translate = -newX + 20 + 'px';
            }
            else if (x < 0) {
                inner.style.translate = -x + 20 + 'px';
            }
            else if (x + width > window.innerWidth - 20) {
                inner.style.translate = -(x + width - window.innerWidth) - 20 + 'px';
            }

            tooltip.classList.add('hidden');
        };

        onMounted(async() => {
            await setTitle();
            setTooltipWidth();

            window.addEventListener('resize', setTooltipWidth);
        });
        watch(props, async () => {
            await setTitle();
            setTooltipWidth();
        });

        onUnmounted(() => window.removeEventListener('resize', setTooltipWidth));

        return { titleClass, questionNumberStyle, titleStyle, isNumberVisible, elementTitle, hideTooltip, tooltipRef, id };
    },
});
</script>
