<template>
    <div
        class="h-full"
        :style="{'background-color': background_color}"
    >
        <div
            class="top-0 right-0 bottom-0 left-0 absolute h-full"
            :style="surveyBgStyle"
        />
        <simplebar
            class="survey-filler"
            :class="{
                embedded: isEmbedded,
                'scrollbar-is-hidden': scrollbarHidden
            }"
            @scroll="() => ({})"
        >
            <div
                class="survey-filler-content"
                :style="{ minWidth: '348px' }"
            >
                <partial-completion-popup
                    v-if="isAutosaveOn && !isFinished && showAutosaveDialog === true && !hasBeenFilled"
                    @accept="accept()"
                />
                <password-protection
                    v-if="!isFinished && hasPassword && !passwordChecked"
                    :survey-id="id"
                    :style="{
                        'min-height': hasOwnPrivacyPolicy && privacyPolicyUrl ? 'calc(100vh - 105px)' : 'calc(100vh - 90px)'
                    }"
                    @passwordIsOK="passwordIsOK()"
                />
                <div
                    v-else
                    :class="{ 'py-4': hasBackground }"
                    :style="{
                        'min-height': hasOwnPrivacyPolicy && privacyPolicyUrl ? 'calc(100vh - 105px)' : 'calc(100vh - 90px)'
                    }"
                >
                    <div
                        class="rounded mx-auto md:px-10 relative mobile:w-fit"
                        :style="{
                            maxWidth: '1024px',
                        }"
                    >
                        <div
                            class="top-0 right-0 bottom-0 left-0 absolute h-full w-full rounded "
                            :style="panelStyle"
                            style="max-width: 1024px"
                        />
                        <filler
                            v-if="showFiller"
                            style="max-width: 960px;"
                            class="relative"
                            :url-params-for-tracking="urlParamsForTracking"
                            @page-change="handlePageChange"
                            @finished="submit"
                        />
                        <thank-you
                            v-if="shouldShowThankYou"
                            style="min-height: 50vh;"
                            class="relative"
                        />
                        <disqualification-page
                            v-else-if="(isFinished || (showPage && showPage.includes('disq-page-'))) && disqualifiedPage"
                            style="min-height: 50vh;"
                            :element="disqualifiedPage"
                            :url-params-for-tracking="urlParamsForTracking"
                            class="relative"
                        />
                    </div>
                </div>
                <filler-footer
                    v-show="!isEmbedded"
                    :has-own-privacy-policy="hasOwnPrivacyPolicy"
                    :privacy-policy-company="privacyPolicyTitle"
                    :privacy-policy-url="privacyPolicyUrl"
                    :is-free-group="isFreeGroup"
                    @reportSurvey="reportSurvey()"
                />
            </div>
        </simplebar>
    </div>
</template>

<script>
import api from '@/api';
import 'url-polyfill';
import simplebar from 'simplebar-vue';
import { mapState, mapGetters } from 'vuex';
import Filler from '@/components/Filler.vue';
import ThankYou from '@/components/ThankYou.vue';
import PartialCompletionPopup from '@/components/PartialCompletionPopup.vue';
import PasswordProtection from '@/components/PasswordProtection.vue';
import DisqualificationPage from '@/components/DisqualificationPage.vue';
import FillerFooter from '@/components/Footer.vue';
import storage from '@/utils/storage';
import {postMessageToParent} from '@/utils/iframe';

import { toaster } from '@/utils/toaster';
import { useMutations, useState, useGetters } from 'vuex-composition-helpers';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';

import { getPanelStyle, getSurveyBgStyle } from '@/utils/getSurveyStyle';
import store from '@/store';
import multipleRestrictionEnum from '@/enums/multipleRestrictionEnum';
import googleAnalytics from '@/utils/googleAnalytics';
import gtm from '@/utils/gtm';
import facebookPixel from '@/utils/facebookPixel';

export default {
    components: {
        simplebar,
        'filler': Filler,
        'thank-you': ThankYou,
        'password-protection': PasswordProtection,
        'disqualification-page': DisqualificationPage,
        'filler-footer': FillerFooter,
        PartialCompletionPopup,
    },
    setup() {
        const route = useRoute();
        const router = useRouter();

        const { isEmbedded, recordId, answers, userId, startedAt, GTMId, googleAnalyticsId, fbPixelId, usedUrlParams, isNewEmbedded } = useState(['isEmbedded', 'recordId', 'answers', 'userId', 'startedAt', 'GTMId', 'googleAnalyticsId', 'fbPixelId', 'usedUrlParams', 'isNewEmbedded']);
        const { urlParameters } = useGetters(['urlParameters']);
        const { setIsNewEmbedded, setEmbeddedColors, setIsNewEmbeddedPreview, setRecordId } = useMutations(['setIsNewEmbedded', 'setEmbeddedColors', 'setIsNewEmbeddedPreview', 'setRecordId']);
        const width = ref(window.innerWidth);
        const showAutosaveDialog = ref(true);
        const isTrackingSet = ref(false);

        const handleMessageEvent = (event) => {
            const message = event.data;
            try {
                if (message.split('&')[0] === 'zurvey-embedded' || message.split('&')[0] === 'zurvey-embedded-preview') {
                    setIsNewEmbedded(true);

                    if (message.split('&')[0] === 'zurvey-embedded-preview') {
                        setIsNewEmbeddedPreview(true);
                    }

                    const colors = JSON.parse(message.split('&')[1]);

                    setEmbeddedColors(colors);
                }
            }
            catch (error) {
                return;
            }
        };
        const setWidth = () => width.value = window.innerWidth;

        const surveyBgStyle = computed(() => getSurveyBgStyle(width.value));
        const panelStyle = computed(() => getPanelStyle(width.value));

        const initSurveyFilling = async () => {
            if (!recordId.value) {
                try {
                    setRecordId(await api.initSurveyFilling(
                        route.params.shortCode,
                        answers.value,
                        urlParameters.value,
                        userId.value,
                        startedAt.value
                    ));
                } catch (e) {
                    Sentry.captureException(e);
                    router.push({ name: 'notFound' });
                }

                Sentry.addBreadcrumb({ message: 'recordId: ' + recordId.value });
            }
        };

        onMounted(() => {
            window.addEventListener('message', handleMessageEvent);
            window.addEventListener('resize', setWidth);
        });

        onUnmounted(() => {
            window.removeEventListener('message', handleMessageEvent);
            window.removeEventListener('resize', setWidth);
        });

        const hasBackground = computed(() => !!surveyBgStyle.value['background-image']);

        const scrollbarHidden = ref(false);

        const handlePageChange = () => {
            if(isTrackingSet.value === false && window.RENDER_FROM_FILLER) {
                gtm.loadGTMScript(GTMId.value);
                googleAnalytics.loadGAScript(googleAnalyticsId.value);
                facebookPixel.loadFbPixelScript(fbPixelId.value);

                isTrackingSet.value = true;
            }


            scrollbarHidden.value = true;
            setTimeout(() => scrollbarHidden.value = false, 200);
        };

        const accept = async () => {
            await initSurveyFilling();
            showAutosaveDialog.value = false;
        };

        return { toaster, surveyBgStyle, panelStyle, isEmbedded, hasBackground, handlePageChange, scrollbarHidden, showAutosaveDialog, accept, usedUrlParams, isNewEmbedded };
    },
    data(){
        return {
            passwordChecked: false,
            creatorName: null, // TODO
            resizeTimer: 0,
            prevWidth: 0,
            prevHeight: 0,
        };
    },
    computed: {
        ...mapGetters(['disqualifiedPage', 'getAnswersToSubmit', 'urlParameters', 'hasClosingPageType', 'currentPage', 'privacyPolicyTitle']),
        ...mapState([
            'id',
            'isTest',
            'logo_file_url',
            'title',
            'description',
            'thank_you_title',
            'thank_you_description',
            'title_font_name',
            'title_font_weight',
            'answers',
            'answer_font_name',
            'answer_font_weight',
            'primary_color',
            'text_color',
            'isFinished',
            'hasBeenFilled',
            'isMultipleFillingOn',
            'isAutosaveOn',
            'status',
            'password',
            'hasOwnPrivacyPolicy',
            'privacyPolicyUrl',
            'urlCode',
            'isFreeGroup',
            'isPreviewMode',
            'isNewEmbeddedPreview',
            'showPage',
        ]),
        background_color() {
            return this.$store.state.background_color;
        },
        hasPassword() {
            return !!this.password;
        },
        showFiller() {
            if (this.showPage) {
                return false;
            }
            if (this.isPreviewMode && !this.isFinished) {
                return true;
            }
            return (!this.isFinished && this.status === 1 && this.hasPassword && this.passwordChecked && !this.hasBeenFilled)
                || (!this.isFinished && this.status === 1 && !this.hasPassword && !this.passwordChecked && !this.hasBeenFilled);
        },
        shouldShowThankYou() {
            if ((this.status !== 1 && !this.isPreviewMode) || this.showPage === 'thank-you') {
                return true;
            }
            return this.isFinished && !this.disqualifiedPage;
        },
        urlParamsForTracking() {
            return this.usedUrlParams.reduce((acc, current) => {
                acc[`zurveyParam-${current.name}`] = current.value;
                return acc;
            }, {});
        }
    },
    created() {
        this.$load.stop();
        if (!this.isTest) {
            Sentry.setTag('zurvey', 'Survey Filler');
        } else {
            Sentry.setTag('zurvey', 'Test Filler');
        }
    },
    destroyed() {
        Sentry.setTag('zurvey', '');
        clearInterval(this.resizeTimer);
    },
    mounted() {
        postMessageToParent('loaded');
        this.$load.setColor(this.$store.state.primary_color);
        this.resizeTimer = setInterval(() => this.resize(), 10);
    },
    methods: {
        async submit() {
            if (this.isTest || this.isNewEmbeddedPreview) {
                this.$store.commit('setFinished');
                postMessageToParent('finished');
                return;
            }

            Sentry.addBreadcrumb({
                message: 'Survey submitted',
                data: this.answers,
                level: 'info'
            });

            if (!this.getAnswersToSubmit || !Object.keys(this.getAnswersToSubmit).length) {
                Sentry.captureException('Survey submit with empty answers!');
            }
            this.$load.start();

            try {
                await api.submit({
                    shortCode: this.$store.state.urlCode,
                    answers: this.getAnswersToSubmit,
                    parameters: this.urlParameters,
                    startedAt: this.$store.state.startedAt,
                    userId: this.$store.state.userId,
                    recordId: this.$store.state.recordId,
                    language: this.$store.state.surveyLanguage,
                    currentPage: this.currentPage,
                    ...(this.hasClosingPageType && {closingPageType: this.disqualifiedPage ? this.disqualifiedPage.id : 0})
                });

                this.$store.commit('setFinished');
                storage.removeItem('active-' + this.urlCode);
                if(store.state.multiFillRestrictionType === 'cookie' &&
                    (
                        store.state.multiFillRestriction === multipleRestrictionEnum.ONCE_IN_CAMPAIGN && this.urlParameters['campaign_id'] ||
                        store.state.multiFillRestriction === multipleRestrictionEnum.ONCE_IN_WAVE && this.urlParameters['campaign_id'] && this.urlParameters['wave_id']
                    )
                ) {
                    const idBasedTimeFrame = multipleRestrictionEnum.ONCE_IN_CAMPAIGN ? '-' +  this.urlParameters['campaign_id'] : '-' +  this.urlParameters['campaign_id'] + '-' + this.urlParameters['wave_id'];
                    storage.setItem('finished-' + this.urlCode + idBasedTimeFrame, new Date().toISOString());
                } else {
                    storage.setItem('finished-' + this.urlCode, new Date().toISOString());
                }

                if(!this.disqualifiedPage) {
                    this.addTracking();
                }

                postMessageToParent('finished');
                // MessageEvent added to notify mobile apps about finished surveys
                window.postMessage('successfulSubmit', '*');
            } catch (error) {
                Sentry.captureException(error);
                this.toaster.error('Something went wrong, please try again!');
            }
            this.$load.stop();
        },

        addTracking() {
            const properties = {
                recordId: this.$store.state.recordId,
                surveyUniqueName: this.urlCode,
                ...this.urlParamsForTracking
            };

            gtm.track(
                gtm.events.SURVEY_SUBMIT,
                properties,
                this.isNewEmbedded || this.isEmbedded
            );

            googleAnalytics.track(
                googleAnalytics.events.SURVEY_SUBMIT,
                properties,
                this.isNewEmbedded || this.isEmbedded
            );

            facebookPixel.track(
                facebookPixel.events.SURVEY_SUBMIT,
                properties
            );
        },

        passwordIsOK() {
            this.passwordChecked = true;
        },

        async reportSurvey() {
            Sentry.addBreadcrumb({ message: 'Survey ID', data: this.id });
            Sentry.captureMessage('Survey reported');
            this.$load.start();
            try {
                await api.reportSurvey(this.id);
                this.$load.stop();
                this.toaster.success(this.$t('TOASTER.SURVEY_REPORTED_SUCCESSFULLY', 'You reported this survey successfully.'));
            } catch (e) {
                Sentry.captureException(e);
                this.$load.stop();
                this.toaster.error(this.$t('TOASTER.SOMETHING_WENT_WRONG', 'Something went wrong!'));
            }

        },
        resize() {
            const currentWidth = document.querySelector('.survey-filler-content')?.scrollWidth;
            const currentHeight = document.querySelector('.survey-filler-content')?.scrollHeight;
            if ((this.showFiller || this.prevHeight === 0) && (currentWidth !== this.prevWidth || currentHeight !== this.prevHeight)) {
                postMessageToParent({
                    resize: true,
                    height: currentHeight,
                    width: currentWidth,
                });
                this.prevWidth = currentWidth;
                this.prevHeight = currentHeight;
            }
        }
    },
};
</script>
<style lang="less" scoped>
.survey-filler {
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    overflow: auto;
}

.accept-checkbox {
    display: block;
    margin-top: 10px;
}

#autosave-checkbox {
    margin-right: 5px;
}

/deep/ .pp-link{
    text-decoration: underline;
}

.mobile {
    .accept-checkbox {
        label {
            width: 90% !important;
        }
    }
}

/deep/ .scrollbar-is-hidden .simplebar-scrollbar {
    visibility: hidden;
}

@media screen and (max-width: 600px) {
    .mobile()
}
</style>
