import elementValidator from './utils/elementValidator';

import { autoPageMutations } from './autopage';
import { multiLangMutations } from './multilang';
import getters from './getters';

export default {
    setAccessedFeatures(state, features) {
        state.accessedFeatures = features;
    },
    setId(state, id) {
        state.id = id;
    },
    setUrlCode(state, urlCode) {
        state.urlCode = urlCode;
    },
    setElements(state, elements) {
        state.elements = elements.map(element => {
            const options = Array.isArray(element.options) ? element.options : [];

            const optionsWithUrls = [] as {index: number, option: string, url: string}[];
            for (let i = 0; i < options.length; i++) {
                optionsWithUrls.push({
                    index: i,
                    option: element.options[i],
                    url: element.option_urls ? element.option_urls[i] : null
                });
            }
            return {
                ...element,
                options,
                option_urls: element.option_urls ? element.option_urls : [],
                options_with_urls: optionsWithUrls
            };
        });
    },
    setUserId(state, userId) {
        state.userId = userId;
    },
    setRecordId(state, recordId) {
        state.recordId = recordId;
    },
    setStartedAt(state, startedAt) {
        state.startedAt = startedAt;
    },
    setMetaData(state, surveyData) {
        state.title = surveyData.title || state.title;
        state.description = surveyData.description || state.description;
        state.created_at = surveyData.created_at || state.created_at;
        state.creator = surveyData.user_group_name || state.creator;
        state.logo_file_url = surveyData.logo_file_url || state.logo_file_url;
        state.logo_source_url = surveyData.logo_source_url || state.logo_source_url;
        state.thank_you_title = surveyData.thank_you_title || state.thank_you_title;
        state.thank_you_description = surveyData.thank_you_description || state.thank_you_description;
        state.thank_you_image_url = surveyData.thank_you_image || state.thank_you_image_url;
        state.thank_you_logo_url = surveyData.thank_you_logo_url || state.thank_you_logo_url;
        state.thank_you_logo = surveyData.thank_you_logo || state.thank_you_logo;
        state.primary_color = surveyData.primary_color || state.primary_color;
        state.text_color = surveyData.text_color || state.text_color;
        state.background_color = surveyData.background_color || state.background_color;
        state.title_font_name = surveyData.title_font_name || state.title_font_name;
        state.title_font_weight = surveyData.title_font_weight || state.title_font_weight;
        state.title_font_size = surveyData.title_font_size || state.title_font_size;
        state.question_font_name = surveyData.question_font_name || state.question_font_name;
        state.question_font_weight = surveyData.question_font_weight || state.question_font_weight;
        state.question_font_size = surveyData.question_font_size || state.question_font_size;
        state.answer_font_name = surveyData.answer_font_name || state.answer_font_name;
        state.answer_font_weight = surveyData.answer_font_weight || state.answer_font_weight;
        state.answer_font_size = surveyData.answer_font_size || state.answer_font_size;
        state.layout_spacing = surveyData.layout_spacing || state.layout_spacing;
        state.is_question_ordinal_number_visible = surveyData.is_question_ordinal_number_visible !== undefined ? surveyData.is_question_ordinal_number_visible : state.is_question_ordinal_number_visible;
        state.question_tag_type = surveyData.question_tag_type !== undefined ? surveyData.question_tag_type : state.question_tag_type;
        state.is_filling_time_visible = surveyData.is_filling_time_visible !== undefined ? surveyData.is_filling_time_visible : state.is_filling_time_visible;
        state.hasOwnPrivacyPolicy = surveyData.has_own_privacy_policy !== undefined ? surveyData.has_own_privacy_policy : state.hasOwnPrivacyPolicy;
        state.privacyPolicyTitle = surveyData.privacy_policy_title || state.privacyPolicyTitle;
        state.privacyPolicyUrl = surveyData.privacy_policy_url || state.privacyPolicyUrl;
        state.status = surveyData.status || state.status;
        state.language_id  = surveyData.language_id || state.language_id;
        state.uniqueName = surveyData.unique_name || state.uniqueName;
        state.urlParameters = surveyData.url_parameters ? surveyData.url_parameters : state.urlParameters;
        state.isMultipleFillingOn =  surveyData.is_multiple_on !== undefined ? surveyData.is_multiple_on : state.isMultipleFillingOn;
        state.multiFillRestriction = surveyData.multiple_restriction !== undefined ? surveyData.multiple_restriction : state.multiFillRestriction;
        state.multiFillRestrictionType = surveyData.multifilling_restriction_type !== undefined ? surveyData.multifilling_restriction_type : state.multiFillRestrictionType;
        state.isIpCollectionOn =  surveyData.is_anonymous_on !== undefined ? !surveyData.is_anonymous_on : state.isIpCollectionOn;
        state.isAutosaveOn =  surveyData.is_autosave_on !== undefined ? surveyData.is_autosave_on : state.isAutosaveOn;
        state.password =  surveyData.password !== undefined ? surveyData.password : state.password;
        state.responseLimit =  surveyData.target_number !== undefined ? surveyData.target_number : state.responseLimit;
        state.dataSourceId =  surveyData.data_source_id ? surveyData.data_source_id : state.dataSourceId;
        state.isAutoPaginationOn = surveyData.is_autopage_enabled || state.isAutoPaginationOn;
        state.lastResponseAt = surveyData.last_response_at || state.last_response_at;
        state.datasetId = surveyData.dataset_id;
        state.filling_time = surveyData.filling_time || state.filling_time;
        state.bgImage = surveyData.bg_image || state.bgImage;
        state.is_filling_progress_visible = surveyData.is_filling_progress_visible !== undefined ? surveyData.is_filling_progress_visible : state.is_filling_progress_visible;
        state.filling_progress_position = surveyData.filling_progress_position !== undefined ? surveyData.filling_progress_position :  state.filling_progress_position;
        state.logo_settings = surveyData.logo_settings ? surveyData.logo_settings : state.logo_settings;
        state.thankYouRedirect = surveyData.thank_you_redirect || state.thankYouRedirect;
        state.overallResponseLimitTarget = surveyData.overall_response_limit_target || state.overallResponseLimitTarget;
        state.singleResponseLimitTarget = surveyData.single_response_limit_target || state.singleResponseLimitTarget;
        state.resumableSurveyFillingGuid = surveyData.resumable_survey_filling_guid;
        state.GTMId =  surveyData.gtm_id;
        state.googleAnalyticsId =  surveyData.google_analytics_id;
        state.fbPixelId =  surveyData.facebook_pixel_id;
    },
    setAnswer (state, {guid, answer}) {
        state.answers = {
            ...state.answers,
            [guid]: answer,
        };
    },
    setSelectedAnswer(state, {guid, index}) {
        state.selectedAnswers = {
            ...state.selectedAnswers,
            [guid]: index,
        };
    },
    unsetAnswer (state, guid) {
        const answerIndex = Object.keys(state.answers).findIndex(a => a === guid);
        if(answerIndex >= 0) {
            delete state.answers[guid];
        }
    },
    setFinished(state, isFinished = true) {
        state.isFinished = isFinished;
    },
    reset(state) {
        state.answers = {};
        state.usedUrlParams = [];
    },
    setHasBeenFilled(state, hasBeenFilled = true) {
        state.hasBeenFilled = hasBeenFilled;
    },
    setIsFreeGroup(state, isFreeGroup = false) {
        state.isFreeGroup = isFreeGroup;
    },
    setDisqualificationPages(state, pages) {
        state.disqualificationPages = pages;
    },
    setIsAutosaveAccepted(state, isAccepted) {
        state.isAutosaveAccepted = isAccepted;
    },
    setIsTest(state, isTest) {
        state.isTest = isTest;
    },
    validate(state) {
        state.validationErrors = getters.editableElements(state).reduce((validationErrors, element) => {
            return {
                ...validationErrors,
                [element.guid]: elementValidator(state, element),
            };
        }, {});
    },
    updateParameters(state, parameters) {
        state.urlParameters = parameters;
    },
    setUsedUrlParams(state, usedUrlParams) {
        state.usedUrlParams = usedUrlParams;
    },
    setIsEmbedded(state, isEmbedded) {
        state.isEmbedded = isEmbedded;
    },
    setIsNewEmbedded(state, value) {
        state.isNewEmbedded = value;
    },
    setIsNewEmbeddedPreview(state, value) {
        state.isNewEmbeddedPreview = value;
    },
    setEmbeddedColors(state, { textColor, primaryColor, backgroundColor, panelColor, backgroundImage }) {
        state.text_color = textColor;
        state.primary_color = primaryColor;
        state.background_color = backgroundColor;
        if(state.bgImage) {
            state.bgImage.panelSettings.bgColor = panelColor;
            state.shouldShowBgImageOnEmbed = backgroundImage;
        }
    },
    setEmbeddedType(state, type) {
        state.embeddedType = type;
    },
    setIsPreviewMode(state, isPreviewMode) {
        state.isPreviewMode = isPreviewMode;
    },
    setQuery(state, newQuery) {
        state.query = {
            p: newQuery.p || 1,
            o: newQuery.o || 0
        };
    },
    setShowPage(state, page) {
        state.showPage = page;
    },
    setLastVisitedPage(state, page) {
        state.lastVisitedPage = page;
    },
    ...autoPageMutations,
    ...multiLangMutations,
    setSurvey(state, data) {
        state.answers = data.answers;
        state.startedAt = data.startedAt;
        state.userId = data.userId;
        state.recordId = data.recordId;
        state.surveyLanguage = data.language;
    },
    setHasNewDictionary(state, hasNewDictionary) {
        state.hasNewDictionary = hasNewDictionary;
    },
    updateDropdownWithOptionsFromFileAnswer(state, {  guid, index }) {
        state.dropdownWithOptionsFromFileAnswers[guid] = index;
    },
};
