var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.$slots.label || _vm.label)?_c('label',{staticClass:"text-default block w-full pl-2 mb-2 text-black"},[_vm._t("label",function(){return [_vm._v(" "+_vm._s(_vm.label)+" ")]})],2):_vm._e(),_c('div',{staticClass:"flex items-center relative"},[_c('span',{staticClass:"absolute mx-2"},[_vm._t("icon")],2),_c('input',_vm._g(_vm._b({ref:"input",staticClass:"block flex-1 max-w-full rounded p-2 placeholder-neutral-700 disabled:opacity-75 disabled:cursor-not-allowed outline-none",class:[
                !!_vm.hasError ? 'border-red-500' : '',
                !!_vm.$slots.icon ? 'pl-6' : '',
                !_vm.isActive && !_vm.isHovered && !_vm.hasError ? 'border-neutral-800' : '',
                _vm.type !== 'no-border' ? 'border' : '',
                _vm.isActive ? 'border-primary-700' : '',
                _vm.fontSizeClass
            ],style:(_vm.boxStyle),attrs:{"data-test-id":_vm.dataTestId,"type":_vm.type,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"max":_vm.max},domProps:{"value":_vm.value},on:{"input":_vm.input,"mouseover":function($event){_vm.isHovered = true},"mouseleave":function($event){_vm.isHovered = false},"focus":function($event){_vm.isActive = true},"focusout":function($event){_vm.isActive = false}}},'input',_vm.$attrs,false),_vm.listeners))]),(_vm.$slots.errorMessage || _vm.errorMessage)?_c('span',{staticClass:"text-default text-red-500"},[_vm._t("errorMessage",function(){return [_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]})],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }