import { render, staticRenderFns } from "./Slider.vue?vue&type=template&id=13f3a2e8&scoped=true"
import script from "./Slider.vue?vue&type=script&lang=ts"
export * from "./Slider.vue?vue&type=script&lang=ts"
import style0 from "./Slider.vue?vue&type=style&index=0&id=13f3a2e8&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13f3a2e8",
  null
  
)

export default component.exports