<template>
    <div>
        <label
            v-if="$slots.label || label"
            class="text-default block w-full pl-2 mb-2 text-black"
        >
            <slot name="label">
                {{ label }}
            </slot>
        </label>
        <div class="flex items-center relative">
            <span class="absolute mx-2">
                <slot name="icon" />
            </span>
            <input
                ref="input"
                :data-test-id="dataTestId"
                :value="value"
                :type="type"
                :placeholder="placeholder"
                :disabled="disabled"
                :max="max"
                v-bind="$attrs"
                class="block flex-1 max-w-full rounded p-2 placeholder-neutral-700 disabled:opacity-75 disabled:cursor-not-allowed outline-none"
                :class="[
                    !!hasError ? 'border-red-500' : '',
                    !!$slots.icon ? 'pl-6' : '',
                    !isActive && !isHovered && !hasError ? 'border-neutral-800' : '',
                    type !== 'no-border' ? 'border' : '',
                    isActive ? 'border-primary-700' : '',
                    fontSizeClass
                ]"
                :style="boxStyle"
                @input="input"
                @mouseover="isHovered = true"
                @mouseleave="isHovered = false"
                @focus="isActive = true"
                @focusout="isActive = false"
                v-on="listeners"
            >
        </div>
        <span
            v-if="$slots.errorMessage || errorMessage"
            class="text-default text-red-500"
        >
            <slot name="errorMessage">
                {{ errorMessage }}
            </slot>
        </span>
    </div>
</template>

<script>
import {getBackgroundColor, getPrimaryColor, getTextColor} from '@/utils/theme';
import { useResponsiveFontSizeClass } from '@/components/typeSpecificElements/hooks/useStyle';

export default {
    name: 'ZInput',
    props:  {
        label: { type: String, default: null },
        placeholder: { type: String, default: null },
        errorMessage: { type: String, default: null },
        type: { type: String, default: 'text' },
        max: { type: Number, default: null },
        disabled: { type: Boolean, default: null },
        value: { type: [String,Number], default: null },
        textColor: { type: String, default: getTextColor },
        primaryColor: { type: String, default: getPrimaryColor },
        backgroundColor: { type: String, default: getBackgroundColor },
        dataTestId: { type: String, default: null },
        error: { type: Boolean, default: false }
    },
    data() {
        return {
            isHovered: false,
            isActive: false
        };
    },
    computed: {
        listeners() {
            const { ...listeners } = this.$listeners;
            delete listeners.input;
            return listeners;
        },
        boxStyle() {
            if  (this.isActive || this.isHovered) {
                return {
                    'background-color': this.backgroundColor,
                    'color': this.textColor,
                    'border-color': this.textColor // default N1100 black
                };
            }
            return {
                'background-color': this.backgroundColor,
                'color': this.textColor
            };
        },
        hasError() {
            return Boolean(this.errorMessage) || this.error;
        },
        fontSizeClass() {
            const { answerFontSizeClass } = useResponsiveFontSizeClass();
            return answerFontSizeClass.value;
        },
    },
    methods: {
        input(event) {
            this.$emit('input', event.target.value);
        },
        focus() {
            this.$refs.input.focus();
        }
    },
};
</script>
