<template>
    <div>
        <survey-filler />
        <div
            v-if="!$store.state.isEmbedded && !$store.state.isPreviewMode"
            class="fixed bottom-0 w-96 text-center mx-auto mb-4 p-4 rounded text-neutral-800"
            style="left: calc(50vw - 12rem); background: rgba(242, 243, 248, 0.8); backdrop-filter: blur(5px);"
        >
            <b>{{ $t('TEST.TEST_PREVIEW', 'Test Preview') }}</b>
            {{ $t('TEST.YOUR_ANSWER_WONT_BE_COLLECTED', 'Your answers won\'t be collected') }}
        </div>
    </div>
</template>

<script>
import SurveyFiller from '@/pages/SurveyFiller';
export default {
    name: 'TestFiller',
    components: {SurveyFiller}
};
</script>
