import { isVisibleByConditions, changeTitleByConditions, getNextPageElementByConditions } from '@/store/utils/conditionHelpers';
import {hex2rgba} from '@/utils/color';
import elementTypes from '@/enums/elementTypeEnum';

import { autoPageGetters } from './autopage';
import { multiLangGetters } from './multilang';

function answerValueHasValue(answerValue, value: 0|1): boolean {
    if (Array.isArray(answerValue)) {
        return answerValue.some(v => v === value);
    }

    return Object.values(answerValue).some(v => v=== value);
}

export default {
    editableElements: (state) => state.elements.filter(element => element.type !== elementTypes.META),
    hasClosingPageType: (state) => state.elements.filter(element => element.title === 'Closing page type').length > 0,
    fillerElements: (state, getters) => {
        let currentVisibleOrder = 0;
        return getters.editableElements
            .filter(element => isVisibleByConditions(state, element))
            .filter(element => {
                if (!element.options_parent_question_guid) {
                    return true;
                }

                const answer = state.answers[element.options_parent_question_guid];

                if (
                    !element.use_selected_answers
                    && (
                        !answer
                        || (answer && answerValueHasValue(answer.value, 0))
                    )
                ) {
                    //Order type question with inherited options should be shown if it has minimum 2 non-selected options
                    if (element.type === elementTypes.ORDER && typeof answer !== 'undefined' && answer !== null) {
                        if (Array.isArray(answer.value)) {
                            return answer.value.filter(value => value === 0).length > 1 || (answer.value.filter(value => value === 0).length > 0 && answer.other);
                        }
                        const answerLength = Object.values(answer.value).filter(a => a === 0).length;

                        return answerLength >= 2;
                    }

                    return true;
                }

                if (element.use_selected_answers && typeof answer !== 'undefined' && answer !== null) {
                    //Order type question with inherited options should be shown if it has minimum 2 selected options
                    if (element.type === elementTypes.ORDER) {
                        if (Array.isArray(answer.value)) {
                            return answer.value.filter(value => value === 1).length > 1 || (answer.value.filter(value => value === 1).length > 0 && answer.other);
                        }

                        let answerLength = Object.values(answer.value).filter(Boolean).length;

                        if (answer.other) answerLength++;

                        return answerLength >= 2;
                    }

                    return answerValueHasValue(answer.value, 1) || answer.other;
                }
                return false;
            })
            .map(element => changeTitleByConditions(state, element))
            .map(element => ({
                ...element,
                validation_error_message: state.validationErrors[element.guid],
            }))
            .map(element => {
                if (element.type === elementTypes.EXPLANATION) {
                    return element;
                }
                const optionsWithUrls = [] as {index: number, option: string, url: string}[];
                if (Array.isArray(element.options)) {
                    for (let i = 0; i < element.options.length; i++) {
                        optionsWithUrls.push({
                            index: i,
                            option: element.options[i],
                            url: element.option_urls ? element.option_urls[i] : null
                        });
                    }
                }
                currentVisibleOrder++;
                return {
                    ...element,
                    visible_order: currentVisibleOrder,
                    options_with_urls: optionsWithUrls,
                };
            });
    },
    currentPage: (state, getters) => {
        if (!isNaN(parseInt(state.query.p))) {
            return parseInt(state.query.p);
        }
        const firstVisibleElement = getters.fillerElements.sort((e1, e2) => e1.order - e2.order)[0];
        return firstVisibleElement ? firstVisibleElement.page : 1;
    },
    hasNextPage: (_, getters) => !!(getters.editableElements.find(element => element.page > getters.currentPage)),
    answerQuotaTarget: (state, getters) => {
        return getters.fillerElements
            .filter(element => element.answer_quotas && element.answer_quotas.length > 0 && element.has_response_limit)
            .map(element => {
                const answer = state.answers[element.guid];
                if (answer === undefined || answer === null) {
                    return null;
                }
                const optionIndex = state.hasNewDictionary ? element.options.findIndex(o => o.id == answer.value) : answer.value;
                const answerQuota = element.answer_quotas[optionIndex];
                if (answerQuota && answerQuota.limit <= answerQuota.limit_used && answerQuota.target !== null){
                    return answerQuota.target;
                } else {
                    return null;
                }
            })
            .find(target => target !== null);
    },
    nextPageFirstVisibleElement: (state, getters) => {

        if(getters.answerQuotaTarget !== undefined){
            return getters.answerQuotaTarget;
        }

        // search the highest jump from conditions
        const elementToJump = getters.editableElements
            .filter(e =>  isNaN(getters.currentPage) ? true : e.page === getters.currentPage)
            .reduce((targetElement, element) =>  {
                if (targetElement === 'thank-you' || (targetElement && targetElement.includes && targetElement.includes('disq-page-')) ) {
                    return targetElement;
                }
                const targetElementByCurrentElement = getNextPageElementByConditions(state, element);
                if (!targetElementByCurrentElement) {
                    return targetElement;
                }
                if (targetElementByCurrentElement === 'thank-you') {
                    return 'thank-you';
                }
                if (!targetElement) {
                    return targetElementByCurrentElement;
                }
                return targetElement.order > targetElementByCurrentElement.order ? targetElement : targetElementByCurrentElement;
            }, null);
        if (elementToJump === 'thank-you') {
            return 'thank-you';
        }
        if (elementToJump && elementToJump.includes && elementToJump.includes('disq-page-')) {
            return elementToJump;
        }
        // If there is a jump, and it's higher than current page, jump there
        if (elementToJump && elementToJump.page > getters.currentPage) {
            return elementToJump;
        }
        // if there's no jump, calc 'natural' next page
        const nextPageFirstElement = getters.fillerElements.find(element => element.page > getters.currentPage);
        // if there's no 'natural' next page, finish the survey
        return nextPageFirstElement ? nextPageFirstElement : 'thank-you';
    },
    disqualifiedPage: (state, getters) => {
        if (state.showPage?.includes('disq-page-')) {
            return state.disqualificationPages.find(page => page.order == state.showPage.split('-')[2]);
        }

        const nextElement = getters.nextPageFirstVisibleElement;
        if (!nextElement.includes || !nextElement.includes('disq-page-')) {
            return false;
        }
        const disqPageOrder = parseInt(nextElement.match(/\d*$/));
        return state.disqualificationPages.find(page => page.order === disqPageOrder);
    },
    getAnswersToSubmit: (state) => {
        return Object.entries(state.answers).reduce((answers , [guid, answer]) => {
            const thisElement = state.elements.find(e => e.guid === guid);
            let thisAnswer : null|any = null;
            if (thisElement && isVisibleByConditions(state, thisElement)) {
                thisAnswer = answer;
            }
            return {
                ...answers,
                [guid]: thisAnswer,
            };
        }, {});
    },
    activeBoxStyle: (state) => {
        return {
            'background-color': 'transparent',
            'color': state.text_color,
            'border-color': state.primary_color,
        };
    },
    hoverBoxStyle: (state) => {
        return {
            'background-color': 'transparent',
            'color': state.text_color,
            'border-color': state.text_color,
        };
    },
    baseBoxStyle: (state) => {
        return {
            'background-color': 'transparent',
            'color': state.text_color,
            'border-color':  hex2rgba(state.text_color, .5),
        };
    },
    urlParameters: (state) => {
        const initialValue = {};
        return state.usedUrlParams.reduce((obj, param) => {
            return {
                ...obj,
                [param['name']]: param['value']
            };
        }, initialValue);
    },
    privacyPolicyTitle(state) {
        return state.privacyPolicyTitle || 'survey creator';
    },
    ...autoPageGetters,
    ...multiLangGetters,
};
