<template>
    <div
        class="fixed flex items-center justify-center top-0 left-0 bottom-0 right-0 z-30"
        :style="{ 'background-color': hasBackdrop && 'rgb(0,0,0,.5)' }"
    >
        <div
            class="fixed top-0 bottom-0 left-0 right-0 bg-transparent z-40"
            @click="clickOut"
        />
        <simplebar
            class="rounded bg-white text-black p-4 shadow-depth-300 overflow-hidden z-50"
            :class="{ 'pt-0': stickyHeader, 'w-128': fixWidth }"
            style="max-height:  calc(100vh - 32px)"
            @scroll="() => ({})"
        >
            <div
                v-if="title || $slots.header || isClosable || hasMorePages"
                :class="{
                    '-m-4 mb-0 p-4 sticky top-0 bg-bg': stickyHeader,
                    'mb-3': !stickyHeader
                }"
            >
                <font-awesome-icon
                    v-if="hasMorePages"
                    :icon="['fal', 'chevron-left']"
                    class="cursor-pointer float-left mt-1 mr-1"
                    data-test-id="popup-go-back"
                    @click="$emit('go-back')"
                />
                <font-awesome-icon
                    v-if="isClosable"
                    :icon="['fal', 'times']"
                    class="cursor-pointer float-right ml-4 mb-4"
                    data-test-id="close-popup"
                    @click="$emit('close')"
                />
                <slot name="header">
                    <span
                        class="text-base text-black font-bold capitalize"
                        v-html="title"
                    />
                </slot>
            </div>
            <div>
                <slot name="default">
                    <span
                        class="text-black text-default"
                        v-html="text"
                    />
                </slot>
            </div>
            <div class="footer">
                <slot name="footer" />
            </div>
        </simplebar>
    </div>
</template>

<script>
import simplebar from 'simplebar-vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
    name: 'Popup',
    components: {
        simplebar,
        FontAwesomeIcon,
    },
    props: {
        title: { type: String, default: null },
        text: { type: String, default: null },
        isClosable: { type: Boolean, default: false },
        shouldCloseOnClickout: { type: Boolean, default: false},
        hasBackdrop: { type: Boolean, default: false },
        hasMorePages: { type: Boolean, default: false },
        stickyHeader: { type: Boolean, default: false },
        fixWidth: { type: Boolean, default: false },
    },
    methods: {
        clickOut() {
            if (this.isClosable && this.shouldCloseOnClickout) {
                this.$emit('close');
            }
        }
    },
};
</script>
