<template>
    <div
        ref="root"
        class="relative"
    >
        <textarea
            ref="textarea"
            :value="value"
            class="block w-full max-w-s border rounded p-2"
            :class="[
                (Boolean(element.validation_error_message) || showMaxLengthError) ? 'border-red-500' : '',
                answerFontSizeClass,
                { 'mb-6': !!element.max_length }
            ]"
            :style="active ? activeBoxStyle : (hover ? hoverBoxStyle : baseBoxStyle)"
            @mouseover="hover = true"
            @mouseleave="hover = false"
            @focus="active = true"
            @focusout="setValue()"
        />
        <div
            v-if="!!element.max_length && value && (element.max_length - (value ? value.length : 0) <= 150)"
            class="text-sm absolute right-0 top-100"
            :class="showMaxLengthError ? 'text-red-700' : 'text-neutral-800'"
        >
            {{ maxCountInfo }}
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, ref, computed, onMounted, onUnmounted, nextTick } from 'vue';
import { useActions, useGetters, useState } from 'vuex-composition-helpers';
import { useResponsiveFontSizeClass } from '@/components/typeSpecificElements/hooks/useStyle';
import i18next from 'i18next';

export default defineComponent({
    props: {
        element: { type: Object, default: () => ({})},
        isFirstUnansweredElement: { type: Boolean, default: false},
    },
    setup(props) {
        const root = ref<HTMLDivElement>();
        const textarea = ref<HTMLTextAreaElement>();
        const hover = ref(false);
        const active = ref(false);

        const { answers } = useState(['answers']);
        const { activeBoxStyle, hoverBoxStyle, baseBoxStyle } = useGetters(['activeBoxStyle', 'hoverBoxStyle', 'baseBoxStyle']);
        const { setAnswer } = useActions(['setAnswer']);

        const value = ref(answers.value[props.element.guid] || null);
        const showMaxLengthError = computed(() => (props.element.max_length && value.value) ? (value.value.length > props.element.max_length) : false);
        const maxCountInfo = computed(() => {
            const remained = props.element.max_length - value.value.length;
            if(remained >= 0) {
                return i18next.t('QUESTION.TEXTAREA_MAX_LENGTH_INFO', {
                    remained: remained,
                    defaultValue: '{{ remained }} characters left'
                });
            } else {
                return i18next.t('QUESTION.TEXTAREA_MAX_LENGTH_INFO_EXCEEDED', {
                    remained: Math.abs(remained),
                    defaultValue: 'Limit exceeded by {{ remained }} characters'
                });
            }
        });

        onMounted(async () => {
            if (!textarea.value || !root.value) return;

            if (props.isFirstUnansweredElement) {
                textarea.value.focus();
            }

            textarea.value.addEventListener('input', resizeTextareaAndHandleValue);

            await nextTick();

            textarea.value.style.height = root.value.scrollHeight > 0 ? root.value.scrollHeight + 'px' : 'auto';
            textarea.value.style.overflowY = 'hidden';
        });

        onUnmounted(() => textarea.value?.removeEventListener('input', resizeTextareaAndHandleValue));

        function setValue() {
            active.value = false;
            setAnswer({ guid: props.element.guid, answer: value.value });
        }

        function resizeTextareaAndHandleValue(event) {
            event.target.style.height = 'auto';
            event.target.style.height = (event.target.scrollHeight) + 'px';

            value.value = event.target.value;
        }

        const { answerFontSizeClass } = useResponsiveFontSizeClass();

        return {
            root, textarea, hover, active, // refs
            activeBoxStyle, hoverBoxStyle, baseBoxStyle, // store getters
            value, // computed
            setValue, // methods
            answerFontSizeClass,
            showMaxLengthError,
            maxCountInfo
        };
    }
});
</script>
