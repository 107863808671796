import dayjs from 'dayjs';

const restrictions = {
    ANY: 'any',
    DAY: 'day',
    WEEK: 'week',
    MONTH: 'month',
    QUARTER: 'quarter',
    YEAR: 'year',
    ONCE_IN_CAMPAIGN: 'once_in_campaign',
    ONCE_IN_WAVE: 'once_in_wave',
};

export const getRestrictionMark = (restriction) => {
    switch (restriction) {
    case restrictions.DAY:
        return dayjs().subtract(1, 'day');
    case restrictions.WEEK:
        return dayjs().subtract(1, 'week');
    case restrictions.MONTH:
        return dayjs().subtract(1, 'month');
    case restrictions.QUARTER:
        return dayjs().subtract(3, 'month');
    case restrictions.YEAR:
        return dayjs().subtract(1, 'year');
    }
};

export const isTimeBasedRestriction = (restriction) => {
    switch (restriction) {
    case restrictions.DAY:
    case restrictions.WEEK:
    case restrictions.MONTH:
    case restrictions.QUARTER:
    case restrictions.YEAR:
        return true;
    default:
        return false;
    }
};

export default restrictions;
