<template>
    <div class="space-y-2">
        <div
            class="flex"
            :class="{
                'flex-row-reverse': element.is_reversed
            }"
        >
            <div
                v-for="range in element.ranges"
                :key="range.weight"
                class="w-full border border-r-0 flex justify-center p-1 cursor-pointer"
                :class="{
                    'last:border-r': !element.is_reversed, /* css is... */
                    'first:border-r': element.is_reversed, /* ...weird */
                }"
                :style="{
                    'border-color': borderColor
                }"
                @mouseover="hoveredAnswer = range.weight"
                @mouseleave="hoveredAnswer = null"
                @click="setAnswer({ guid: element.guid, answer: range.weight })"
            >
                <div
                    class="rounded-full w-10 h-10 flex items-center justify-center"
                    :style="{
                        ...textStyle,
                        ...(range.weight === hoveredAnswer ? hoveredStyle : {}),
                        ...(range.weight === answer ? selectedStyle : {}),
                    }"
                    :class="answerFontSizeClass"
                >
                    <div>
                        {{ range.weight }}
                    </div>
                </div>
            </div>
        </div>
        <div
            class="flex"
            :class="{
                'flex-row-reverse': element.is_reversed
            }"
        >
            <div
                v-for="(range, index) in element.ranges"
                :key="range.weight"
                class="w-full text-center text-xs"
                :style="textStyle"
                :class="answerFontSizeClass"
            >
                {{ getDisplayedLabel(index) || range.range }}
            </div>
        </div>
        <div v-if="element.has_dkna_option">
            <answer-box
                :selected="answer === element.dkna_option_value"
            >
                <vm-radio
                    v-model="answer"
                    :value="element.dkna_option_value"
                    :primary-color="primary_color"
                    class="w-full p-1.5"
                    @change="setAnswer({ guid: element.guid, answer: element.dkna_option_value })"
                >
                    <span
                        :style="textStyle"
                        :class="answerFontSizeClass"
                    >
                        {{ dknaLabel }}
                    </span>
                </vm-radio>
            </answer-box>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { useState, useActions, useGetters } from 'vuex-composition-helpers';
import AnswerBox from '@/components/ui/AnswerBox.vue';
import VmRadio from '@/components/ui/VModelRadio.vue';
import { useResponsiveFontSizeClass } from '@/components/typeSpecificElements/hooks/useStyle';

import { hex2rgba, isHexDark } from '@/utils/color';

export default defineComponent({
    components: {
        AnswerBox,
        VmRadio,
    },
    props: {
        element: { type: Object, required: true },
    },
    setup(props) {
        const state = useState(['answers', 'text_color', 'answer_font_name', 'answer_font_weight', 'primary_color', 'background_color']);
        const getters = useGetters(['getTranslations']);
        const actions = useActions(['setAnswer']);

        const borderColor = computed(() => hex2rgba(state.text_color.value, 0.15));
        const textStyle = computed(() => ({
            'color': state.text_color.value,
            'font-family': state.answer_font_name.value,
            'font-weight': state.answer_font_weight.value
        }));
        const selectedStyle = computed(() => ({
            'color': isHexDark(state.primary_color.value) ? (state.background_color.value === 'transparent' ? '#FFFFFF' : state.background_color) : state.text_color.value,
            'background-color': state.primary_color.value,
        }));
        const hoveredStyle = computed(() => ({
            'background-color': hex2rgba(state.primary_color.value, 0.2),
        }));

        const answer = computed({
            get: () => state.answers.value?.[props.element.guid],
            set: (val) => actions.setAnswer({ guid: props.element.guid, answer: Number(val) }),
        });
        const hoveredAnswer = ref();

        const getDisplayedLabel = (index: number): string => getters.getTranslations.value?.questions?.find(q => q.guid === props.element.guid)?.ranges?.[index].range;

        const dknaLabel = computed(() => getters.getTranslations.value?.questions?.find(q => q.guid === props.element.guid)?.dkna_option_label || props.element.dkna_option_label);

        const { answerFontSizeClass } = useResponsiveFontSizeClass();

        return {
            primary_color: state.primary_color,
            borderColor,
            textStyle,
            selectedStyle,
            hoveredStyle,
            answer,
            hoveredAnswer,
            setAnswer: actions.setAnswer,
            getDisplayedLabel,
            dknaLabel,
            answerFontSizeClass,
        };
    },
});
</script>
