var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mb-3 space-y-2 mobile:block",class:{
        'inline-block': _vm.isVertical,
        'w-64': _vm.isVertical
    }},[_c('div',{staticClass:"flex mobile:space-y-4 mobile:space-x-0",class:{
            'flex-col space-y-4 space-x-0': _vm.isVertical,
            'space-x-4': !_vm.isVertical,
            'mobile:flex-col': !_vm.element.is_reversed,
            'mobile:flex-col-reverse mobile:space-y-reverse': _vm.element.is_reversed,
            'flex-row-reverse space-x-reverse' : !_vm.isVertical && _vm.element.is_reversed,
            'flex-col-reverse space-y-reverse' : _vm.isVertical && _vm.element.is_reversed,
        }},_vm._l((_vm.ranges),function(range,index){return _c('answer-box',{key:index,staticClass:"p-2 text-center cursor-pointer w-full flex justify-center items-center",attrs:{"selected":index === _vm.selectedAnswer},on:{"click":function($event){return _vm.handleChange(range.weight, index)}}},[_c('span',{class:_vm.answerFontSizeClass,style:(_vm.labelStyle)},[_vm._v(" "+_vm._s(range.label)+" ")])])}),1),_c('div',{staticClass:"max-w-2xl"},[(_vm.has_dkna_option)?_c('answer-box',{class:{
                'inline-block mobile:block': !_vm.isVertical
            },attrs:{"selected":_vm.selectedAnswer === 'dkna'}},[_c('vm-radio',{staticClass:"w-full p-1.5",attrs:{"checked":_vm.selectedAnswer === 'dkna' && _vm.dkna_option_value,"value":_vm.dkna_option_value,"primary-color":_vm.primary_color},on:{"change":function($event){return _vm.handleChange($event, 'dkna')}}},[_c('span',{class:_vm.answerFontSizeClass,style:(_vm.labelStyle)},[_vm._v(" "+_vm._s(_vm.dknaOptionLabel)+" ")])])],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }