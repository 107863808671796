import { render, staticRenderFns } from "./Element.vue?vue&type=template&id=58cad236&scoped=true"
import script from "./Element.vue?vue&type=script&lang=js"
export * from "./Element.vue?vue&type=script&lang=js"
import style0 from "./Element.vue?vue&type=style&index=0&id=58cad236&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58cad236",
  null
  
)

export default component.exports