var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-full",style:({'background-color': _vm.background_color})},[_c('div',{staticClass:"top-0 right-0 bottom-0 left-0 absolute h-full",style:(_vm.surveyBgStyle)}),_c('simplebar',{staticClass:"survey-filler",class:{
            embedded: _vm.isEmbedded,
            'scrollbar-is-hidden': _vm.scrollbarHidden
        },on:{"scroll":() => ({})}},[_c('div',{staticClass:"survey-filler-content",style:({ minWidth: '348px' })},[(_vm.isAutosaveOn && !_vm.isFinished && _vm.showAutosaveDialog === true && !_vm.hasBeenFilled)?_c('partial-completion-popup',{on:{"accept":function($event){return _vm.accept()}}}):_vm._e(),(!_vm.isFinished && _vm.hasPassword && !_vm.passwordChecked)?_c('password-protection',{style:({
                    'min-height': _vm.hasOwnPrivacyPolicy && _vm.privacyPolicyUrl ? 'calc(100vh - 105px)' : 'calc(100vh - 90px)'
                }),attrs:{"survey-id":_vm.id},on:{"passwordIsOK":function($event){return _vm.passwordIsOK()}}}):_c('div',{class:{ 'py-4': _vm.hasBackground },style:({
                    'min-height': _vm.hasOwnPrivacyPolicy && _vm.privacyPolicyUrl ? 'calc(100vh - 105px)' : 'calc(100vh - 90px)'
                })},[_c('div',{staticClass:"rounded mx-auto md:px-10 relative mobile:w-fit",style:({
                        maxWidth: '1024px',
                    })},[_c('div',{staticClass:"top-0 right-0 bottom-0 left-0 absolute h-full w-full rounded",staticStyle:{"max-width":"1024px"},style:(_vm.panelStyle)}),(_vm.showFiller)?_c('filler',{staticClass:"relative",staticStyle:{"max-width":"960px"},attrs:{"url-params-for-tracking":_vm.urlParamsForTracking},on:{"page-change":_vm.handlePageChange,"finished":_vm.submit}}):_vm._e(),(_vm.shouldShowThankYou)?_c('thank-you',{staticClass:"relative",staticStyle:{"min-height":"50vh"}}):((_vm.isFinished || (_vm.showPage && _vm.showPage.includes('disq-page-'))) && _vm.disqualifiedPage)?_c('disqualification-page',{staticClass:"relative",staticStyle:{"min-height":"50vh"},attrs:{"element":_vm.disqualifiedPage,"url-params-for-tracking":_vm.urlParamsForTracking}}):_vm._e()],1)]),_c('filler-footer',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isEmbedded),expression:"!isEmbedded"}],attrs:{"has-own-privacy-policy":_vm.hasOwnPrivacyPolicy,"privacy-policy-company":_vm.privacyPolicyTitle,"privacy-policy-url":_vm.privacyPolicyUrl,"is-free-group":_vm.isFreeGroup},on:{"reportSurvey":function($event){return _vm.reportSurvey()}}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }