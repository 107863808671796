<template>
    <transition-group
        :name="direction"
        tag="div"
        :style="containerStyle"
    >
        <toaster
            v-for="toaster in toasters"
            :key="toaster.id"
            :message="toaster.message"
            :level="toaster.level"
            :closable="toaster.closable"
            :revocable="toaster.revocable"
            :style="{ marginBottom: '8px' }"
            :timeout="toaster.timeout"
            @close="removeToaster(toaster.id)"
            @undo="toaster.undoCallback"
        />
    </transition-group>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';

import Toaster from './Toaster.vue';
import useToaster from '@/utils/toaster';

export default defineComponent({
    components: {
        Toaster,
    },
    setup() {
        const { toasters, removeToaster, toasterOptions } = useToaster();

        const containerStyle = computed(() => {
            const baseStyle = {
                position: 'absolute',
                display: 'flex',
                zIndex: 999,
            };

            if (toasterOptions.position === 'top-right') {
                return {
                    ...baseStyle,
                    top: 0,
                    marginTop: toasterOptions.padding.y + 'px',
                    right: 0,
                    marginRight: toasterOptions.padding.x + 'px',
                    flexDirection: 'column-reverse',
                    alignItems: 'flex-end',
                };
            }

            if (toasterOptions.position === 'top-left') {
                return {
                    ...baseStyle,
                    top: 0,
                    marginTop: toasterOptions.padding.y + 'px',
                    left: 0,
                    marginLeft: toasterOptions.padding.x + 'px',
                    flexDirection: 'column-reverse',
                    alignItems: 'flex-start',
                };
            }

            if (toasterOptions.position === 'bottom-right') {
                return {
                    ...baseStyle,
                    bottom: 0,
                    marginBottom: toasterOptions.padding.y + 'px',
                    right: 0,
                    marginRight: toasterOptions.padding.x + 'px',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                };
            }

            if (toasterOptions.position === 'bottom-left') {
                return {
                    ...baseStyle,
                    bottom: 0,
                    marginBottom: toasterOptions.padding.y + 'px',
                    left: 0,
                    marginLeft: toasterOptions.padding.x + 'px',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                };
            }

            if (toasterOptions.position === 'bottom-center') {
                return {
                    ...baseStyle,
                    bottom: 0,
                    marginBottom: toasterOptions.padding.y + 'px',
                    left: '50%',
                    flexDirection: 'column',
                    alignItems: 'center',
                    transform: 'translateX(-50%)'
                };
            }

            if (toasterOptions.position === 'top-center') {
                return {
                    ...baseStyle,
                    top: 0,
                    marginTop: toasterOptions.padding.y + 'px',
                    left: '50%',
                    flexDirection: 'column-reverse',
                    alignItems: 'center',
                    transform: 'translateX(-50%)'
                };
            }

            throw new Error('wrong position');

        });

        const direction = computed(() => {
            if (toasterOptions.position === 'top-right' || toasterOptions.position === 'bottom-right') {
                return 'right';
            }
            if (toasterOptions.position === 'top-left' || toasterOptions.position === 'bottom-left') {
                return 'left';
            }
            if (toasterOptions.position === 'top-center') {
                return 'top';
            }
            if (toasterOptions.position === 'bottom-center') {
                return 'bottom';
            }

            throw new Error('wrong option');

        });

        return {containerStyle, toasters, removeToaster, direction};
    },
});
</script>

<style scoped>
    .right-enter-active, .right-leave-active,
    .left-enter-active, .left-leave-active,
    .bottom-enter-active, .bottom-leave-active,
    .top-enter-active, .top-leave-active,
    .right-move, .left-move, .bottom-move, .top-move {
        transition: all 0.3s;
    }
    .right-leave-active,
    .left-leave-active,
    .bottom-leave-active,
    .top-leave-active {
        position: absolute;
    }
    .right-enter, .right-leave-to {
        opacity: 0;
        transform: translateX(100%);
    }
    .left-enter, .left-leave-to {
        opacity: 0;
        transform: translateX(-100%);
    }
    .top-enter, .top-leave-to {
        opacity: 0;
        transform: translateY(-100%);
    }
    .bottom-enter, .bottom-leave-to {
        opacity: 0;
        transform: translateY(100%);
    }
</style>
