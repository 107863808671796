<template>
    <div
        class="without-mobile-tap-highlight border rounded"
        :style="{
            borderColor,
            backgroundColor,
        }"
        @mouseover="hovered = true"
        @mouseleave="hovered = false"
        @click="handleClick"
    >
        <div class="w-full">
            <slot />
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue';
import { useState } from 'vuex-composition-helpers';
import { hex2rgb } from '@/utils/color';

export default defineComponent({
    props: {
        selected: { type: Boolean, default: false },
    },
    setup(props, context) {
        const hovered = ref(false);

        const { primary_color, text_color } = useState(['primary_color', 'text_color']);

        const borderColor = computed(() => {
            if (props.selected) return primary_color.value;
            if (hovered.value) return `rgba(${hex2rgb(primary_color.value)[0]}, ${hex2rgb(primary_color.value)[1]}, ${hex2rgb(primary_color.value)[2]}, 0.30)`;

            return `rgba(${hex2rgb(text_color.value)[0]}, ${hex2rgb(text_color.value)[1]}, ${hex2rgb(text_color.value)[2]}, 0.15)`;
        });

        const backgroundColor = computed(() => {
            if (props.selected) return `rgba(${hex2rgb(primary_color.value)[0]}, ${hex2rgb(primary_color.value)[1]}, ${hex2rgb(primary_color.value)[2]}, 0.20)`;
            if (hovered.value) return `rgba(${hex2rgb(primary_color.value)[0]}, ${hex2rgb(primary_color.value)[1]}, ${hex2rgb(primary_color.value)[2]}, 0.10)`;

            return 'transparent';
        });

        function handleClick() {
            context.emit('click');
        }

        return {
            hovered,
            borderColor,
            backgroundColor,
            handleClick,
        };
    },
});
</script>
<style scoped lang="less">
.without-mobile-tap-highlight {
    -webkit-tap-highlight-color: transparent;
}
</style>
