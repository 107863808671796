<template>
    <div class="mx-auto">
        <div
            class="progress-bar"
            :style="'background: linear-gradient(to right,' + primaryColor + ',' + primaryColor + ' ' + percentage + '%, #ebeff5 ' + percentage + '%);'"
        />
        <div
            :style="'color:' + primaryColor"
            class="progress-bar-info"
        >
            {{ percentage }} % {{ $t('PROGRESS_BAR.COMPLETED') }}
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        primaryColor: { type: String, required: true },
        percentage: { type: Number, default: 0 },
    }
});
</script>
<style scoped lang="less">
.progress-bar {
    height: 8px;
    border-radius: 4px;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
}

.progress-bar-info {
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    font-weight: bolder;
    text-align: center;
}
</style>
