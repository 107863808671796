<template>
    <div class="survey-filler">
        <div class="sticky-header">
            <div id="logo">
                <img
                    crossorigin="anonymous"
                    style="margin:10px 0 0 30px;"
                    src="/images/zurvey-small-logo.jpeg"
                >
            </div>
        </div>
        <div class="error-message">
            <div class="error-first-line">
                <b>Oops! This survey is not active at the moment.</b>
            </div>
            <div class="error-second-line">
                We couldn't find the survey you asked for.
            </div>
            <div class="error-second-line">
                Please make sure you entered the URL correctly.
            </div>
            <div class="error-third-line">
                <b><i>404</i></b>
            </div>
        </div>
        <form
            class="error-form"
            action="https://zurvey.io"
        >
            <section>
                <div class="element-body">
                    <h1>
                        <span class="text-primary-700">1</span>  Now that you're here, are you interested in checking out <a
                            class="text-primary-700"
                            href="https://zurvey.io"
                        >zurvey.io</a>?
                    </h1>
                    <div class="answers">
                        <div
                            class="option"
                        >
                            <z-radio
                                id="yes"
                                name="error-radio"
                                label-text="Sure, let's see that website!"
                                :value="true"
                            />
                        </div>
                        <div
                            class="option"
                        >
                            <z-radio
                                id="no"
                                name="error-radio"
                                label-text=" No, thank you."
                                :value="false"
                            />
                        </div>
                    </div>
                </div>
            </section>
            <div class="error-form-button">
                <a href="https://zurvey.io">
                    <z-button>Submit</z-button>
                </a>
            </div>
        </form>
    </div>
</template>

<script>
import ZButton from '@/components/ui/Button';
import ZRadio from '@/components/ui/VModelRadio';

export default {
    name: 'ErrorPage',
    components: {ZButton, ZRadio},
    created() {
        this.$load.stop();
        Sentry.captureMessage('Survey Filler Error Page was shown');
    },
};
</script>

<style lang="less" scoped>
.survey-filler {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.error-message {
    margin: 30px auto;
    width: 500px;
    text-align: center;
}

.error-first-line, .error-third-line {
    font-size: 30px;
}

.error-form {
    width: 500px;
    margin: 50px auto;

    h1 {
        font-weight: 700;
        font-size: 15px;
    }

    .answers {
        margin: 10px auto;
        width: 95%;
    }

    .error-form-button {
        width: 100px;
        margin: 20px auto;
    }

    label {
        font-size: 14px;
        vertical-align: middle;
        margin-left: 10px;
    }
}
</style>
