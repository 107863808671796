<template>
    <div class="flex">
        <div
            v-for="index in 5"
            :key="index"
            class="w-1/5 text-center"
        >
            <div
                :data-test-id="'rating-icon-' + type + '-' + iconStyle"
                class="cursor-pointer inline-block"
                @mouseover="hoveredIndex = index"
                @mouseleave="hoveredIndex = 0"
                @click="$emit('clicked', index)"
            >
                <svg
                    v-if="type !== 'smile'"
                    width="52"
                    height="48"
                    viewBox="0 0 576 512"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        v-if="type === 'heart' && iconStyle === 'light'"
                        d="M462.3 62.7c-54.5-46.4-136-38.7-186.6 13.5L256 96.6l-19.7-20.3C195.5 34.1 113.2 8.7 49.7 62.7c-62.8 53.6-66.1 149.8-9.9 207.8l193.5 199.8c6.2 6.4 14.4 9.7 22.6 9.7 8.2 0 16.4-3.2 22.6-9.7L472 270.5c56.4-58 53.1-154.2-9.7-207.8zm-13.1 185.6L256.4 448.1 62.8 248.3c-38.4-39.6-46.4-115.1 7.7-161.2 54.8-46.8 119.2-12.9 142.8 11.5l42.7 44.1 42.7-44.1c23.2-24 88.2-58 142.8-11.5 54 46 46.1 121.5 7.7 161.2z"
                        :fill="color(index)"
                    />
                    <path
                        v-if="type === 'heart' && iconStyle === 'regular'"
                        d="M458.4 64.3C400.6 15.7 311.3 23 256 79.3 200.7 23 111.4 15.6 53.6 64.3-21.6 127.6-10.6 230.8 43 285.5l175.4 178.7c10 10.2 23.4 15.9 37.6 15.9 14.3 0 27.6-5.6 37.6-15.8L469 285.6c53.5-54.7 64.7-157.9-10.6-221.3zm-23.6 187.5L259.4 430.5c-2.4 2.4-4.4 2.4-6.8 0L77.2 251.8c-36.5-37.2-43.9-107.6 7.3-150.7 38.9-32.7 98.9-27.8 136.5 10.5l35 35.7 35-35.7c37.8-38.5 97.8-43.2 136.5-10.6 51.1 43.1 43.5 113.9 7.3 150.8z"
                        :fill="color(index)"
                    />
                    <path
                        v-if="type === 'heart' && iconStyle === 'solid'"
                        d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"
                        :fill="color(index)"
                    />

                    <path
                        v-if="type === 'star' && iconStyle === 'light'"
                        d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM405.8 317.9l27.8 162L288 403.5 142.5 480l27.8-162L52.5 203.1l162.7-23.6L288 32l72.8 147.5 162.7 23.6-117.7 114.8z"
                        :fill="color(index)"
                    />
                    <path
                        v-if="type === 'star' && iconStyle === 'regular'"
                        d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM388.6 312.3l23.7 138.4L288 385.4l-124.3 65.3 23.7-138.4-100.6-98 139-20.2 62.2-126 62.2 126 139 20.2-100.6 98z"
                        :fill="color(index)"
                    />
                    <path
                        v-if="type === 'star' && iconStyle === 'solid'"
                        d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"
                        :fill="color(index)"
                    />

                    <path
                        v-if="type === 'thumbsUp' && iconStyle === 'light'"
                        d="M496.656 285.683C506.583 272.809 512 256 512 235.468c-.001-37.674-32.073-72.571-72.727-72.571h-70.15c8.72-17.368 20.695-38.911 20.695-69.817C389.819 34.672 366.518 0 306.91 0c-29.995 0-41.126 37.918-46.829 67.228-3.407 17.511-6.626 34.052-16.525 43.951C219.986 134.75 184 192 162.382 203.625c-2.189.922-4.986 1.648-8.032 2.223C148.577 197.484 138.931 192 128 192H32c-17.673 0-32 14.327-32 32v256c0 17.673 14.327 32 32 32h96c17.673 0 32-14.327 32-32v-8.74c32.495 0 100.687 40.747 177.455 40.726 5.505.003 37.65.03 41.013 0 59.282.014 92.255-35.887 90.335-89.793 15.127-17.727 22.539-43.337 18.225-67.105 12.456-19.526 15.126-47.07 9.628-69.405zM32 480V224h96v256H32zm424.017-203.648C472 288 472 336 450.41 347.017c13.522 22.76 1.352 53.216-15.015 61.996 8.293 52.54-18.961 70.606-57.212 70.974-3.312.03-37.247 0-40.727 0-72.929 0-134.742-40.727-177.455-40.727V235.625c37.708 0 72.305-67.939 106.183-101.818 30.545-30.545 20.363-81.454 40.727-101.817 50.909 0 50.909 35.517 50.909 61.091 0 42.189-30.545 61.09-30.545 101.817h111.999c22.73 0 40.627 20.364 40.727 40.727.099 20.363-8.001 36.375-23.984 40.727zM104 432c0 13.255-10.745 24-24 24s-24-10.745-24-24 10.745-24 24-24 24 10.745 24 24z"
                        :fill="color(index)"
                    />
                    <path
                        v-if="type === 'thumbsUp' && iconStyle === 'regular'"
                        d="M466.27 286.69C475.04 271.84 480 256 480 236.85c0-44.015-37.218-85.58-85.82-85.58H357.7c4.92-12.81 8.85-28.13 8.85-46.54C366.55 31.936 328.86 0 271.28 0c-61.607 0-58.093 94.933-71.76 108.6-22.747 22.747-49.615 66.447-68.76 83.4H32c-17.673 0-32 14.327-32 32v240c0 17.673 14.327 32 32 32h64c14.893 0 27.408-10.174 30.978-23.95 44.509 1.001 75.06 39.94 177.802 39.94 7.22 0 15.22.01 22.22.01 77.117 0 111.986-39.423 112.94-95.33 13.319-18.425 20.299-43.122 17.34-66.99 9.854-18.452 13.664-40.343 8.99-62.99zm-61.75 53.83c12.56 21.13 1.26 49.41-13.94 57.57 7.7 48.78-17.608 65.9-53.12 65.9h-37.82c-71.639 0-118.029-37.82-171.64-37.82V240h10.92c28.36 0 67.98-70.89 94.54-97.46 28.36-28.36 18.91-75.63 37.82-94.54 47.27 0 47.27 32.98 47.27 56.73 0 39.17-28.36 56.72-28.36 94.54h103.99c21.11 0 37.73 18.91 37.82 37.82.09 18.9-12.82 37.81-22.27 37.81 13.489 14.555 16.371 45.236-5.21 65.62zM88 432c0 13.255-10.745 24-24 24s-24-10.745-24-24 10.745-24 24-24 24 10.745 24 24z"
                        :fill="color(index)"
                    />
                    <path
                        v-if="type === 'thumbsUp' && iconStyle === 'solid'"
                        d="M104 224H24c-13.255 0-24 10.745-24 24v240c0 13.255 10.745 24 24 24h80c13.255 0 24-10.745 24-24V248c0-13.255-10.745-24-24-24zM64 472c-13.255 0-24-10.745-24-24s10.745-24 24-24 24 10.745 24 24-10.745 24-24 24zM384 81.452c0 42.416-25.97 66.208-33.277 94.548h101.723c33.397 0 59.397 27.746 59.553 58.098.084 17.938-7.546 37.249-19.439 49.197l-.11.11c9.836 23.337 8.237 56.037-9.308 79.469 8.681 25.895-.069 57.704-16.382 74.757 4.298 17.598 2.244 32.575-6.148 44.632C440.202 511.587 389.616 512 346.839 512l-2.845-.001c-48.287-.017-87.806-17.598-119.56-31.725-15.957-7.099-36.821-15.887-52.651-16.178-6.54-.12-11.783-5.457-11.783-11.998v-213.77c0-3.2 1.282-6.271 3.558-8.521 39.614-39.144 56.648-80.587 89.117-113.111 14.804-14.832 20.188-37.236 25.393-58.902C282.515 39.293 291.817 0 312 0c24 0 72 8 72 81.452z"
                        :fill="color(index)"
                    />
                </svg>
                <div v-else>
                    <div v-if="iconStyle === 'light' || iconStyle === 'regular'">
                        <svg
                            v-if="index === 1"
                            width="52"
                            height="48"
                            viewBox="0 0 18 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle
                                cx="9.995"
                                cy="8.035"
                                r="6.875"
                                :stroke="color(index)"
                                :stroke-width="iconStyle === 'regular' ? '1.5' : '1'"
                            />
                            <path
                                d="M12.7886 11.8284C11.2265 10.2663 8.69388 10.2663 7.13178 11.8284"
                                :stroke="color(index)"
                                stroke-linecap="round"
                            />
                            <path
                                d="M13.96 8C13.96 8.55228 13.5122 9 12.96 9C12.4077 9 11.96 8.55228 11.96 8"
                                :stroke="color(index)"
                                stroke-linecap="round"
                            />
                            <path
                                d="M7.95996 8C7.95996 8.55228 7.51225 9 6.95996 9C6.40768 9 5.95996 8.55228 5.95996 8"
                                :stroke="color(index)"
                                stroke-linecap="round"
                            />
                        </svg>
                        <svg
                            v-if="index === 2"
                            width="52"
                            height="48"
                            viewBox="0 0 18 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle
                                cx="9.995"
                                cy="8.035"
                                r="6.875"
                                :stroke="color(index)"
                                :stroke-width="iconStyle === 'regular' ? '1.5' : '1'"
                            />
                            <path
                                d="M12.7884 10.8284C11 10.8284 9.05793 10.8284 7 11.5"
                                :stroke="color(index)"
                                stroke-linecap="round"
                            />
                            <circle
                                cx="7"
                                cy="7"
                                r="1"
                                :fill="color(index)"
                            />
                            <circle
                                cx="13"
                                cy="7"
                                r="1"
                                :fill="color(index)"
                            />
                        </svg>
                        <svg
                            v-if="index === 3"
                            width="52"
                            height="48"
                            viewBox="0 0 18 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle
                                cx="9.995"
                                cy="8.035"
                                r="6.875"
                                :stroke="color(index)"
                                :stroke-width="iconStyle === 'regular' ? '1.5' : '1'"
                            />
                            <circle
                                cx="7"
                                cy="7"
                                r="1"
                                :fill="color(index)"
                            />
                            <circle
                                cx="13"
                                cy="7"
                                r="1"
                                :fill="color(index)"
                            />
                            <path
                                d="M12.7884 10.8284C11 11.5 9 11.5 7.13153 10.8284"
                                :stroke="color(index)"
                                stroke-linecap="round"
                            />
                        </svg>
                        <svg
                            v-if="index === 4"
                            width="52"
                            height="48"
                            viewBox="0 0 18 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle
                                cx="9.995"
                                cy="8.035"
                                r="6.875"
                                :stroke="color(index)"
                                :stroke-width="iconStyle === 'regular' ? '1.5' : '1'"
                            />
                            <path
                                d="M12.7884 9.82843C11.2263 11.3905 8.69363 11.3905 7.13153 9.82843"
                                :stroke="color(index)"
                                stroke-linecap="round"
                            />
                            <path
                                d="M13.96 7C13.96 6.44772 13.5122 6 12.96 6C12.4077 6 11.96 6.44772 11.96 7"
                                :stroke="color(index)"
                                stroke-linecap="round"
                            />
                            <path
                                d="M7.95996 7C7.95996 6.44772 7.51225 6 6.95996 6C6.40768 6 5.95996 6.44772 5.95996 7"
                                :stroke="color(index)"
                                stroke-linecap="round"
                            />
                        </svg>
                        <svg
                            v-if="index === 5"
                            width="52"
                            height="48"
                            viewBox="0 0 18 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle
                                cx="9.995"
                                cy="8.035"
                                r="6.875"
                                :stroke="color(index)"
                                :stroke-width="iconStyle === 'regular' ? '1.5' : '1'"
                            />
                            <path
                                d="M13.9999 9.00001C13.9999 8.72387 13.7761 8.50001 13.4999 8.50001H6.49994C6.2238 8.50001 5.99994 8.72387 5.99994 9.00001C5.99994 10.1345 6.4247 11.0416 7.17483 11.6531C7.91158 12.2537 8.91122 12.5287 10.0032 12.5226C11.0913 12.5166 12.0888 12.2421 12.8245 11.6454C13.5749 11.0369 13.9999 10.1352 13.9999 9.00001Z"
                                :stroke="color(index)"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M13.96 6C13.96 5.44772 13.5122 5 12.96 5C12.4077 5 11.96 5.44772 11.96 6"
                                :stroke="color(index)"
                                stroke-linecap="round"
                            />
                            <path
                                d="M7.95996 6C7.95996 5.44772 7.51225 5 6.95996 5C6.40768 5 5.95996 5.44772 5.95996 6"
                                :stroke="color(index)"
                                stroke-linecap="round"
                            />
                        </svg>
                    </div>
                    <div v-else-if="iconStyle === 'solid'">
                        <svg
                            v-if="index === 1"
                            width="52"
                            height="48"
                            viewBox="0 0 18 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M9.995 15.41C14.0681 15.41 17.37 12.1081 17.37 8.035C17.37 3.9619 14.0681 0.66 9.995 0.66C5.9219 0.66 2.62 3.9619 2.62 8.035C2.62 12.1081 5.9219 15.41 9.995 15.41ZM13.1422 11.4749C13.3374 11.6701 13.3374 11.9867 13.1422 12.182C12.9469 12.3772 12.6303 12.3772 12.4351 12.182C11.0682 10.8151 8.85217 10.8151 7.48533 12.182C7.29007 12.3772 6.97349 12.3772 6.77822 12.182C6.58296 11.9867 6.58296 11.6701 6.77822 11.4749C8.53558 9.71751 11.3848 9.71751 13.1422 11.4749ZM12.96 8.5C12.6838 8.5 12.46 8.27614 12.46 8C12.46 7.72385 12.2361 7.5 11.96 7.5C11.6838 7.5 11.46 7.72385 11.46 8C11.46 8.82842 12.1315 9.5 12.96 9.5C13.7884 9.5 14.46 8.82842 14.46 8C14.46 7.72385 14.2361 7.5 13.96 7.5C13.6838 7.5 13.46 7.72385 13.46 8C13.46 8.27614 13.2361 8.5 12.96 8.5ZM6.45996 8C6.45996 8.27614 6.68382 8.5 6.95996 8.5C7.2361 8.5 7.45996 8.27614 7.45996 8C7.45996 7.72385 7.68382 7.5 7.95996 7.5C8.2361 7.5 8.45996 7.72385 8.45996 8C8.45996 8.82842 7.78839 9.5 6.95996 9.5C6.13153 9.5 5.45996 8.82842 5.45996 8C5.45996 7.72385 5.68382 7.5 5.95996 7.5C6.2361 7.5 6.45996 7.72385 6.45996 8Z"
                                :fill="color(index)"
                            />
                        </svg>
                        <svg
                            v-if="index === 2"
                            width="52"
                            height="48"
                            viewBox="0 0 18 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M9.995 15.41C14.0681 15.41 17.37 12.1081 17.37 8.035C17.37 3.9619 14.0681 0.66 9.995 0.66C5.9219 0.66 2.62 3.9619 2.62 8.035C2.62 12.1081 5.9219 15.41 9.995 15.41ZM12.7884 11.3284C13.0645 11.3284 13.2884 11.1046 13.2884 10.8284C13.2884 10.5523 13.0645 10.3284 12.7884 10.3284H12.764C10.9876 10.3284 8.97851 10.3284 6.84488 11.0247C6.58237 11.1103 6.439 11.3926 6.52467 11.6551C6.61034 11.9176 6.8926 12.061 7.15512 11.9753C9.12758 11.3316 10.994 11.3284 12.7884 11.3284ZM8 7C8 7.55228 7.55228 8 7 8C6.44772 8 6 7.55228 6 7C6 6.44772 6.44772 6 7 6C7.55228 6 8 6.44772 8 7ZM13 8C13.5523 8 14 7.55228 14 7C14 6.44772 13.5523 6 13 6C12.4477 6 12 6.44772 12 7C12 7.55228 12.4477 8 13 8Z"
                                :fill="color(index)"
                            />
                        </svg>
                        <svg
                            v-if="index === 3"
                            width="52"
                            height="48"
                            viewBox="0 0 18 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M9.995 15.41C14.0681 15.41 17.37 12.1081 17.37 8.035C17.37 3.9619 14.0681 0.660004 9.995 0.660004C5.9219 0.660004 2.62 3.9619 2.62 8.035C2.62 12.1081 5.9219 15.41 9.995 15.41ZM7 8C7.55228 8 8 7.55228 8 7C8 6.44772 7.55228 6 7 6C6.44772 6 6 6.44772 6 7C6 7.55228 6.44772 8 7 8ZM14 7C14 7.55228 13.5523 8 13 8C12.4477 8 12 7.55228 12 7C12 6.44772 12.4477 6 13 6C13.5523 6 14 6.44772 14 7ZM12.9642 11.2965C13.2227 11.1994 13.3535 10.9112 13.2565 10.6527C13.1594 10.3941 12.8711 10.2633 12.6126 10.3603C10.9399 10.9885 9.06229 10.9911 7.30065 10.3579C7.04079 10.2645 6.75441 10.3994 6.661 10.6593C6.5676 10.9192 6.70255 11.2056 6.96241 11.299C8.93771 12.0089 11.0601 12.0115 12.9642 11.2965Z"
                                :fill="color(index)"
                            />
                        </svg>
                        <svg
                            v-if="index === 4"
                            width="52"
                            height="48"
                            viewBox="0 0 18 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M9.995 15.41C14.0681 15.41 17.37 12.1081 17.37 8.035C17.37 3.9619 14.0681 0.660004 9.995 0.660004C5.9219 0.660004 2.62 3.9619 2.62 8.035C2.62 12.1081 5.9219 15.41 9.995 15.41ZM13.1419 10.182C13.3372 9.98672 13.3372 9.67014 13.1419 9.47487C12.9467 9.27961 12.6301 9.27961 12.4348 9.47487C11.068 10.8417 8.85192 10.8417 7.48509 9.47487C7.28982 9.27961 6.97324 9.27961 6.77798 9.47487C6.58272 9.67014 6.58272 9.98672 6.77798 10.182C8.53534 11.9393 11.3846 11.9393 13.1419 10.182ZM12.96 6.5C12.6838 6.5 12.46 6.72386 12.46 7C12.46 7.27615 12.2361 7.5 11.96 7.5C11.6838 7.5 11.46 7.27615 11.46 7C11.46 6.17158 12.1315 5.5 12.96 5.5C13.7884 5.5 14.46 6.17158 14.46 7C14.46 7.27615 14.2361 7.5 13.96 7.5C13.6838 7.5 13.46 7.27615 13.46 7C13.46 6.72386 13.2361 6.5 12.96 6.5ZM6.45996 7C6.45996 6.72386 6.68382 6.5 6.95996 6.5C7.2361 6.5 7.45996 6.72386 7.45996 7C7.45996 7.27615 7.68382 7.5 7.95996 7.5C8.2361 7.5 8.45996 7.27615 8.45996 7C8.45996 6.17158 7.78839 5.5 6.95996 5.5C6.13153 5.5 5.45996 6.17158 5.45996 7C5.45996 7.27615 5.68382 7.5 5.95996 7.5C6.2361 7.5 6.45996 7.27615 6.45996 7Z"
                                :fill="color(index)"
                            />
                        </svg>
                        <svg
                            v-if="index === 5"
                            width="52"
                            height="48"
                            viewBox="0 0 18 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M9.995 15.41C14.0681 15.41 17.37 12.1081 17.37 8.035C17.37 3.9619 14.0681 0.660004 9.995 0.660004C5.9219 0.660004 2.62 3.9619 2.62 8.035C2.62 12.1081 5.9219 15.41 9.995 15.41ZM6.5 8C5.94772 8 5.5 8.44772 5.5 9C5.5 10.2691 5.98067 11.3246 6.85895 12.0406C7.71048 12.7348 8.83463 13.0292 10.0061 13.0226C11.1697 13.0161 12.2898 12.7229 13.1396 12.0338C14.0185 11.3209 14.5 10.2704 14.5 9C14.5 8.44772 14.0523 8 13.5 8H6.5ZM6.64241 10C6.54936 9.69931 6.5 9.36582 6.5 9H7.5H12.5H13.5C13.5 9.36657 13.4505 9.69993 13.3571 10C12.941 11.3371 11.6544 12.0134 10.0005 12.0226C8.34579 12.0319 7.05811 11.3433 6.64241 10ZM12.96 5.5C12.6838 5.5 12.46 5.72386 12.46 6C12.46 6.27615 12.2361 6.5 11.96 6.5C11.6838 6.5 11.46 6.27615 11.46 6C11.46 5.17158 12.1315 4.5 12.96 4.5C13.7884 4.5 14.46 5.17158 14.46 6C14.46 6.27615 14.2361 6.5 13.96 6.5C13.6838 6.5 13.46 6.27615 13.46 6C13.46 5.72386 13.2361 5.5 12.96 5.5ZM6.45996 6C6.45996 5.72386 6.68382 5.5 6.95996 5.5C7.2361 5.5 7.45996 5.72386 7.45996 6C7.45996 6.27615 7.68382 6.5 7.95996 6.5C8.2361 6.5 8.45996 6.27615 8.45996 6C8.45996 5.17158 7.78839 4.5 6.95996 4.5C6.13153 4.5 5.45996 5.17158 5.45996 6C5.45996 6.27615 5.68382 6.5 5.95996 6.5C6.2361 6.5 6.45996 6.27615 6.45996 6Z"
                                :fill="color(index)"
                            />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useState } from 'vuex-composition-helpers';
import { hex2rgba } from '@/utils/color';

export default defineComponent({
    props: {
        type: { type: String, default: 'heart'},
        iconStyle: { type: String, default: 'regular'},
        value: { type: Number, default: 0 },
        activeColor: { type: String, default: null },
        baseColor: { type: String, default: null },
    },
    setup(props) {
        const hoveredIndex = ref(0);
        const { primary_color, text_color } = useState(['primary_color', 'text_color']);

        function color(index) {
            if (index <= props.value) {
                return props.activeColor || primary_color.value;
            } else if (index <= hoveredIndex.value) {
                return props.activeColor ? hex2rgba(props.activeColor, .75) : hex2rgba(primary_color.value, .75);
            } else {
                return props.baseColor || hex2rgba(text_color.value, 0.6) || '#d8dce8';
            }
        }

        return {
            hoveredIndex, color
        };
    },
});
</script>
