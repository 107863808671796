<template>
    <div
        :style="style"
        class="text-default rounded shadow bg-white"
    >
        <icon
            v-if="level === 'info'"
            icon="question-circle"
            class="text-blue-500"
        />
        <icon
            v-if="level === 'error'"
            icon="times-circle"
            class="text-red-500"
        />
        <icon
            v-if="level === 'success'"
            icon="check-circle"
            class="text-green-700"
        />
        <span :style="{ marginLeft: '8px' }">{{ message }}</span>
        <span
            v-if="revocable"
            :style="undoStyle"
            :class="undoColorClass"
            @click="$emit('undo')"
        >
            Undo
        </span>
        <template v-if="closable || revocable || !timeout">
            <div :style="verticalLineStyle" />
            <icon
                icon="times"
                :style="{ cursor: 'pointer'}"
                @click="$emit('close')"
            />
        </template>
    </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, onUnmounted, ref } from 'vue';

import Icon from '@/components/ui/Icon.vue';

export default defineComponent({
    components: {
        Icon,
    },
    props: {
        message: { type: String, required: true },
        level: { type: String as () => 'info'|'success'|'error', validator: (value: string) => ['info', 'success', 'error'].includes(value), default: 'info' },
        closable: { type: Boolean, default: false },
        revocable: { type: Boolean, default: false },
        timeout: { type: Number, default: 3000 },
    },
    setup(props, {emit}) {
        const style = {
            padding: '16px 16px 16px 12px',
            width: 'max-content',
            display: 'flex',
            alignItems: 'center'
        };

        const verticalLineStyle = {
            backgroundColor: 'var(--color-neutral-500)',
            height: '31px',
            width: '1px',
            margin: '-8px 16px'
        };

        const undoColorClass = computed(() => {
            if (props.level === 'success') return 'text-green-700';
            else if (props.level === 'error') return 'text-red-500';
            else return 'text-blue-500';
        });

        const undoStyle = computed(() => ({
            marginLeft: '16px',
            cursor: 'pointer',
            fontWeight: 700,
        }));

        const timer = ref();

        onMounted(() => {
            if (props.timeout) {
                timer.value = setTimeout(() => emit('close'), props.timeout);
            }
        });

        onUnmounted(() => {
            clearTimeout(timer.value);
        });

        return { style, verticalLineStyle, undoStyle, undoColorClass };
    },
});
</script>
