import i18next from 'i18next';

import al from '@/translations/al.json';
import ba from '@/translations/ba.json';
import bg from '@/translations/bg.json';
import cz from '@/translations/cz.json';
import de from '@/translations/de.json';
import dk from '@/translations/dk.json';
import en from '@/translations/en.json';
import es from '@/translations/es.json';
import et from '@/translations/et.json';
import fi from '@/translations/fi.json';
import fr from '@/translations/fr.json';
import ge from '@/translations/ge.json';
import gr from '@/translations/gr.json';
import hr from '@/translations/hr.json';
import hu from '@/translations/hu.json';
import id from '@/translations/id.json';
import it from '@/translations/it.json';
import lv from '@/translations/lv.json';
import nl from '@/translations/nl.json';
import no from '@/translations/no.json';
import pl from '@/translations/pl.json';
import pt from '@/translations/pt.json';
import ro from '@/translations/ro.json';
import ru from '@/translations/ru.json';
import se from '@/translations/se.json';
import si from '@/translations/si.json';
import sk from '@/translations/sk.json';
import sr from '@/translations/sr.json';
import ua from '@/translations/ua.json';
import hi from '@/translations/hi.json';
import tr from '@/translations/tr.json';
import my from '@/translations/my.json';

export default async (locale) => {
    await i18next.init({
        lng: locale || 'en',
        fallbackLng: 'en',
        resources: {
            al,
            ba,
            bg,
            cz,
            de,
            dk,
            en,
            es,
            et,
            fi,
            fr,
            ge,
            gr,
            hr,
            hu,
            id,
            it,
            lv,
            nl,
            no,
            pl,
            pt,
            ro,
            ru,
            se,
            si,
            sk,
            sr,
            ua,
            hi,
            tr,
            my,
        },
    });

    return i18next;
};
