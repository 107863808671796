<template>
    <div
        class="media-holder mx-auto"
        :class="logoWidthClass"
    >
        <img
            v-if="url"
            :src="url"
            :alt="title"
            crossorigin="anonymous"
        >
        <zurvey-logo v-else />
    </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import ZurveyLogo from '@/components/ui/Logo.vue';

export default defineComponent({
    components: { ZurveyLogo },
    props: {
        title: { type: String, default: null },
        url: { type: String, default: null },
        size: { type: String, default: 'small' },
    },
    setup(props) {
        const logoWidthClass = computed(() => {
            switch (props.size) {
            case 'small':
                return 'w-1/4';
            case 'large':
                return 'w-3/4';
            case 'full':
                return 'w-full';
            default:
                return 'w-1/2';
            }
        });
        return {
            logoWidthClass
        };
    }
});
</script>

<style scoped lang="less">
.media-holder {
    max-width: 100%;
    max-height: 100%;

    img, svg {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        display: block;
        margin: 0;
    }
}
</style>
