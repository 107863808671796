<template>
    <div class="w-3/4 mobile:w-full mobile:text-sm">
        <span
            :class="answer ? 'invisible': 'visible'"
            class="text-primary-700"
        >
            {{ $t('QUESTION.ORDER_DRAG_N_DROP_INFO', 'Drag and drop to order options') }}
        </span>
        <div class="flex my-1">
            <div>
                <div
                    v-for="(range, index) in element.options_parent_question_guid ? orderList : ranges"
                    :key="index"
                    class="flex items-center my-2 mobile:h-10"
                    style="height: 45px"
                >
                    <div
                        :key="index"
                        class="font-bold mr-2 break-words"
                        style="max-width: 200px"
                        :style="labelStyle"
                        :class="answerFontSizeClass"
                    >
                        {{ range.range || (index + 1) }}
                    </div>
                </div>
            </div>
            <draggable
                v-model="orderList"
                :force-fallback="true"
                draggable=".draggable"
                fallback-class="dragged-item"
                class="w-full mobile:text-xs"
                @choose="handleDrag()"
            >
                <div
                    v-for="(option, index) in orderList"
                    :key="index"
                    class="draggable my-2 flex items-center mobile:h-10"
                    style="height: 45px;"
                >
                    <answer-box
                        class="cursor-move w-full p-2 flex items-center mobile:h-10"
                        style="height: 45px;"
                    >
                        <div
                            class="flex items-center mobile:h-10"
                            style="max-height: 45px;"
                        >
                            <font-awesome-icon
                                :style="{ color: text_color }"
                                :icon="['fal', 'bars']"
                                class="text-neutral-700 mr-2"
                            />
                            <div
                                :style="labelStyle"
                                :class="answerFontSizeClass"
                                v-html="calculateRowLabelWithLimit(option.optionText).slice(0, 39)"
                            />
                            <font-awesome-icon
                                v-if="calculateRowLabelWithLimit(option.optionText).length > 40"
                                :class="answerFontSizeClass"
                                :style="{ color: text_color }"
                                :icon="['fal', 'ellipsis-h']"
                                class="ml-1 mt-3 text-xs"
                            />
                        </div>
                    </answer-box>
                </div>
            </draggable>
        </div>
        <div
            v-if="element.has_dkna_option"
            class="mobile:h-10"
            style="height: 45px"
        >
            <answer-box
                class="mobile:h-10 inline-block mobile:block"
                style="height: 45px"
            >
                <z-radio
                    :id="'dkna-' + element.guid"
                    v-model="dkna"
                    :primary-color="primary_color"
                    :value="true"
                    class="w-full p-1.5"
                >
                    <span
                        :style="labelStyle"
                        :class="answerFontSizeClass"
                    >
                        {{ dknaOptionLabel }}
                    </span>
                </z-radio>
            </answer-box>
        </div>
    </div>
</template>

<script lang="ts">
import {computed, defineComponent, onUnmounted} from 'vue';
import AnswerBox from '@/components/ui/AnswerBox.vue';
import {useActions, useGetters, useMutations, useState} from 'vuex-composition-helpers';
import ZRadio from '@/components/ui/VModelRadio.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import draggable from 'vuedraggable';
import useOptions from './hooks/useOptions';
import { useResponsiveFontSizeClass } from '@/components/typeSpecificElements/hooks/useStyle';

export default defineComponent( {
    components: {AnswerBox, ZRadio, FontAwesomeIcon, draggable},
    props: {
        element: { type: Object, default: () => ({})},
        mobileView: Boolean,
        showWithoutParent: { type: Boolean, default: false },
    },
    setup(props) {
        const { answer_font_name, answer_font_weight, text_color, primary_color, answers, hasNewDictionary } = useState([
            'answer_font_name',
            'answer_font_weight',
            'text_color',
            'primary_color',
            'answers',
            'hasNewDictionary',
        ]);
        const visibleOptions = useOptions(props.element);
        const { setAnswer } = useActions(['setAnswer']);
        const { unsetAnswer } = useMutations(['unsetAnswer']);

        const answer = computed(() => answers.value[props.element.guid]);

        const orderList = computed({
            get: () => {
                if (!answer.value) {
                    return visibleOptions.value.map(({ index, optionText }) => ({ index, optionText }));
                }

                if (!hasNewDictionary.value) {
                    const hasAnswer = visibleOptions.value.filter(o => answer.value[o.index].value !== null).sort((o1, o2) => answer.value[o1.index].value - answer.value[o2.index].value);
                    const hasNoAnswer = visibleOptions.value.filter(o => answer.value[o.index].value === null);

                    return [...hasAnswer, ...hasNoAnswer];
                }
                // @ts-ignore
                else if (Object.values(answer.value).every(a => a.dkna)) {
                    return visibleOptions.value.map(({ index, optionText }) => ({ index, optionText }));
                }
                else {
                    const getRangeIndex = (rangeId) => {
                        return props.element.ranges.findIndex(r => r.id === rangeId);
                    };

                    const sorter = (o1,o2) => {
                        return getRangeIndex(answer.value[o1.id].value) - getRangeIndex(answer.value[o2.id].value);
                    };

                    const hasAnswer = visibleOptions.value.filter(o => answer.value[o.id].value !== null).sort(sorter);
                    const hasNoAnswer = visibleOptions.value.filter(o => answer.value[o.id] == null);

                    return [...hasAnswer, ...hasNoAnswer];
                }
            },
            set: (val) => {
                let answer;

                if (!hasNewDictionary.value) {
                    answer = props.element.options.map((_, i) => {
                        const index = val.findIndex(op => op.index === i);

                        return {
                            value: index !== -1 ? index : null,
                            dkna: false,
                        };
                    });
                }
                else {
                    answer = props.element.options.reduce((a, o, i) => {
                        const index = val.findIndex(op => op.index === i);

                        return {
                            ...a,
                            [o.id]: {
                                value: index !== -1 ? props.element.ranges[index].id : null,
                                dkna: false,
                            }
                        };
                    }, {});
                }

                setAnswer({
                    guid: props.element.guid,
                    answer,
                });
            }
        });

        function handleDrag() {
            orderList.value = [...orderList.value];
        }

        const dkna = computed<boolean>({
            get: () => {
                const answer = answers.value[props.element.guid];

                if (!answer) return false;

                if (Array.isArray(answer)) {
                    return answer.every(o => o.dkna);
                }

                return Object.values(answer as Object).every(o => o.dkna);

            },
            set: (val) => {
                if (val) {
                    if (!hasNewDictionary.value) {
                        setAnswer({
                            guid: props.element.guid,
                            answer: props.element.options.map(() => ({ dkna: true, value: null }))
                        });
                    }
                    else {
                        setAnswer({
                            guid: props.element.guid,
                            answer: props.element.options.reduce((a, o) => {
                                return {
                                    ...a,
                                    [o.id]: {
                                        value: null,
                                        dkna: true,
                                    }
                                };
                            }, {})
                        });
                    }
                }
            }
        });

        onUnmounted(() => {
            if (visibleOptions.value.length < 2) unsetAnswer(props.element.guid);
        });

        const labelStyle = computed(function() {
            return {
                color: text_color.value,
                'font-family': answer_font_name.value,
                'font-weight': answer_font_weight.value
            };
        });

        const { getTranslations } = useGetters(['getTranslations']);

        const ranges = computed(() => getTranslations.value?.questions?.find(q => q.guid === props.element.guid)?.ranges || props.element.ranges);

        const dknaOptionLabel = computed(() => {
            return getTranslations.value?.questions?.find(q => q.guid === props.element.guid)?.dkna_option_label || props.element.dkna_option_label;
        });

        const { answerFontSizeClass } = useResponsiveFontSizeClass();

        const calculateRowLabelWithLimit = (rowLabel: string): string => {
            // Remove html tags to not cut in the middle
            return rowLabel.length > 40 ? rowLabel.replace(/<\/?[^>]+(>|$)/g, ''): rowLabel;
        };

        return {
            primary_color,
            labelStyle,
            text_color,
            orderList,
            dkna,
            calculateRowLabelWithLimit,
            dknaOptionLabel,
            ranges,
            answerFontSizeClass,
            handleDrag,
            answer,
            visibleOptions,
        };
    }
});
</script>
<style lang="less">
.sortable-drag {
    opacity: 1 !important
}
.sortable-ghost {
    opacity: 0;
}
</style>
