<template>
    <label
        class="cursor-pointer inline-flex items-center relative"
        :class="{ 'justify-center': !(labelText || $slots.default)}"
        :for="id"
    >
        <div class="checkbox_input leading-none flex items-center">
            <input
                :id="id"
                type="radio"
                :checked="checked === value"
                @change="$emit('change', value)"
            >
            <span class="checkbox_control">
                <svg
                    height="1em"
                    width="1em"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle
                        cx="12"
                        cy="12"
                        r="10.5"
                        :stroke="color"
                        stroke-width="1"
                    />
                    <circle
                        class="inner"
                        cx="12"
                        cy="12"
                        r="8"
                        stroke-width="6"
                        :stroke="checked === value ? color : 'transparent'"
                    />
                </svg>
            </span>
        </div>
        <div
            v-if="labelText || $slots.default"
            :class="[paddingSize]"
            class="pr-0 py-0"
        >
            <slot>
                {{ labelText }}  <!--When no slot is specified in the parent, this fallback text will appear-->
            </slot>
        </div>
    </label>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useSpacingClass } from '../typeSpecificElements/hooks/useStyle';

export default defineComponent({
    model: {
        prop: 'checked',
        event: 'change'
    },
    props: {
        id: { type: String, default: () => `radio-${Math.floor(Math.random() * 1000000)}` },
        value: { type: [Number, String, Boolean], required: true },
        checked: { type: [Number, String, Boolean], required: false, default: '' },
        labelText: { type: String, required: false, default: '' },
        primaryColor: { type: String, required: false, default: '' },
    },
    setup(props) {
        const { paddingSize } = useSpacingClass();

        const color = computed(() => {
            return props.primaryColor || 'var(--color-primary-700)';
        });

        return {paddingSize, color};
    },
});
</script>

<style scoped lang="less">
.checkbox_control {
    svg circle.inner {
        transition: transform 0.1s ease-in 25ms;
        transform: scale(0);
        transform-origin: center;
    }
}

.checkbox_input {
    input {
        display: none;

        &:checked + .checkbox_control svg circle.inner {
            transform: scale(1);
        }

        &:disabled + .checkbox_control {
            color: var(--disabled);
        }
    }
}
</style>
