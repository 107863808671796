import { getAnswerForValidation } from './utils/elementValidator';
import elementTypeEnum from '@/enums/elementTypeEnum';

const { TEXT, TEXTAREA, CHECKBOX, NUMBER, MATRIX, DATE, SCALE } = elementTypeEnum;

export const autoPageState = {
    isAutoPaginationOn: false,
    isCurrentPageValidForAutoPagination: false,
    wentBack: false,
};

export const autoPageGetters = {
    shouldAutoForward(state) {
        return state.isAutoPaginationOn && state.isCurrentPageValidForAutoPagination && !state.wentBack;
    },
};

export const autoPageMutations = {
    setIsCurrentPageValidForAutoPagination(state, isValid) {
        state.isCurrentPageValidForAutoPagination = isValid;
    },
    setWentBack(state, value) {
        state.wentBack = value;
    },
};

export const autoPageActions = {
    validateCurrentPage(context, elements) {
        const hasTextOrMultipleAnswersOrDate = elements.some(element => {
            if (element.is_multiple_enabled) {
                return true;
            }

            if (element.type === SCALE && ['slider', 'color-slider'].includes(element.display_type)) {
                return true;
            }

            const textOrMultipleAnswersOrDateTypes = [TEXT, TEXTAREA, CHECKBOX, NUMBER, MATRIX, DATE];

            return textOrMultipleAnswersOrDateTypes.includes(element.type);
        });

        if (hasTextOrMultipleAnswersOrDate) {
            context.commit('setIsCurrentPageValidForAutoPagination', false);
        } else {
            const answers = elements.filter(element => element.type !== elementTypeEnum.EXPLANATION).map(element => getAnswerForValidation(context.state, element));
            context.commit('setIsCurrentPageValidForAutoPagination', answers.every(Boolean));
        }
    },
};

