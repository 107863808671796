<template>
    <button
        class="rounded py-1 cursor-pointer align-middle outline-none"
        :class="tailwindClasses"
        :data-test-id="dataTestId"
        @click="disabled || $emit('click', $event)"
    >
        <slot>
            {{ text }}  <!--When no slot is specified in the parent, this fallback text will appear-->
        </slot>
    </button>
</template>

<script>
// YIQ equation from http://24ways.org/2010/calculating-color-contrast
function getPrimaryColor() {
    if (
        !document ||
        !document.documentElement ||
        !document.documentElement.style ||
        !document.documentElement.style.getPropertyValue ||
        !document.documentElement.style.getPropertyValue('--color-primary-700')
    ) {
        return [28,198,186];
    }
    const c = document.documentElement.style.getPropertyValue('--color-primary-700');
    const result = c.length === 7 ? /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(c) : /^#?([a-f\d])([a-f\d])([a-f\d])$/i.exec(c);
    return result ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16)
    ] : null;
}
// YIQ equation from http://24ways.org/2010/calculating-color-contrast
function isDark() {
    const [ r, g, b ] = getPrimaryColor();
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq < 168;
}

export default {
    name: 'ZButton',
    props: {
        type: { type: String, default: 'primary' },     // Types: primary, secondary, destructive, disabled
        bg: { type: String, default: 'solid' },         // Background: solid, transparent, ghost
        size: { type: String, default: 'default' },     // Sizes: default, large, small, narrow
        text: { type: String, default: 'OK' },
        dataTestId: { type: String, default: 'Zbutton' },
        disabled: { type: Boolean, default: false, }
    },
    computed: {
        tailwindClasses() {
            let background = 'bg-primary-700';
            let text = 'text-white';
            let border = 'border-none';
            let px = 'px-6';

            if(this.type === 'disabled') {
                background = 'bg-neutral-400';
                text = 'text-neutral-600 cursor-not-allowed';
            } else if(this.type === 'primary' && this.bg === 'solid') {
                background = 'bg-primary-700 hover:bg-primary-500';
                text = isDark() ? 'text-alwayswhite' : 'text-alwaysblack';
            } else if(this.type === 'destructive') {
                if(this.bg === 'solid') {
                    text = 'text-white';
                    background = 'bg-red-500 hover:bg-red-300';
                } else if(this.bg === 'transparent') {
                    text = 'text-red-700';
                    background = 'bg-transparent';
                    border = 'border border-red-700';
                } else if(this.bg === 'ghost') {
                    text = 'text-red-700';
                    background = 'bg-white';
                }
            } else if(this.type === 'secondary') {
                if(this.bg === 'solid') {
                    text = isDark() ? 'text-primary-700' : 'text-black';
                    background = 'bg-white';
                } else if(this.bg === 'transparent') {
                    text = isDark() ? 'text-primary-700' : 'text-black';
                    background = 'bg-transparent';
                    border = 'border border-primary-700';
                }
            }

            let size;
            switch (this.size) {
            case 'small':
                size = 'text-small';
                px = 'px-2';
                break;
            case 'large':
                size = 'text-large';
                break;
            case 'narrow':
                px = 'px-4';
                size = 'text-default';
                break;
            default:
                size = 'text-default';
            }
            return [background, text, size, border, px].join(' ');
        },
    },
};
</script>
