<template>
    <popup
        :is-closable="false"
        :fix-width="true"
        :title="$t('REAL_TIME_SAVE.ENABLED_TITLE', 'Partial Completion Enabled')"
    >
        <template #default>
            <div>{{ $t('REAL_TIME_SAVE.ENABLED_DESCRIPTION', 'The creator of this survey has activated the Partial Completion feature, ensuring that your responses and interactions are saved instantly. Rest assured, we prioritize the security and confidentiality of your data.') }}</div>
            <br>
            <div>
                {{ $t('PRIVACY.READ_MORE') }}
                <a
                    href="https://zurvey.io/zurvey/en/privacy-policy"
                    class="pp-link"
                    target="_blank"
                >{{ $t('PRIVACY.OUR_PP') }}</a>.
            </div>
            <div
                v-if="hasOwnPrivacyPolicy && privacyPolicyUrl !== null"
                v-html="ppLine"
            />
            <div class="accept-checkbox flex items-center space-x-1">
                <input
                    id="autosave-checkbox"
                    v-model="isAutosaveAccepted"
                    type="checkbox"
                >
                <label for="autosave-checkbox">{{ $t('PRIVACY.AGREE_PP') }}</label>
            </div>
        </template>
        <template
            #footer
        >
            <div class="text-center mt-4">
                <z-button
                    :type="!isAutosaveAccepted ? 'disabled' : 'primary'"
                    :disabled="!isAutosaveAccepted"
                    :text="$t('BUTTONS.PRIVACY_SUBMIT')"
                    @click="$emit('accept')"
                />
            </div>
        </template>
    </popup>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { useGetters, useMutations, useState } from 'vuex-composition-helpers';

import Popup from '@/components/ui/Popup.vue';
import ZButton from '@/components/ui/Button.vue';
import i18next from 'i18next';

export default defineComponent({
    components: {
        Popup,
        ZButton,
    },
    emits: ['accept'],
    setup() {
        const state = useState(['isAutosaveAccepted', 'privacyPolicyUrl', 'hasOwnPrivacyPolicy']);
        const getters = useGetters(['privacyPolicyTitle']);
        const mutations = useMutations(['setIsAutosaveAccepted']);

        const isAutosaveAccepted = computed({
            get() {
                return state.isAutosaveAccepted.value;
            },
            set(value) {
                mutations.setIsAutosaveAccepted(value);
            },
        });

        const ppLink = computed(() => `<a href="${state.privacyPolicyUrl.value}" class="pp-link" target="_blank">${i18next.t('PRIVACY.PRIVACY_POLICY', 'privacy policy')}</a>`);

        const ppLine = computed(() => i18next.t('PRIVACY.CLIENT_PP_LINE', {
            replace: {privacy_policy_link: ppLink.value, company: getters.privacyPolicyTitle.value},
            interpolation: {escapeValue: false},
            defaultValue: 'You can also learn more about the {{- privacy_policy_link}} of {{company}}'
        }));

        return {
            isAutosaveAccepted,
            ppLine,
            privacyPolicyUrl: state.privacyPolicyUrl,
            hasOwnPrivacyPolicy : state.hasOwnPrivacyPolicy,
        };
    }
});
</script>
