<template>
    <div class="flex mobile:justify-center">
        <div
            data-test-id="file-upload"
        >
            <loading-animation
                v-if="loading"
                style="display: table; margin: auto; margin-bottom: 5px;"
                :size="32"
                :color="$store.state.primary_color"
            />
            <div
                v-if="uploaded"
                class="uploaded-message text-neutral-800"
            >
                {{ $t('QUESTION.FILE_UPLOADED') }}
            </div>
            <div
                class="file-upload-button"
                :style="{
                    'background-color': primary_color,
                    'color': bgImage && bgImage.panelSettings ? bgImage.panelSettings.bgColor : background_color,
                }"
                @click="openDialog"
            >
                {{ $t('BUTTONS.FILE_UPLOAD') }}
            </div>
            <div class="max-file-size-message text-neutral-800">
                {{ $t('QUESTION.MAX_FILE_SIZE') }} 10MB
            </div>
            <input
                ref="file"
                type="file"
                name="file"
                accept="media_type"
                style="display: none;"
                @change="uploadFile()"
            >
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useActions, useState } from 'vuex-composition-helpers';
import api from '@/api';
import delay from '@/utils/delay';
import LoadingAnimation from '@/plugins/LoadingScreen/LoadingAnimation.vue';

export default defineComponent({
    components: {
        LoadingAnimation,
    },
    props: {
        element: { type: Object, default: () => ({})},
    },
    setup(props) {
        const file = ref<HTMLInputElement>();
        const loading = ref(false);
        const uploaded = ref(false);

        const { primary_color, text_color, isTest, bgImage, background_color } = useState(['primary_color', 'text_color', 'isTest', 'bgImage', 'background_color']);
        const { setAnswer } = useActions(['setAnswer']);

        function openDialog() {
            file.value?.click();
        }

        async function uploadFile() {
            loading.value = true;
            if(isTest.value === false && file.value?.files) {
                const response = await api.upload(file.value.files[0]);
                loading.value = false;
                uploaded.value = true;
                setAnswer({ guid: props.element.guid, answer: response });
            } else {
                // @ts-ignore
                await delay(1000);
                loading.value = false;
                setAnswer({ guid: props.element.guid, answer: 'test answer'});
            }
        }

        return {
            loading, uploaded, file, // refs
            text_color, primary_color, bgImage, background_color,
            openDialog, uploadFile // methods
        };
    },
});
</script>

<style scoped lang="less">
.file-upload-button {
    position: unset !important;
    border-radius: 4px;
    padding: 6px 20px;
    margin: 0 auto;
    width: fit-content;
    font-size: 13px;
    cursor: pointer;
}

.uploaded-message,.max-file-size-message {
    font-size: 10px;
    position: unset !important;
    display: block;
    margin: 0 auto  !important;
    left: 50%;
    top: 50% !important;
    text-align: center;
    text-transform: none;
    padding: 6px 20px;
}
</style>
