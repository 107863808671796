<template>
    <section
        v-if="isElementVisible"
        class="flex"
        :class="[gapSize]"
    >
        <div
            v-if="element.picture_url || youtubeUrl"
            class="flex-shrink-0"
            :class="sideMediaClass"
        >
            <element-image
                v-if="element.picture_url"
                :url="element.picture_url"
                :alt="element.title"
                :data-test-id="'uploaded-picture-' + element.order"
            />
            <element-video
                v-else-if="youtubeUrl"
                :url="youtubeUrl"
                :data-test-id="'embedded-video-' + element.order"
            />
        </div>
        <div
            class="flex flex-grow flex-col"
            :class="[gapSize]"
        >
            <div>
                <element-title
                    :visible-order="visibleOrder"
                    :title="title"
                    :optional="optional"
                    :required="required"
                    :help="help"
                    :help-as-tooltip="element.help_as_tooltip"
                />
                <help-text
                    v-if="help && !element.help_as_tooltip && !isContent"
                    :help="help"
                />
            </div>
            <div
                v-if="element.picture_url || youtubeUrl"
                :class="mediaClass"
            >
                <element-image
                    v-if="element.picture_url"
                    :url="element.picture_url"
                    :alt="element.title"
                    :data-test-id="'uploaded-picture-' + element.order"
                />
                <element-video
                    v-else-if="youtubeUrl"
                    :url="youtubeUrl"
                    :data-test-id="'embedded-video-' + element.order"
                />
            </div>
            <div>
                <component
                    :is="controllerComponentName"
                    :element="element"
                    :show-without-parent="showParentedQuestionsAlways"
                    :data-test-id="'survey-element-' + element.order"
                    :mobile-view="mobileView"
                    :is-first-unanswered-element="isFirstUnansweredElement"
                />
                <help-text
                    v-if="help && isContent"
                    :help="help"
                />
                <error-message
                    v-if="element.validation_error_message"
                    :error="element.validation_error_message"
                />
            </div>
        </div>
    </section>
</template>

<script>
import { computed } from 'vue';
import { mapGetters } from 'vuex';
import typeEnum from '@/enums/elementTypeEnum';
import Text from '../typeSpecificElements/Text.vue';
import Textarea from '../typeSpecificElements/Textarea.vue';
import Checkbox from '../typeSpecificElements/Checkbox.vue';
import Date from '../typeSpecificElements/Date.vue';
import Dropdown from '../typeSpecificElements/Dropdown.vue';
import Radio from '../typeSpecificElements/Radio.vue';
import Scale from '../typeSpecificElements/Scale.vue';
import NumberType from '../typeSpecificElements/Number.vue';
import Rating from '../typeSpecificElements/Rating.vue';
import Matrix from '../typeSpecificElements/Matrix.vue';
import File from '../typeSpecificElements/FileUpload.vue';
import Picture from '../typeSpecificElements/PictureChoice.vue';
import LabeledButtons from '../typeSpecificElements/LabeledButtons.vue';
import Order from '@/components/typeSpecificElements/Order';
import LabelsAndValuesButtons from '@/components/typeSpecificElements/LabelsAndValuesButtons.vue';
import ElementTitle from './Title.vue';
import HelpText from './HelpText.vue';
import ElementImage from './Image.vue';
import ElementVideo from './Video.vue';
import ErrorMessage from './ErrorMessage.vue';
import { useState } from 'vuex-composition-helpers';
import { LayoutSpacing } from '@/types/SettingEnums';


export default {
    components: {
        Order,
        'text-input': Text,
        'textarea-input': Textarea,
        'checkbox-input': Checkbox,
        'date-input': Date,
        'dropdown-input': Dropdown,
        'radio-input': Radio,
        'scale-input': Scale,
        'number-input': NumberType,
        'rating-input': Rating,
        'matrix-input': Matrix,
        'file-input': File,
        'picture-input': Picture,
        'labeled-buttons': LabeledButtons,
        LabelsAndValuesButtons,
        ElementTitle,
        HelpText,
        ElementImage,
        ElementVideo,
        ErrorMessage,
    },
    props: {
        element: { type: Object, required: true },
        visibleOrder: { type: Number, default: null },
        mobileView: Boolean,
        showParentedQuestionsAlways: { type: Boolean, default: false},
        isFirstUnansweredElement: { type: Boolean, default: false},
    },
    setup(props) {
        const position = computed(() => {
            if (props.element.media_position === 'icon') return 'icon';

            if (props.element.media_position?.split('-').length === 2) {
                return props.element.media_position.split('-')[0];
            }
            if (props.element.media_position?.split('-')[2] === 'above') {
                return 'above';
            }
            else {
                return 'below';
            }
        });
        const alignment = computed(() => {
            if (props.element.media_position === 'icon') return 'icon';

            return props.element.media_position?.split('-')[1] || 'center';
        });
        const size = computed(() => props.element.media_size || 'medium');

        const sideMediaClass = computed(() => {
            if (position.value === 'icon') {
                const classes = ['mobile:w-2/12 pr-4 mt-2'];
                switch (size.value) {
                case 'small':
                    classes.push('w-1/12');
                    break;
                case 'medium':
                    classes.push('w-2/12');
                    break;
                case 'large':
                    classes.push('w-3/12');
                }
                return classes.join(' ');
            }
            else if (['left', 'right'].includes(position.value)) {
                const classes = ['mobile:hidden'];

                if (position.value === 'left') {
                    classes.push('mr-4');
                }
                else if (position.value === 'right') {
                    classes.push('order-2');
                }

                switch (alignment.value) {
                case 'top':
                    classes.push('self-start');
                    break;
                case 'middle':
                    classes.push('self-center');
                    break;
                case 'bottom':
                    classes.push('self-end');
                }

                switch (size.value) {
                case 'small':
                    classes.push('w-1/4');
                    break;
                case 'medium':
                    classes.push('w-1/2');
                    break;
                case 'large':
                    classes.push('w-3/4');
                }

                return classes.join(' ');
            }

            return 'hidden';
        });

        const mediaClass = computed(() => {
            if (position.value === 'icon') return 'hidden';

            if (position.value === 'left') return 'hidden mobile:block mobile:w-full mobile:order-first';

            if (position.value === 'right') return 'hidden mobile:block mobile:w-full mobile:order-last';

            const classes = ['mobile:w-full'];

            if (position.value.includes('above')) {
                classes.push('order-first');
            }
            else if (position.value.includes('bottom')) {
                classes.push('order-last');
            }

            if (alignment.value.includes('center')) {
                classes.push('self-center');
            }
            else if (alignment.value.includes('right')) {
                classes.push('self-end');
            }

            switch (size.value) {
            case 'small':
                classes.push('w-1/4');
                break;
            case 'medium':
                classes.push('w-1/2');
                break;
            case 'large':
                classes.push('w-3/4');
                break;
            case 'full':
                classes.push('w-full');
            }

            return classes.join(' ');
        });

        const { layout_spacing } = useState(['layout_spacing']);

        const gapSize = computed(() => {
            switch (layout_spacing.value) {
            case LayoutSpacing.COZY:
                return 'gap-1';
            case LayoutSpacing.COMPACT:
                return 'gap-2';
            default:
                return 'gap-4';
            }
        });

        return {
            sideMediaClass,
            mediaClass,
            gapSize,
        };
    },
    computed: {
        ...mapGetters([
            'getTranslations',
        ]),
        optional() {
            return !this.element.is_required && this.element.type !== typeEnum.EXPLANATION;
        },
        required() {
            return this.element.is_required && this.element.type !== typeEnum.EXPLANATION;
        },
        title() {
            // changeTitleByConditions sets original title even if multilang
            if (this.getTranslations?.questions?.find(q => q.guid === this.element.guid) && !this.getTranslations?.questions?.find(q => q.guid === this.element.guid)?.conditions?.some(condition => condition.action_payload === this.element.title)) {
                return this.getTranslations?.questions?.find(q => q.guid === this.element.guid)?.title;
            }
            return this.element.title;
        },
        help() {
            const helpText = this.getTranslations?.questions?.find(q => q.guid === this.element.guid)?.help || this.element.help || '';

            // properties like help can have html formatting and after deleting the text, it might leave a span or p tag behind
            if (helpText.replace(/<\/?[^>]+(>|$)/g, '') === '') return '';

            return helpText;
        },
        isElementVisible(){
            if (this.showParentedQuestionsAlways === true) { // always show in builder preview
                return true;
            } else if( // dont show if it has parent, but the parent does not have an answer
                (this.element.type === typeEnum.MATRIX || this.element.type === typeEnum.RADIO || this.element.type === typeEnum.CHECKBOX) &&
                this.element.options_parent_question_guid !== null &&
                (typeof this.$store.state.answers[this.element.options_parent_question_guid] === 'undefined' && this.element.use_selected_answers))
            {
                return false;
            }
            return true;
        },
        controllerComponentName() {
            switch (this.element.type) {
            case typeEnum.TEXT:
                return 'text-input';
            case typeEnum.TEXTAREA:
                return 'textarea-input';
            case typeEnum.CHECKBOX:
                return 'checkbox-input';
            case typeEnum.DATE:
                return 'date-input';
            case typeEnum.DROPDOWN:
                return 'dropdown-input';
            case typeEnum.RADIO:
                return 'radio-input';
            case typeEnum.SCALE: {
                if (this.element.range_labels_always_shown) return 'labels-and-values-buttons';

                return this.element.display_type === 'value' ? 'labeled-buttons' : 'scale-input';
            }
            case typeEnum.NUMBER:
                return 'number-input';
            case typeEnum.RATING:
                return 'rating-input';
            case typeEnum.MATRIX:
                return 'matrix-input';
            case typeEnum.FILE:
                return 'file-input';
            case typeEnum.PICTURE:
                return 'picture-input';
            case typeEnum.NPS:
                return this.element.display_type === 'value' ? 'labeled-buttons' : 'scale-input';
            case typeEnum.CSAT:
            case typeEnum.CES_5_SCALE:
            case typeEnum.CES_7_SCALE:
                return this.element.range_labels_always_shown ? 'labels-and-values-buttons' : 'labeled-buttons';
            case typeEnum.ORDER:
                return 'order';
            default:
                return null;
            }
        },
        youtubeUrl() { // based on this SO answer: https://stackoverflow.com/a/8260383
            if (!this.element.video_url) {
                return false;
            }
            const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
            const match = this.element.video_url.match(regExp);
            return (match && match[7].length === 11) ? `https://www.youtube.com/embed/${match[7]}` : false;
        },
        isContent() {
            return this.element.type === typeEnum.EXPLANATION;
        },
    },
};
</script>

<style lang="less" scoped>
/deep/ ul {
    list-style-type: disc;
    padding-left: 1em;
}
/deep/ ol {
    list-style-type: decimal;
    padding-left: 1em;
}

/deep/ p:empty,
/deep/ div:empty {
    height: 1em;
}
</style>
