declare global {
    interface Window {
        isGoogleAnalyticsEnabled: boolean;
    }

    function gtag(command: string, eventName: string, eventParams?: any): void;
}
export default {
    events: {
        SURVEY_NEXT: 'ZurveySurveyNext',
        SURVEY_PREV: 'ZurveySurveyPrev',
        SURVEY_SUBMIT: 'ZurveySurveySubmit',
        SURVEY_DISQUALIFIED: 'ZurveySurveyDisqualified'
    },

    loadGAScript(measurementId) {
        if (!measurementId) {
            window.isGoogleAnalyticsEnabled = false;
            return;
        }

        window.isGoogleAnalyticsEnabled = true;

        const asyncScript = document.createElement('script');
        asyncScript.async = true;
        asyncScript.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
        document.head.appendChild(asyncScript);

        const scriptContent = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${measurementId}');`;

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.innerHTML = scriptContent;
        document.head.appendChild(script);
    },

    track(event, properties = {}, isEmbedded = false) {
        if (!window.isGoogleAnalyticsEnabled) {
            return;
        }

        try {
            // eslint-disable-next-line
            gtag('event', event, properties);

            if (isEmbedded) {
                window.parent.postMessage({
                    event: event,
                    ...properties
                }, '*');
            }
        } catch (e: any) {
            console.warn('Google analytics error: ' + e.message);
        }
    },
};
