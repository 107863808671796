import { storageFactory } from 'storage-factory';

let storage : typeof window.localStorage;

try {
    storage = storageFactory(() => window.localStorage);
} catch (e) {
    //@ts-ignore
    storage = storageFactory(() => {});
}
export default storage as typeof window.localStorage;
